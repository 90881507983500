import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ProfileService } from 'src/app/providers/profile.service';
import { CommonService } from 'src/app/providers/common.service';
import { UserService } from 'src/app/providers/user.service';
import { Router } from '@angular/router';
import { EventPublishService } from 'src/app/providers/event-publish';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  title = 'OTPModal';
  type: any = 'mobForm';
  pattern1 =  "^[0-9_-]{7,11}";
  loginForm: FormGroup = this.fb.group({
    mobile: ['', [Validators.required, Validators.pattern(this.pattern1)]],
    email:new FormControl('',)
  });
  countryCode: any = '91'
  submitted: boolean = false;
  otpSubmitted: boolean = false;
  resentOtpAfter = null;
  timerTime = 2 * 60 * 1000;
  showResendOtp: boolean = false;
  loadingRequest:boolean=false
  isResendOtp:boolean=false
  enteredOtp:any;
  loadingSubmission:boolean=false
  isLoading:boolean=false;
  is_email:boolean=false;
  user_email:any;
  display_email:boolean=false;
  fix_email:boolean=false;
  // country_name:any='91'
 
  constructor( public profile: ProfileService,
    public common: CommonService,
    public events: EventPublishService,
    public router: Router,
    public user: UserService,
    public dialogRef: MatDialogRef<SigninComponent>,public fb:FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

  ngOnInit(): void {
  }

  get fpf() { return this.loginForm.controls; } 


  onAlreadyHaveAnOtp() {
    if (this.user.login_mobile && this.fpf.mobile.value && this.user.login_mobile != "") {
      this.type = 'otpForm';
      let date = new Date();
      this.user.login_otp_resend_timer = date;
      this._runResendTimer();
    } else {
      this.common.showToastError("Please Enter Your Mobile Number")
    }

  }


  onSubmitMobile() {
    if(this.is_email){
      console.log(this.is_email)
      this.loginForm.controls.email.setValidators([Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), Validators.minLength(1)])
      this.loginForm.controls.email.updateValueAndValidity();
    }
    if(!this.is_email){
      this.loginForm.controls.email.setValidators([])
      this.loginForm.controls.email.updateValueAndValidity();
    }
    this.isLoading=true;
    if (this.loginForm.invalid) {
      this.submitted = true;
      this.isLoading=false;
      return;
    }
    this.isLoading=true
    if (this.loginForm.valid) {
      this.submitted = true;
      this.loadingRequest = true;
      this.user.login_mobile = this.fpf.mobile.value;
    
      var _data = {
        customer_mobile: this.countryCode + this.fpf.mobile.value
      };
      if(this.is_email){
        _data['customer_email']=this.fpf.email.value;
        this.user_email=this.fpf.email.value
        this.display_email=true;
      }
     
      this.profile.generateOtpForLogin(_data).subscribe(res => {
        if (res.success == true) {
          this.isLoading=false
        
          localStorage.setItem(this.common.LOGIN_MOBILE_NO, this.fpf.mobile.value)
          if(!this.is_email){
            this.common.showToastSuccess('OTP has been sent to ' + this.countryCode + this.fpf.mobile.value);
          }
          if(this.is_email){
            this.common.showToastSuccess('OTP has been sent to ' + this.user_email);
          }
          this.is_email=false;
          this.type = 'otpForm';
          this.user.login_otp_resend_timer = new Date();
          this._runResendTimer();
        } else {
          this.common.showToastError(res.message)
        }
        this.submitted = false;
        this.loadingRequest = false;
      }, err => {
        this.submitted = false;
        this.isLoading=false;
        this.is_email=true;
        this.loadingRequest = false;
        this
          .common
          .processError(err);
        return false;
      });
    }
  }

  _runResendTimer() {
    this.isResendOtp = false
    var _interval = setInterval(() => {
      let now = new Date();
      this.resentOtpAfter = Math.trunc((this.timerTime - (now.getTime() - this.user.login_otp_resend_timer.getTime())) / 1000)
      if (this.user.login_otp_resend_timer && now.getTime() - this.user.login_otp_resend_timer.getTime() > this.timerTime) {
        this.showResendOtp = true;
        clearInterval(_interval);
        if (this.resentOtpAfter < 1) this.isResendOtp = true
      }
    }, 1000);
  }

  onOtpChange(otp) {
    this.enteredOtp = otp;
    if (this.enteredOtp.toString().length == 4) {
    this.submit();
    }
  }

  changeMobile(){
    this.type='mobForm'
    if(this.display_email){
      this.is_email=true
      this.type='mobForm'
    }
  }

  submit() {
    this.isLoading=true;
    if (this.enteredOtp == null) {
      this.otpSubmitted = true;
      this.isLoading=false
      return;
    }
    if (this.enteredOtp != null) {
      this.otpSubmitted = true;
      this.loadingSubmission = true;

      var _data = {
        customer_mobile: this.countryCode + this.user.login_mobile,
        otp: this.enteredOtp,
      };
      console.log(_data)
      this
        .profile
        .verifyOtpForLogin(_data)
        .subscribe(res => {
          console.log(res);
          if (res.success == true) {
            this.isLoading=false;
            localStorage.setItem(this.common.LOGIN_TYPE,'customer')
            this.processSuccessLogin(res);
            this.common.showToastSuccess(res.message)
            this.dialogRef.close()
          } else {
            this.common.showToastError(res.message)
          }
          this.otpSubmitted = false;
          this.loadingSubmission = false;
        }, err => {
          this.otpSubmitted = false;
          this.loadingSubmission = false;
          console.error('ERROR', err);
          this
            .common
            .processError(err);
            this.isLoading=false
          return false;
        });
    }
  }

  processSuccessLogin(res) {
    console.log(res);
    this.user.hasLoggedIn = true
    this.user.updateLoginData(res);
    this.events.publishSomeData(this.common.USER_LOGIN);
    this.router.navigate(['select-course'])
  }

  close(){
   this.dialogRef.close()
  }

  onCountryChange(event){
    console.log(event.dialCode)
    this.countryCode=event.dialCode
  }
  
}
