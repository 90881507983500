import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { Api } from './api';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(public user: UserService, public api: Api) { }
  register(data) {
    let endpoint = 'customer/profile/registration';
    return this
      .api
      .post(endpoint, data)
      .pipe(map(resp => resp.json()));
  }
  login(data) {
    let endpoint = 'customer/profile/login';
    return this
      .api
      .post(endpoint, data)
      .pipe(map(resp => resp.json()));
  }
  socialLogin(provider, token, referralCode: any = 0) {
    console.log(token)
    let endpoint = 'customer/profile/social-login/' + provider + '/' + token + '/' + referralCode;
    // if (referralCode) endpoint += '/' + user_id
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  getUserDetails() {
    let endpoint = 'profile/me';
    return this
      .api.get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  // change password
  changePassword(data) {
    let endpoint = 'profile/passwordchange';
    return this
      .api
      .post(endpoint, data)
      .pipe(map(resp => resp.json()));
  }
  // get user details
  // update user details
  updateUserDetails(data) {
    let endpoint = 'profile/edit';
    return this
      .api.put(endpoint, data)
      .pipe(map(resp => resp.json()));
  }
  // Profile Pic
  updateProfilePic(data) {
    let endpoint = 'profile/edit/image';
    // let fData = new FormData();
    // fData.append('profile_pic', profile_pic);
    return this
      .api
      .postAsFormData(endpoint, data)
      .pipe(map(resp => resp.json()));
  }
  removeProfilePic() {
    let endpoint = 'profile/edit/image';
    return this
      .api.delete(endpoint)
      .pipe(map(resp => resp.json()));
  }
  // FORgOt PASSWORD
  requestOtpForForgotPassword(data) {
    let endpoint = 'customer/profile/forgotPassword';
    return this
      .api.post(endpoint, data)
      .pipe(map(resp => resp.json()));
  }
  sendOtpForForgotPassword(data) {
    let endpoint = 'customer/profile/resetpassword';
    return this
      .api.post(endpoint, data)
      .pipe(map(resp => resp.json()));
  }
  // OTP OTP OTP
  requestMobOtp() {
    let endpoint = 'customer/profile/send_otp';
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  verifyMobOtp(data) {
    let endpoint = 'customer/profile/verify_otp';
    return this
      .api
      .put(endpoint, data)
      .pipe(map(resp => resp.json()));
  }
  // address
  addAddress(data) {
    let endpoint = 'address';
    if (data.id) {
      endpoint += '/' + data.id;
      return this
        .api.put(endpoint, data)
        .pipe(map(resp => resp.json()));
    } else {
      return this
        .api.post(endpoint, data)
        .pipe(map(resp => resp.json()));
    }
  }
  getAddressess() {
    let endpoint = 'address';
    return this
      .api.get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  getAddressById(id) {
    let endpoint = 'address';
    endpoint += '/' + id
    return this
      .api.get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  deleteAddress(id) {
    let endpoint = 'address/delete/' + id;
    return this
      .api.delete(endpoint)
      .pipe(map(resp => resp.json()));
  }
  // FCM
  registerFcm(data: any) {
    let endpoint = 'registerfcm';
    return this
      .api
      .post(endpoint, data)
      .pipe(map(resp => resp.json()));
  }
  // SHARE AND EARN DETAILS 
  getShareAndEarn() {
    let endpoint = 'customer/profile/share';
    return this
      .api.get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  generateOtpForLogin(data) {
    let endpoint = 'profile/login-generate-otp';
    return this
      .api.post(endpoint, data)
      .pipe(map(resp => resp.json()));
  }
  verifyOtpForLogin(data) {
    let endpoint = 'profile/login-verify-otp';
    return this
      .api.post(endpoint, data)
      .pipe(map(resp => resp.json()));
  }

  // country List
  getCountry() {
    let endpoint = 'location/countries';
    return this
      .api.get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  // State List
  getState(id) {
    let endpoint = 'location/states?country_id='+id;
    return this
      .api.get(endpoint)
      .pipe(map(resp => resp.json()));
  }
   // District List
   getDistrict(id) {
    let endpoint = 'location/districts?state_id='+id;
    return this
      .api.get(endpoint)
      .pipe(map(resp => resp.json()));
  }
}
