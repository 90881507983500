import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../../providers/common.service';
import { ExamService } from '../../providers/exam.service';

@Component({
  selector: 'app-exam-list',
  templateUrl: './exam-list.component.html',
  styleUrls: ['./exam-list.component.scss']
})
export class ExamListComponent implements OnInit {

  week_id:any=null
  course_id:any=null
  day_id:any=null
  sub_id: any = 0
  length: any
  examList:any=[]
  page:any=1
  loadingExams:boolean=false
  per_page:number=10
  constructor(public common:CommonService,public exam:ExamService,
    public router:Router) {
    this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
    if(this.common.selectedCourse){
      this.course_id = this.common.selectedCourse._id
    }
    this.loadExams(this.sub_id,this.course_id, this.week_id, this.day_id)
    this.router.events.subscribe((evt) => {
      
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.common.sideActive = 'exam'
   }

  ngOnInit(): void {
  }

  onScroll(e) {
    if (this.length <= this.examList.length) {
      return;
    }
    this.page = this.page + 1
    this.loadExams(this.sub_id,this.course_id ,this.week_id, this.day_id)
  }

  loadExams(sub_id:any=null,course_id:any=null,week_id:any=null, day_id:any=null) {
    this.loadingExams = true;
    this.exam.getExam(this.page, this.per_page, sub_id,course_id,week_id,day_id)
      .subscribe(res => {
        
        if (res.success == true) {
          this.length = res.data.total
          res.data.data.forEach(element => {
            if (!this.examList.find(x => x._id == element._id)) {
              this.examList.push(element);
            }
          });
        }
        this.loadingExams = false;
      }, err => {
        this.loadingExams = false;
        
        this.common.processError(err);
        return false;
      });
  }

  onClickView(value) {
    
    if (value.completed_on && value.completed_on != null) {
      this.router.navigate(['/exam-result', value.result_id])
      
    } else {
      this.router.navigate(['/exam-view', value._id])
      
    }
  }

}
