<app-side-nav></app-side-nav>
<section>

  <div class='row' *ngIf="loadingExams">
    <div class="col-md-6 " *ngFor="let itm of [1,2,3,4,5,6,7,8]">
      <ngx-skeleton-loader [theme]="{ height:'100px',width:'400px'}"></ngx-skeleton-loader>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-8 mdmt20">
        <div class="div_left_video_box">
          <div class="canvas_video_div">
            <div class="video_detail_info">
              <h2 class="mb10 mt0 pt0">{{examDetails.name}}</h2>
            </div>
            <div class="related_video_detail mt20">
              <div class="rel_content_box_main mr5 loopdiv">
                <div class="rel_content_box">
                  <div class="rel_cont_box_img">
                    <img src="assets/img/timer.svg">
                  </div>
                  <div class="rel_cont_box_text">
                    <span class="medium">{{examDetails.duration}} Mins</span>
                  </div>
                </div>
              </div>
              <div class="rel_content_box_main mr5 loopdiv">
                <div class="rel_content_box">
                  <div class="rel_cont_box_img">
                    <img src="assets/img/q.svg">
                  </div>
                  <div class="rel_cont_box_text">
                    <span class="medium">{{examDetails.no_of_questions}} Question</span>
                  </div>
                </div>
              </div>
              <div class="rel_content_box_main mr5 loopdiv">
                <div class="rel_content_box">
                  <div class="rel_cont_box_img">
                    <img src="assets/img/mark.svg">
                  </div>
                  <div class="rel_cont_box_text">
                    <span class="medium">{{examDetails.total_mark}} Marks</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="exam_ins_div">
              <p ></p>
            </div> -->
            <div class="exam_ins_ul">
              <h3 class="medium block mb10">Exam Instructions</h3>
              <p [innerHTML]="examDetails.instructions"></p>
            </div>
          </div>
        </div>
        <div class="inner_ex_detail mb50">
          <div class="row">
            <div class="col-md-7 col-sm-12 col-xs-12 col-lg-12 mt20 tl" (click)='startExam()'
              *ngIf='!isError && !exam.leftTimeSeconds && exam.isExamComplete=="false" || exam.isExamComplete==false'>
              <button class="button_s1">Start Exam</button>
            </div>

            <div class="col-md-7 col-sm-12 col-xs-12 col-lg-12 mt20 tl" *ngIf="exam.leftTimeSeconds > 0"
              (click)="goToExam()">
              <button class="button_s1">Go To Exam</button>
            </div>

            <div class="col-md-7 col-sm-12 col-xs-12 col-lg-12 mt20 tl"
              *ngIf='exam.isExamComplete == "true" && !examDetails.result_id'>
              <button class="button_s1">Submit</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 mdmt20">
        <div class="col-md-12 tl p0">
          <h2 class="mb20 mt0">Related Exams</h2>
        </div>
        <div class="col-md-12 p0">
          <div class="row">
            <div class="col-md-12 mb30" *ngFor="let exam of examList |slice:0:3">
              <div class="exam_list_selection">
                <div class="exam_list_heading mb5">
                  <h3 class="medium mt0 mb0">{{exam.name}}</h3>
                </div>
                <div class="exam_list_duration">
                  <span>Duration: <span class="primary">{{exam.duration}} Min</span> </span>
                  <table class="mt20">
                    <tr>
                      <td class="pr20 tl"><span class="medium">{{exam.no_of_questions}} Que | {{exam.total_mark}}
                          Marks</span></td>
                      <td class="tr">
                        <button class="button_s6" *ngIf='exam.completed_on && exam.completed_on != null'
                          (click)='onClickView(exam)'>View Result</button>

                      </td>
                      <td class="tr">
                        <button class="button_s6" *ngIf='!exam.completed_on || exam.completed_on == null'
                          (click)='onClickView(exam)'>Start & View Exam</button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>