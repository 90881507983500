<section class="video_frame" *ngIf="detailsData.item_type == 'video'">
    <div class="container">
        <div class="row">
            <button type="button" aria-label="Close" class="close_model_div" (click)="dialogRef.close()">
        <img src="assets/img/close.svg" class="close_model">
      </button>
            <div class="col-md-12 mb20 mt20">
                <div class="prev_data_info">
                    <div class="row">
                        <div class="col-md-6 col-xs-6 tl p0">
                            <button class="btn button_s7" *ngIf="detailsData.previous" (click)="videoNavigation('previous')"><img src="assets/img/rel_play.svg" class="prev_week_img"> Previous   </button>
                        </div>
                        <div class="col-md-6 col-xs-6 tr p0">
                            <button class="btn button_s7" *ngIf="detailsData.next" (click)="videoNavigation('next')">Next  <img src="assets/img/rel_play.svg"  class="next_week_img"></button>
                        </div>
                    </div>
                </div>

                <!-- <div class="row">
          <div class="col-md-6 col-xs-6 tl">
            <button *ngIf="detailsData.previous" class="btn button_s5" (click)="videoNavigation('previous')">Previous
              Week</button>
          </div>
          <div class="col-md-6 col-xs-6 tr">
            <button *ngIf="detailsData.next" class="btn button_s5" (click)="videoNavigation('next')">Next Week</button>
          </div>
        </div> -->
            </div>

            <div class="col-md-12 mb20">
                <h4 class="mt0 mb0" *ngIf="detailsData.week && detailsData.timeline_day">Week : {{detailsData.week?.week}} Day : {{detailsData.timeline_day?.days}}</h4>
            </div>

            <div class="col-md-7">
                <!-- <div class="detail_page_date_section tl mb20">
          <span>{{detailsData.created_at | date:'dd MMM yyyy hh : mm a'}}</span>
        </div> -->
                <div class="div_left_video_box">
                    <div class="canvas_video_div">
                        <div class="div_video_canvas">
                            <ng-container *ngIf='detailsData.video_link'>
                                <div *ngIf='!detailsData.video_source || detailsData.video_source == "jw"'>
                                    <div id="jwplayerdiv"></div>
                                </div>
                                <div class="plyer_div" *ngIf='detailsData.video_source && detailsData.video_source != "jw"'>
                                    <iframe class='vid_height' *ngIf="detailsData.video_link || is_subscribed  " [src]="detailsData.video_link | safe" width="100%" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


                                </div>
                            </ng-container>

                            <div class="free_video_frame mb20" *ngIf="!detailsData.video_link && detailsData.image && detailsData.image!=''">
                                <h3>Please subscribe this course to view the video.</h3>
                            </div>
                            <!-- <div class="video_box_img_main"
                *ngIf="!detailsData.video_link && detailsData.image && detailsData.image!=''">
                <img [src]="detailsData.image" />
              </div> -->
                            <!-- <img src="assets/img/video_large_thumb.svg" class="width100"> -->
                        </div>
                        <div class="video_detail_info">
                            <h2 class="mb5" *ngIf="detailsData.subject.title"> {{detailsData.title}} <span class="badge_2 vat">{{detailsData.type}}</span></h2>
                            <h3 class="mt0 mb0 m0 f12">{{detailsData.subject?.title}}</h3>

                        </div>
                        <div class="video_detail_info">
                            <p class="mb10" [innerHTML]="detailsData.description"> </p>
                        </div>
                    </div>

                    <div class="related_video_detail mt20" *ngIf="detailsData.pdf">
                        <div class="rel_content_box_main mr20 loopdiv pointer" (click)="onViewPdf()">
                            <div class="rel_content_box">
                                <div class="rel_cont_box_img">
                                    <img src="assets/img/pdf.svg" class="mr0 pr0">
                                </div>
                                <div class="rel_cont_box_text">
                                    <span class="medium">{{detailsData.title}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md-5" *ngIf="videoList.length > 0">
                <div class="div_right_video_box">
                    <div class="rel_video_right">
                        <div class="rel_video_title">
                            <h2 class="mb20 mt0">Related Videos </h2>
                        </div>
                        <div class="rel_video_boxes">
                            <div class="rel_vide_box mb20" *ngFor="let video of videoList" (click)='onClickMaterialView("video",video)'>
                                <div class="rel_v_img cell">
                                    <img [src]="video.image" onError="this.src='assets/img/video_small_thumb.svg'">
                                </div>
                                <div class="rel_v_text cell">
                                    <h3 class="block m0 medium">{{video.title}} </h3>
                                    <span class="f12 block" *ngIf="video.description">{{video.description}}</span>
                                </div>
                                <div class="rel_v_play cell">
                                    <img src="assets/img/rel_play.svg">
                                </div>
                                <!-- <div class="rel_v_save cell">
                  <img src="assets/img/bookmark.svg">
                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt50" *ngIf="pdfList.length > 0">
            <div class="col-md-12 tl">
                <h2 class="mb20 mt0">Related Materials</h2>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4 mb30" *ngFor="let item of pdfList" (click)='onClickMaterialView("pdf",item)'>
                        <div class="rel_mat_box_main">
                            <div class="rel_mat_box_child">
                                <div class="rel_mat_box_text_box">
                                    <h3 class="medium block mt0 mb5 video_detail_info_title">{{item.title}} </h3>
                                    <!-- <p class="m0 f12 mb20" [innerHTML]="item.description"></p> -->
                                    <p class="m0 f12 mb10" *ngIf="item.subject.title">{{ item.subject.title }}</p>
                                </div>
                                <div class="rel_mat_box_actions">
                                    <div class="rel_mat_file_icon cell tl">
                                        <img src="assets/img/pdf.svg">
                                    </div>
                                    <div class="rel_mat_date_inner cell tl" *ngIf='(!item.completed_on || item.completed_on==null) && item.published_on && item.published_on != null && !item.need_to_subscribe'>
                                        <span class="f12">{{item.published_on | date:'dd MMM yyyy, hh:mm a'}} Onwards</span>
                                    </div>
                                    <div class="rel_mat_date_inner cell tl" *ngIf='item.completed_on && item.completed_on != null'>
                                        <span class="f12">{{item.completed_on | date:'dd MMM yyyy, hh:mm a'}} Onwards</span>
                                    </div>
                                    <div class="rel_mat_button_inner cell tr">
                                        <button class="badge_2">{{item.type}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<section class="video_frame" *ngIf="detailsData.item_type == 'pdf'">
    <div class="container">
        <div class="row">
            <button type="button" aria-label="Close" class="close_model_div" (click)="dialogRef.close()">
        <img src="assets/img/close.svg" class="close_model">
      </button>
            <div class="col-md-7">
                <div class="detail_page_date_section tl mb20">
                    <span>{{detailsData.created_at | date:'dd MMM yyyy hh : mm a'}}</span>
                </div>
                <div class="div_left_video_box">
                    <div class="canvas_video_div">
                        <div class="div_video_canvas">
                            <!-- mat box -->
                            <div class="mat_big_box">
                                <div class="matbox_inner_child">
                                    <div class="mat_big_box_file cell">
                                        <img src="assets/img/pdf.svg">
                                    </div>
                                    <div class="mat_big_box_right_text tl cell">
                                        <h3 class="mt0 mb10">{{detailsData.title}}</h3>
                                        <p class="m0 mb20" [innerHTML]="detailsData.description">
                                        </p>
                                        <button class="button_s6" *ngIf="detailsData.pdf" (click)="onViewPdf()">View PDF</button>
                                        <button class="button_s6" *ngIf="detailsData.external_link" (click)="onOpenExternalLink(detailsData.external_link)()">Goto Link</button>
                                    </div>
                                </div>
                            </div>
                            <!-- mat box -->
                        </div>
                    </div>
                    <div class="mat_text_heading mt50" *ngIf="pdfList.length >0">
                        <h2 class="mb20 mt0">Basic Materials</h2>
                    </div>
                    <div class="related_video_detail mt20">
                        <!-- looooooooooooop -->
                        <div class="rel_video_boxes">
                            <div class="row">
                                <div class="col-md-6 mb20" *ngFor="let item of pdfList" (click)='onClickMaterialView("pdf",item)'>
                                    <div class="rel_mat_box_main">
                                        <div class="rel_mat_box_child">
                                            <div class="rel_mat_box_text_box">
                                                <h3 class="medium block mt0 mb10">{{item.title}} </h3>
                                                <p class="m0 f12 mb20" [innerHTML]="item.description"></p>
                                            </div>
                                            <div class="rel_mat_box_actions">
                                                <div class="rel_mat_file_icon cell tl">
                                                    <img src="assets/img/pdf.svg">
                                                </div>
                                                <div class="rel_mat_date_inner cell tl" *ngIf='(!item.completed_on || item.completed_on==null) && item.published_on && item.published_on != null && !item.need_to_subscribe'>
                                                    <span class="f12">{{item.published_on | date:'dd MMM yyyy, hh:mm a'}} Onwards</span>
                                                </div>
                                                <div class="rel_mat_date_inner cell tl" *ngIf='item.completed_on && item.completed_on != null'>
                                                    <span class="f12">{{item.completed_on | date:'dd MMM yyyy'}}</span>
                                                </div>
                                                <div class="rel_mat_button_inner cell tr">
                                                    <button class="badge_2">{{item.type}}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-5" *ngIf="videoList.length > 0">
                <div class="div_right_video_box">
                    <div class="rel_video_right">
                        <div class="rel_video_title">
                            <h2 class="mb20 mt0">Related Videos</h2>
                        </div>
                        <div class="rel_video_boxes">
                            <div class="rel_vide_box mb20 pointer" *ngFor="let video of videoList" (click)='onClickMaterialView("video",video)'>
                                <div class="rel_v_img cell">
                                    <img [src]="video.image" onError="this.src='assets/img/video_small_thumb.svg'">
                                </div>
                                <div class="rel_v_text cell">
                                    <h3 class="block m0 medium">{{video.title}}</h3>
                                    <span class="f12 block" *ngIf="video.description" [innerHTML]="video.description"> </span>
                                </div>
                                <div class="rel_v_play cell">
                                    <img src="assets/img/rel_play.svg">
                                </div>
                                <!-- <div class="rel_v_save cell">
                  <img src="assets/img/bookmark.svg">
                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row mt50">
      <div class="col-md-12 tl">
        <h2 class="mb20 mt0">Basic Materials</h2>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4" *ngFor="let video of videoList" (click)='onClickItem("video",video)'>
            <div class="video_box">
              <div class="play_thumb">
                <img src="assets/img/play_icon.svg">
              </div>
              <div class="crs_item_img">
                <img [src]="video.image" onError="this.src='assets/img/video_small_thumb.svg'">
              </div>
              <div class="video_text">
                <div class="row">
                  <div class="col-md-7 tl">
                    <h3 class="block m0">Accounting</h3>
                    <span class="medium block f12 mt5">{{video.title}}</span>
                  </div>
                  <div class="col-md-5 tr">
                    <button class="button_s5">{{video.type}}</button>
                    <span class="block f12 white mt5">Module</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    </div>
</section>