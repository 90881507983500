import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../providers/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ExamService } from '../../providers/exam.service';

@Component({
  selector: 'app-submit-exam',
  templateUrl: './submit-exam.component.html',
  styleUrls: ['./submit-exam.component.scss']
})
export class SubmitExamComponent implements OnInit {

  exam_id: any
  timeTake: any
  leftTime: boolean = true;
  answers: any = []
  sub_id: any = null
  examDetails: any = []
  skipped_question_count: number = 0
  examName: any
  constructor(public common: CommonService, public route: ActivatedRoute,
    public router: Router, public exam: ExamService) {
    if (localStorage.getItem(this.common.EXAM_NAME)) {
      // console.log(localStorage.getItem(this.common.EXAM_NAME))
      this.examName = localStorage.getItem(this.common.EXAM_NAME)
    }

    this.route.params.subscribe(params => {
      if (params && params['id']) {
        if (localStorage.getItem(this.common.EXAM_ID)) {
          this.exam_id = localStorage.getItem(this.common.EXAM_ID)
          this.loadExamView(this.exam_id)
        }
      }
       else {
        if (localStorage.getItem(this.common.EXAM_ID)) {
          this.exam_id = localStorage.getItem(this.common.EXAM_ID)
          // this.loadExamView(this.exam_id)
        }
      }
    })
  }

  ngOnInit() {
    if (!this.exam.isCalculate) {
      this.exam.isExamComplete = true
      this.exam.calculateLeftTime()
    }
    // this.examName = localStorage.getItem(this.common.EXAM_NAME)
    if (this.exam.leftTimeSeconds < 0) this.exam.leftTimeSeconds = 0;
    this.exam.questions = []
    this.exam.questions = JSON.parse(localStorage.getItem(this.common.EXAM_QUESTIONS))
    console.log(this.exam.questions)
    this.skippedQuestion()
  }

  skippedQuestion() {
    this.skipped_question_count = 0
    this.exam.questions.forEach(element => {
      if (!element.user_answer || element.user_answer == "") {
        this.skipped_question_count = this.skipped_question_count + 1
      }
    })
    console.log(this.skipped_question_count)
  }

  loadExamView(id) {
    this.exam.examView(id).subscribe(res => {
      console.log(res);
      if (res.success == true) {
        this.examDetails = res.data;
        this.exam.countdownComplete(this.examDetails.result_id)
      }
    }, err => {
      console.error('ERROR', err);
      return false;
    });
  }

  viewResult() {
    this.router.navigate(['/result', this.examDetails.result_id])
  }

  submitExam() {
    let leftTime: any = localStorage.getItem(this.common.EXAM_TIME)
    console.log(this.exam.isExamComplete)
    
    if (this.exam.isExamComplete) {
      this.timeTake = (leftTime - this.exam.leftTimeSeconds) / 60;
      this.exam.questions.forEach(element => {
        let questionId = element._id
        let answerKey
        if (element.user_answer && element.user_answer != '') {
          answerKey = element.user_answer
          this.answers.push({ question_id: questionId, option_id: answerKey })
        } else {
          answerKey = 0
          this.answers.push({ question_id: questionId, option_id: answerKey })
        }
      });
      let data = {}
      data = {
        time_taken: this.timeTake,
        exam_id: localStorage.getItem(this.common.EXAM_ID),
        answers: this.answers
      }
      console.log(data)
      console.log(this.examDetails)
      this.exam.submitExam(data).subscribe(res => {
        if (res.success) {
          localStorage.setItem(this.common.IS_EXAM_EXIST, 'false')
          this.exam.isExamComplete = false
          this.exam.resultData = res.data
          localStorage.setItem(this.common.QUESTION_FLAG, 'result')
          this.loadExamView(res.data.exam_id)
          // this.exam.countdownComplete(res.data._id)
          
        }
      }, err => {
        console.error('ERROR', err);
        this.common.processError(err);
      })

    }
  }


  goToPreview(){
    localStorage.setItem("timer",'true')
    this.router.navigate(['question-pool',this.exam_id])
  }


}
