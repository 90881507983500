import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ProfileService } from './profile.service';
import { take } from 'rxjs/operators';
import swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { VideoDetailComponent } from '../pages/video-detail/video-detail.component';
import { CommonService } from './common.service';
@Injectable()
export class MessagingService {

  alertSound: any = ''
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private router: Router, public dialog: MatDialog,
    private profile: ProfileService,
    public common: CommonService) {


    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        console.log(_messaging, '_messaging_messaging')
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        // this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.registerFCM(token)
        this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  registerFCM(_token) {
    this.profile.registerFcm({ fcm_token: _token, device_type: 'web' }).subscribe(response => {
      console.log(response)
      if (response.success == true) {
        console.log('FCM Registered');
      }
    }, err => {
      console.log('Something went wrong');
      console.log(err)
    });
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
        this.sweetAlert(payload)
      })
  }


  sweetAlert(payload) {
    this.alertSound = new Audio('assets/tunes/Midnight-chimes-sound-effect.mp3')
    this.alertSound.addEventListener('ended', function () {
      this.play();
    }, false);
    this.alertSound.play();
    swal.fire({
      title: payload.notification.title,
      text: payload.notification.body,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'View',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        if (payload.data && payload.data.item_type && payload.data.item_type == "video") {
          if (payload.data && payload.data.item_id && payload.data.item_id > 0) {
            this.materialView("video", payload.data.item_id)
          } else {
            this.router.navigate(['/video-list', 'video'])
          }
        } else if (payload.data && payload.data.item_type && payload.data.item_type == "pdf") {
          if (payload.data && payload.data.item_id && payload.data.item_id > 0) {
            this.materialView("pdf", payload.data.item_id)
          } else {
            this.router.navigate(['/video-list', 'pdf'])
          }
        } else if (payload.data && payload.data.item_type && payload.data.item_type == "course") {
          if (payload.data && payload.data.item_id && payload.data.item_id > 0) {
            localStorage.removeItem(this.common.COURSE)
            localStorage.setItem('course_id_notification', payload.data.item_id)
            this.router.navigate(['/course-week'])
          } else {
            this.router.navigate(['/select-course'])
          }
        } else if (payload.data && payload.data.item_type && payload.data.item_type == "exam") {
          if (payload.data && payload.data.item_id && payload.data.item_id > 0) {
            this.router.navigate(['/exam-view', payload.data.item_id])
          } else {
            this.router.navigate(['/exam-list'])
          }
        } else if (payload.data && payload.data.item_type && payload.data.item_type == "result") {
          if (payload.data && payload.data.item_id && payload.data.item_id > 0) {
            this.router.navigate(['/exam-result', payload.data.item_id])
          } else {
            this.router.navigate(['/result'])
          }
        }
      } else {

      }
      this.alertSound.pause()
    })
  }

  materialView(type, value) {
    const dialogRef = this.dialog.open(VideoDetailComponent, {
      panelClass: "full_modal",
      data: {
        id: value,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
