
import { Component, Inject, OnInit, VERSION } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  name = 'Angular ' + VERSION.major;
  pdfSource =encodeURI( "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf");
  pdfSrc:any;

  constructor(public dialogRef: MatDialogRef<PdfViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private spinner: NgxSpinnerService) { 
     
      if(data !=null){
        console.log(data.data)
        this.spinner.show();
        this.pdfSource=encodeURI( data.data)
      }
      // if(this.pdfSource !=null){
      //   this.spinner.hide();
      // }
    }

  ngOnInit(): void {
    
  }


  pageRendered(event){
    this.spinner.hide();
  }
}
