<app-side-nav></app-side-nav>

<section class="">
  <div class="container">
    <div class="row mb20">
      <div class="col-md-12">
        <div class="crs_tab_block tc mt20 mb20">
          <button class="btn button_s4" [ngClass]="{'active':type=='video'}"
            (click)="onClickItem('video')">Video</button>
          <button class="btn button_s4" [ngClass]="{'active':type=='pdf'}"
            (click)="onClickItem('pdf')">Materials</button>
          <button class="btn button_s4 getHeightCol" [ngClass]="{'active':type=='mock'}"
            (click)="onClickItem('mock')">Mock
            Tests</button>
        </div>
      </div>
    </div>

    <div class="row" *ngIf='!loadingVideos && (!itemsList || itemsList.length < 1)'>
      <div class="col-md-12">
        <div class="text-center">
          <!-- <div><img src="assets/images/nodata.svg" width="80"></div> -->
          <span>No <span class="upper">{{type}}</span> Found!</span>
        </div>
      </div>
    </div>

    <div class='row' *ngIf="loadingVideos">
      <div class="col-md-4" *ngFor="let itm of [1,2,3,4,5,6,7,8,9]">
        <ngx-skeleton-loader [theme]="{ height: '200px'}"></ngx-skeleton-loader>
      </div>
    </div>

    <div class="row" infiniteScroll [infiniteScrollDistance]="scrollDistance" (scrolled)="onScroll($event)">
      <div *ngIf="type == 'video'">
        <div class="col-md-12 mb30">
          <div class="row">
            <div class="col-md-8 col-lg-8 col-xs-12">
              <div *ngIf="subject_id ==null">
                <h3 class="block m0" *ngIf="week && day">Week : {{week}} | Day : {{day}}</h3>
              </div>
              <div *ngIf="subject_id !=null">
                <h3 class="block m0">Module : {{subjectData?.title}}</h3>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 col-xs-12">
              <div class="prev_data_info">
                <div class="row" *ngIf="week_id !=null && day_idd !=null">
                  <div class="col-md-6 col-xs-6 tl p0">
                    <button  class="btn button_s7" (click)="nextVideo('previous')"><img src="assets/img/rel_play.svg" class="prev_week_img"> Previous   </button>
                  </div>
                  <div class="col-md-6 col-xs-6 tr p0">
                    <button  class="btn button_s7" (click)="nextVideo('next')">Next  <img src="assets/img/rel_play.svg"  class="next_week_img"></button>
                  </div>
                </div>
              </div>
            </div>
  
  
  
  
          </div>
        </div>
        <p class="m0 f12 mb20" [innerHTML]="description"></p>
        <div class="col-md-4 mb30" *ngFor="let item of itemsList" (click)='onClickMaterialView("video",item)'>

          <div class="course_select_box p0">
            <div class="csb_inner_div">
              <span class="badge_1 video_time">{{item.video_duration * 1000 * 60| date:'HH:mm':'UTC'}}</span>
              <img [src]="item.image" onError="this.src='assets/img/video_thumb.svg'">
            </div>
            <div class="csb_inner_text">
              <div class="row">
                <div class="col-md-8 tl col-xs-8">
                  <span class="medium block item_ttle">{{item.title}}</span>
                  <span class="block f12 item_tt_subject">{{item.subject.title}}</span>
                </div>
                <div class="col-md-4 tr col-xs-4">
                  <span class="badge_2">{{item.type}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="type == 'pdf'" (click)='onClickMaterialView("pdf",item)'>
        <div class="row">
          <div class="col-md-8 col-lg-8 col-xs-12">
            <div *ngIf="subject_id ==null">
              <h3 class="block m0" *ngIf="week && day">Week : {{week}} | Day : {{day}}</h3>
            </div>
            <div *ngIf="subject_id !=null">
              <h3 class="block m0">Module : {{subjectData?.title}}</h3>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-xs-12">
            <div class="prev_data_info">
              <div class="row" *ngIf="week_id !=null && day_idd !=null">
                <div class="col-md-6 col-xs-6 tl p0">
                  <button  class="btn button_s7" (click)="nextVideo('previous')"><img src="assets/img/rel_play.svg" class="prev_week_img"> Previous   </button>
                </div>
                <div class="col-md-6 col-xs-6 tr p0">
                  <button  class="btn button_s7" (click)="nextVideo('next')">Next  <img src="assets/img/rel_play.svg"  class="next_week_img"></button>
                </div>
              </div>
            </div>
          </div>




        </div>
        <div class="related_video_detail">
          <div class="rel_video_boxes">
            <div class="col-md-4 mb30" *ngFor="let item of itemsList" (click)='onClickMaterialView("pdf",item)'>
              <div class="rel_mat_box_main">
                <div class="rel_mat_box_child">
                  <div class="rel_mat_box_text_box">
                    <h3 class="medium block mt0 mb10">{{item.title}} </h3>
                    <p class="m0 f12 mb20" [innerHTML]="item.description"></p>
                  </div>
                  <div class="rel_mat_box_actions">
                    <div class="rel_mat_file_icon cell tl">
                      <img src="assets/img/pdf.svg">
                    </div>
                    <div class="rel_mat_date_inner cell tl"
                      *ngIf='(!item.completed_on || item.completed_on==null) && item.published_on && item.published_on != null && !item.need_to_subscribe'>
                      <span class="f12">{{item.published_on | date:'dd MMM yyyy, hh:mm a'}} Onwards</span>
                    </div>
                    <div class="rel_mat_date_inner cell tl" *ngIf='item.completed_on && item.completed_on != null'>
                      <span class="f12">{{item.completed_on | date:'dd MMM yyyy'}}</span>
                    </div>
                    <div class="rel_mat_button_inner cell tr">
                      <button class="badge_2">{{item.type}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="type == 'mock'">
        <div class="row">
          <div class="col-md-8 col-lg-8 col-xs-12">
            <div *ngIf="subject_id ==null">
              <h3 class="block m0" *ngIf="week && day">Week : {{week}} | Day : {{day}}</h3>
            </div>
            <div *ngIf="subject_id !=null">
              <h3 class="block m0">Module : {{subjectData?.title}}</h3>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-xs-12">
            <div class="prev_data_info">
              <div class="row" *ngIf="week_id !=null && day_idd !=null">
                <div class="col-md-6 col-xs-6 tl p0">
                  <button  class="btn button_s7" (click)="nextVideo('previous')"><img src="assets/img/rel_play.svg" class="prev_week_img"> Previous   </button>
                </div>
                <div class="col-md-6 col-xs-6 tr p0">
                  <button  class="btn button_s7" (click)="nextVideo('next')">Next  <img src="assets/img/rel_play.svg"  class="next_week_img"></button>
                </div>
              </div>
            </div>
          </div>




        </div>
        <div class="col-md-4 mb30 cauto" [ngStyle]="{'height': cal_height}" *ngFor="let exam of itemsList ">
          <div class="exam_list_selection ">
            <div class="exam_list_heading mb5">
              <h3 class="medium mt0 mb0">{{exam.name}}</h3>
            </div>
            <div class="exam_list_duration">
              <span>Duration: <span class="primary">{{exam.duration}} Min</span> <span
                  *ngIf="exam.is_completed == true">| Completed on <span class="primary">20/20/20</span> </span> </span>
              <table class="mt20">
                <tr>
                  <td class="pr20 tl"><span class="medium">{{exam.no_of_questions}} Que | {{exam.total_mark}}
                      Marks</span>
                  </td>
                  <td class="tr">
                    <button class="badge_2" *ngIf='exam.completed_on && exam.completed_on != null'
                      (click)='onClickView(exam)'>View Result</button>

                  </td>
                  <td class="tr">
                    <button class="badge_2" *ngIf='!exam.completed_on || exam.completed_on == null'
                      (click)='onClickView(exam)'>Start & View Exam</button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>