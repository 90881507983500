import { CategoryService } from './../../providers/category.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommonService } from '../../providers/common.service';
import { ItemService } from '../../providers/item.service';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { VideoDetailComponent } from '../video-detail/video-detail.component';
import { PackageListComponent } from '../package-list/package-list.component';
import { EventPublishService } from '../../providers/event-publish';
import { Router, NavigationEnd } from '@angular/router';
import { ProfileService } from '../../providers/profile.service';
import { SigninComponent } from '../signin/signin.component';
import { UserService } from '../../providers/user.service';
import { ExamService } from 'src/app/providers/exam.service';
import { LoginActivate } from 'src/app/providers/loginActivate';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loadingCourse: boolean = false
  courseList: any = []
  userDetails: any = []
  loadingCourseGroup: boolean = false
  courseGroupList: any = []
  selectedGroup: any
  is_subscribed: any
  type: any = 'video'
  loadingItems: boolean = false
  per_page: number = 3
  loadingVideos:boolean=false;
  page: number = 1
  itemsList: any = []
  length:any;
  sub_id: any = null
  week_id: any = null
  loadingExams:boolean=false;
  day_id: any = null
  selected_course: any;
  constructor(public category: CategoryService, public common: CommonService,
    public itemService: ItemService, public dialog: MatDialog, private datePipe: DatePipe,
    public events: EventPublishService, public router: Router, public zone: NgZone,
    public profile: ProfileService, public user: UserService,public exam:ExamService,public loginActivate:LoginActivate) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  ngOnInit() {

    this.selected_course = localStorage.getItem('uexCourse')
    
    this.loadCourseGroup()
    this.loadItems('video')

    this.events.getObservable().subscribe((data) => {
      if (data == 'uexUser:courseChanges') {
        this.zone.run(() => {
          this.getProfile()
          // if (this.common.selectedCourse != null) {
          //   this.loadItems('video')
          //   this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
          // } else {
          //   this.loadItems('video')
          //   this.common.selectedCourse = null
          // }
        })
      }
      if (data == 'uexuser:login') {
        this.zone.run(() => {
          setTimeout(() => {
            this.userDetails = []
            this.getProfile()
          }, 500)
        })
      }
      if (data == 'uexuser:logout') {
        this.zone.run(() => {
          setTimeout(() => {
            this.itemsList = []
            this.userDetails = []
            this.common.selectedCourse = null
            this.getProfile()
            this.loadItems('video')
            this.common.selectedCourse = null
          }, 500)

        })
      }
    })


  }
  
  keyfee: OwlOptions = {
    loop: false,
    autoplayHoverPause:true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    nav: false,
    autoHeight: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
  }
  course: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    nav: false,
    autoHeight: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
  }
  banner: OwlOptions = {
    loop: true,
    autoplay:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    nav: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }
  howitwork: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }

  getProfile() {
    
    this.profile.getUserDetails().subscribe(res => {
      
      if (res.success == true) {
        this.userDetails = res.data;
        
        if (this.userDetails.subscription && this.userDetails.subscription.length == 1) {
          this.common.selectedCourse = this.userDetails.subscription[0].course
          
          localStorage.setItem(this.common.SELECTED_COURSE, JSON.stringify(this.common.selectedCourse))
        } else if (this.userDetails.subscription.length > 1) {
          
          this.common.selectedCourse = this.userDetails.subscription.find(x => x.course_id == this.userDetails.current_course_id).course
          localStorage.setItem(this.common.SELECTED_COURSE, JSON.stringify(this.common.selectedCourse))
        }

        if (this.common.selectedCourse != null) {
          this.loadItems('video')
        } else {
          this.loadItems('video')
          this.common.selectedCourse = null
        }
      }
    }, err => {
      return false;
    });
  }

  loadCourseGroup() {
    this.loadingCourseGroup = true;
    this
      .category.getAllCourseGroups()
      .subscribe(res => {
        if (res.success == true) {
          this.courseGroupList = res.data;
          if (this.courseGroupList && this.courseGroupList.length > 0) {
            this.onClickView(this.courseGroupList[0]._id)
          }

        }
        this.loadingCourseGroup = false;
      }, err => {
        this.loadingCourseGroup = false;
        this.common.processError(err)
        return false;
      });
  }

  onClickView(id) {
    this.selectedGroup = id
    this.loadingCourse = true
    this
      .category.getCourseGroupById(id,)
      .subscribe(res => {
        if (res.success == true) {
          this.courseList = res.data;
        }
        this.loadingCourse = false
      }, err => {
        this.common.processError(err)
        this.loadingCourse = false
        return false;
      });
  }

  loadItems(type: any = null) {
    if (type != null) this.type = type
    let course_id = null
    this.loadingItems = true;
    this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
    
    if (this.common.selectedCourse && this.common.selectedCourse != null) {
      course_id = this.common.selectedCourse._id
      var cur_day = 1
    }
    if(this.type !='mock'){
    this
      .itemService
      .getItems(this.per_page = 3, this.page, course_id, this.sub_id,
        this.week_id, this.day_id, type, 1)
      .subscribe(res => {
        if (res.success == true) {
          this.itemsList = res.data.data
          
          // this.itemsList = []
          
        }
        this.loadingItems = false;
      }, err => {
        this.loadingItems = false;
        this.common.processError(err);
        return false;
      });

    }
      if (type == 'mock') {
        let course_id = this.common.selectedCourse._id
        this.loadingExams = true;
        this.itemsList=[];
        this.exam.getExam(this.page, this.per_page,this.sub_id, course_id,)
          .subscribe(res => {
            if (res.success == true) {
              this.length = res.data.total
              res.data.data.forEach(element => {
                if (!this.itemsList.find(x => x._id == element._id)) {
                  this.itemsList.push(element);
                  this.loadingItems = false;
                  console.log(this.itemsList)
                  
                }
              });
            }
            this.loadingVideos = false;
            this.loadingItems = false;
          }, err => {
            this.loadingVideos = false;
            this.loadingItems = false;
            this.common.processError(err);
            return false;
          });
  
  
      }
  }

  onClickMaterialView(type, value) {
    var is_view :boolean=false

    if(!this.user.hasLoggedIn){
      if(this.common.played_video.video_count >= 3){
        console.log(this.common.played_video.ids.find(x=>x._id == value._id ) )
        if(this.common.played_video.ids.find(x=>x == value._id )){
          is_view=true
        }else{
          is_view=false
          this.loginActivate.onLogin()
        }
      }else{
        is_view=true
      }
    }else{
      is_view=true
    }


    if(is_view){
    this.common.type = type
    // if (value.can_view) {
      if ((!value.need_to_subscribe || value.need_to_subscribe) && value.type =='free') {
      this.common.type = type
      this.materialView(type, value)
      }else if(value.need_to_subscribe && value.type =='premium'){
        this.common.showToast("Please Subscribe to Continue")
      }else if(!value.need_to_subscribe && value.type =='premium'){
        this.common.type = type
        this.materialView(type, value)
       }
    // }
    // else {
    //   if (value.need_to_subscribe) {
    //     this.common.showToast("Please Subscribe to Continue")
    //     this.subscribe(value.course_id)
    //   } else {
    //     if(value.published_on  !=null){
    //       this.common.showToast("Content not Available Now, Please Check On " + this.datePipe.transform(value.published_on, 'dd-MM-yyyy'))
    //     }
    //     if(!value.published_on  ==null){
    //       this.common.showToast("Content not Available Now, Please Check On ")
    //     }
    //   }
    // }
      }
  }

  materialView(type, value) {
    
    const dialogRef = this.dialog.open(VideoDetailComponent, {
      panelClass: "full_modal",
      data: {
        id: value._id,
        sub_id: value.subject_id ? value.subject_id : null,
        week_id: value.week_id ? value.week_id : null,
        day_id: value.day_id ? value.day_id : null,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadItems(type)
    });
  }

  subscribe(course_id) {
    const dialogRef = this.dialog.open(PackageListComponent, {
      data: {
        flag: true,
        course_id: course_id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  onSelectCourse(course) {
    localStorage.setItem(this.common.COURSE, JSON.stringify(course))
    this.events.publishSomeData(this.common.COURSE_CHANGES);
    this.router.navigate(['/course-week'])
  }


  onLogin() {
    const dialogRef = this.dialog.open(SigninComponent, {
      panelClass: 'sign_in_box',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.success && result.success == true) {
        // this.loadCategories();
      }
    });
  }

  onClickViewExam(value) {
    
    if (value.completed_on && value.completed_on != null) {
      this.router.navigate(['/exam-result', value.result_id])

    } else {
      this.router.navigate(['/exam-view', value._id])

    }
  }
}
