<div class="row" infiniteScroll [infiniteScrollDistance]="0.5" (scrolled)="onScroll($event)">
  <div class="notif_d">
    <div class="mt10 mb20 noti_child tc">
      <h3 class="m0 p0">Today</h3>
    </div>
    <div class="noti_lop">
      <div class="noti_loop_inner" *ngFor="let not of notification">
        <table>
          <tr (click)="onClick(not)">
            <td class="tl">
              <img src="assets/img/noti.svg">
            </td>
            <td class="no_two_tb tl">
              <span class="medium block mb5">{{not?.title}}</span>
              <p class="m0 p0">{{not?.description}}</p>
            </td>
          </tr>
        </table>
      </div>

      <div class="no_not_f mb20" *ngIf="length == 0">
        <table>
          <tr>
            <td class="tc">
              <span class="medium block mb5">No Notification!</span>
            </td>
          </tr>
        </table>
      </div>


    </div>
  </div>
</div>