import { Injectable, DefaultIterableDiffer } from '@angular/core';
import * as moment from 'moment';
import { Api } from './api';
import { map } from "rxjs/operators";
// import { NavController } from '@ionic/angular';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ExamService {
  public questions: any = []
  public reviewQuestions: any = []
  public resultData: any = []
  public startTime: any;
  public examTime: any;
  public viewQuestion: any;
  public examDetail: any;
  public leftTime: any
  public leftTimeSeconds: any = 0
  public isExamComplete:any = false
  public isCalculate: boolean = false
  refreshsetInterval
  constructor(public api: Api, public common: CommonService,public router: Router) {

  }

  calculateLeftTime() {
    if(localStorage.getItem(this.common.QUESTION_FLAG)){
    var type = localStorage.getItem(this.common.QUESTION_FLAG)
  }
    this.isCalculate = true
    this.isExamComplete = true
    this.startTime =  localStorage.getItem(this.common.START_TIME)
    this.examTime = localStorage.getItem(this.common.EXAM_TIME)
    console.log( this.startTime , this.examTime)
    let diff = moment().diff(moment(this.startTime), 'seconds')
    this.leftTimeSeconds = this.examTime - diff;
    this.refreshsetInterval = setInterval(() => {
      if (this.leftTimeSeconds > 0) {
        this.leftTimeSeconds--;
      } else if(this.leftTimeSeconds == 0 && type == 'question'){
        this.countdownTimer()
      }
    }, 1000);
  }

  countdownTimer() {
    if (this.refreshsetInterval) {
      clearInterval(this.refreshsetInterval);
    }
    localStorage.removeItem('timer')
    // this.router.navigate(['/submit-exam'])
  }
  countdownComplete(id) {
    this.startTime = 0
    this.examTime = 0 
    this.leftTimeSeconds = 0
    if (this.refreshsetInterval) {
      clearInterval(this.refreshsetInterval);
    }
    localStorage.removeItem('timer')
    localStorage.removeItem(this.common.START_TIME)
    localStorage.removeItem(this.common.EXAM_TIME)

    console.log(id,'countdownComplete')
    this.router.navigate(['/exam-result',id])
   
  }

  getExam(page=0,per_page=0,subject_id=0,course_id=null,week_id=null,day_id=null,cur_day_only=null) {
    let endpoint = 'exams?';
    if(page > 0) endpoint += 'page=' + page + '&'
    if(per_page > 0) endpoint += 'per_page=' + per_page + '&'
    if(course_id != null) endpoint += 'course_id=' + course_id + '&'
    if(subject_id > 0) endpoint += 'subject_id=' + subject_id + '&'
    if(week_id != null) endpoint += 'week_id=' + week_id + '&'
    if(day_id != null) endpoint += 'day_id=' + day_id + '&'
    if(cur_day_only != null) endpoint += 'cur_day_only=' + cur_day_only 
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }

  examView(exam_id) {
    let endpoint = 'exams/' + exam_id;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  examStart(exam_id) {
    let endpoint = 'exams/' + exam_id + '/start';
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  examQuestions(exam_id) {
    let endpoint = 'exam/questions/' + exam_id;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  submitExam(data) {
    console.log(data)
    let endpoint = 'exams/submit-exam';
    return this
      .api
      .post(endpoint, data)
      .pipe(map(resp => resp.json()));
  }
  myExam() {
    let endpoint = 'exam/myresult';
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  myExamView(exam_id) {
    let endpoint = 'exam/myexamview/' + exam_id;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  examReview(exam_id) {
    let endpoint = 'exam/myquestionreview/' + exam_id;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  myResult(page=0,per_page=0) {
    let endpoint = 'exams/results?' ;
    if(page > 0) endpoint += 'page=' + page + '&'
    if(per_page > 0) endpoint += 'per_page=' + per_page 
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  myResultView(exam_id) {
    let endpoint = 'exams/results/'+ exam_id ;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  freeMockTest() {
    let endpoint = 'exam/freemocktest';
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
}
