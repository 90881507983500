<app-header></app-header>
<section class="home_course_tab mt50">
  <div class="container">
    <div class="row mb20">
      <div class="col-md-12 tl">
        <h2>TERMS & CONDITIONS</h2>
        <p>
          The information contained on OCOM website/ app is for information purposes only. While every attempt has been
          made to
          ensure genuine and exactness of the information, OCOM assumes no responsibility for any inadvertent errors or
          omissions
          in the contents of the Service. <br><br>
          In no event shall OCOM be liable for any special, direct, indirect, consequential or incidental damages or any
          damages
          whatsoever, whether in an action or contract, negligence or otherwise, arising out of or in connection with
          the use of
          the Service or the contents of the same. <br><br>
          OCOM reserves the right to make additions, deletions or modiﬁcation to the contents on the Service at any time
          without
          prior notice. While OCOM follows all legal and regulatory compliances, best practices, safety standards and
          protocols,
          OCOM cannot guarantee that the website is fully free of viruses or other harmful components.<br><br>
          OCOM may contain links to external websites that are not provided or maintained by or in any way afﬁliated
          with OCOM and
          hence does not guarantee the accuracy, relevance, timeliness or completeness of any information on these
          external
          websites. The inclusion of any links does not necessarily imply a recommendation or endorse the views
          expressed within
          them.<br><br>
          Some of the educational content available on OCOM may contain logos/symbols/trademarks/tradenames etc. of
          reputed
          organizations. These logos/symbols/trademarks/tradenames etc. and all rights and privileges associated with
          them are the
          sole properties of their respective owners. All references to these organizations have been taken from
          free-to-air
          sources. The inclusion of the same as mentioned above does not necessarily imply a recommendation or endorse
          the views
          expressed within them. <br><br>
          Every effort is made to keep the app/website up and running smoothly. However, OCOM takes no responsibility
          for, and
          will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
        </p>
        <br>
        <h2>TERMS AND THE PRIVACY POLICY</h2>
        <p>
          These Terms & Conditions (“Terms”) govern use of our website/app www.OCOMACCOUNTS.COM
          (“Website/Application/App/We”), or
          any products or services in connection with the Application/, Website/products. <br><br>
          Please read the Terms and the privacy policy of OCOM (“Privacy Policy”) with respect to registration, use of
          the
          App/Website/Services and/or products carefully before using the same. In the event of any discrepancy between
          the Terms
          and any other policies with respect to the Application or Website or Services or products, the provisions of
          the Terms
          shall prevail. <br><br>
          Your use/access/browsing of the Application or Website or the Services or products or registration (with or
          without
          payment/with or without subscription) through any means shall signify Your acceptance of the Terms and Your
          agreement to
          be legally bound by the same. <br><br>
          If You do not agree with the Terms or the Privacy Policy, please do not use the Application or Website or
          avail the
          Services or products. Any access to our Services/Application/products through registrations/subscription is
          non-transferable. <br><br>
          The users of OCOM claim total and complete responsibility for the selection and use of the service(s) and
          neither OCOM
          nor any of its information providers, licensers, employees or agents shall have any liability for any errors,
          malfunctions, defects, loss of data, resulting from the use or inability to use the service. While utmost care
          is taken
          to provide correct and up to date information, OCOM does not warrant the accuracy, completeness and timely
          availability
          of the information provided on the site and accepts no responsibility or liability for any error or omission
          in any
          information provided on the site/app nor does the site/app claim to be comprehensive in it's coverage of the
          KAS
          selection examination. <br><br>
          This site contains information which has been compiled from various sources, including primary research
          conducted by, or
          on behalf of OCOM as well as secondary or desk research. OCOM will in no event be liable for any direct,
          incidental,
          consequential or indirect damages arising out of the use of or inability to use and/or for any delay in the
          service
          provided by the site. OCOM also reserves the right and discretion to make any changes/corrections or
          withdraw/add/modify
          contents at any time without notice. However, any feedback from the User(s) is most welcome to make the
          Application and
          its contents error-free and user friendly. <br><br>
          Any product purchased online on www.OCOM is non-transferable and is intended for the sole use of the buyer
          only. THE
          INFORMATION AVAILABLE ON THIS SITE IS FOR GENERAL INFORMATION PURPOSES. <br><br>
          Our Website/App provides Users with access to compiled educational information and related sources. Such
          information is
          provided on an “As Is” basis and OCOM assumes no liability for the accuracy, timeliness or completeness of
          such
          information. We shall not be liable to update or ensure continuity of such information contained on the
          Website. We
          would not be responsible for any errors, which might appear in such information, which is compiled from third
          party
          sources or for any unavailability of such information. From time to time we may also include links to other
          websites.
          These links are provided for your convenience to provide further information. They do not signify that we
          endorse the
          website(s). We have no responsibility for the content of the linked website(s). You may not create a link to
          the Website
          from another website or document without the OCOM’s prior written consent. <br><br>
          The lessons/videos/tests (or any other content) on the OCOM website/App will be rolled out in a phased and
          time-bound
          manner. The scheduling of the same will be the sole discretion of OCOM only. <br><br>
          Certain contents in the Application/Services/Website/products (in particular relating to assistance in
          preparations for
          administrative services) may contain opinions and views. OCOM shall not be responsible for such opinions or
          any claims
          resulting from them. Also, OCOM makes no warranties or representations whatsoever regarding the quality,
          content,
          completeness, or adequacy of such information and data.
          Some parts of the Services are interactive, and we encourage contributions by Users, which may or may not be
          subject to
          editorial control prior to being posted. OCOM accepts no responsibility or liability for any material
          communicated by
          third parties in this way. OCOM reserves the right at its sole discretion to remove, review, edit or delete
          any content.
          Similarly, we will not be responsible or liable for any content uploaded by Users directly on the Website,
          irrespective
          of whether We have certified any answer uploaded by the User. We would not be responsible to verify whether
          such
          questions/answers or contents placed by any User contain infringing materials or not.<br><br>
          OCOM may, based on any form of access to the Application (including free download/trials) or Services or
          Website or
          registrations through any source whatsoever, contact the User through SMS, email, WhatsApp and call, to give
          information
          about its products as well as notifications on various important updates and/or to seek permission for
          demonstration of
          its products. The User expressly grants such permission to contact him/her through telephone, SMS, e-mail,
          WhatsApp and
          holds OCOM indemnified against any liabilities including financial penalties, damages, expenses in case the
          User’s
          mobile number is registered with Do not Call (DNC) database. By registering yourself, you agree to make your
          contact
          details available to Our employees, associates and partners so that you may be contacted for education
          information and
          promotions through telephone, SMS, email, WhatsApp etc.<br><br>
          Access to certain elements of the Services including doubt clearance, mentoring services etc. may be subject
          to separate
          terms, conditions and fair usage policy. OCOM reserves the right to determine the criteria for provision of
          various
          elements of Services to the different categories of Users based on its policies. Hence, subscription to the
          Application/products or registrations do not automatically entitle the User to any and all elements of
          Services provided
          by OCOM and OCOM shall be entitled to exercise its discretion while providing access to and determining
          continuity of
          certain elements of Services. We reserve the right to extend, cancel, discontinue, prematurely withdraw or
          modify any of
          Our Services at Our discretion. <br><br>
          OCOM shall have no responsibility for any loss or damage caused to any hardware and / or software and/or
          instrument,
          including loss of data or any other adverse effect resulting from Your use of our products and Services.
          OCOM reserves the right to change modify, suspend, or discontinue and/or eliminate any aspect(s), features or
          functionality, including but not limited to the Terms of Service of the Application or the Services as it
          deems fit at
          any time without notice. OCOM makes no commitment, express or implied, to maintain or continue any aspect of
          the
          Application. You agree that OCOM shall not be liable to You or any third party for any modification,
          suspension or
          discontinuance of the Application/Services.
        </p>
        <br>
        <h2 id="privacy">PRIVACY POLICY</h2>
        <section id="privacy">
        <p>
          This Privacy Policy has been prepared under the provisions of the (Indian) Information Technology Act, 2000
          (IT Act) and
          the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or
          Information)
          Rules, 2011 promulgated thereunder, and sets out the practices and policies for protection of user information
          collected, received, possessed, stored, dealt with or handled by Company.
          'We' or 'Us' refers to OCOM, and 'You' refers to the users of our Services who purchase, intend to purchase or
          inquire
          about any Services. <br>
          This privacy policy (Privacy Policy) governs the collection, processing and disclosure practices of user
          information by
          us and informs you about:<br>

          A -What type of information is collected from you;<br>
          B -Why such information is being collected;<br>
          C -When and in what manner can we use this information; and<br>
          D -Whether we will disclose such information to third parties<br>

          By using or accessing OCOM, the User hereby agrees with the terms of this Privacy Policy and the Terms and
          Conditions.
          By using the Services, you are agreeing to the terms of the Privacy Policy. The Privacy Policy is subject to
          revisions
          at any time, as determined by Us, without notice, and any such changes are effective immediately upon being
          posted on
          OCOM; any use of the Services thereafter will be deemed to be an acceptance of these changes by You.
        </p>
      </section>
        <br>

        <h4>1 -Type of information collected</h4>
        <p>
          OCOM collects user information to ensure that Services being provided by OCOM can be used by Users
          effectively. OCOM
          collects information from Users, including operating system, browser type, and the name of the internet
          service
          provider.
        </p>
        <br>
        <h4>2 -Personal Information</h4>
        <p>
          OCOM collects various types of information to establish and maintain your account with OCOM. We collect your
          full name,
          e-mail address, telephone number, age, residential address and/or billing address, gender and banking details
          in order
          to provide Services. We also collect information we receive from other public sources and any other
          information received
          from a third party in order to provide, and allow you to use the Services. We also collect information from
          your
          computer or mobile device, such as unique identification number, model, operating system and version, your
          browser type
          and version, the presence of any device, the portion of Services used, and time and location of access of
          Services.
        </p>
        <h4>3 -Communication</h4>
        <p>
          You consent to receive communication including advertisement and review calls from OCOM and also to take part
          in surveys
          in relation to your use of Services. The information received is used to make further improvements to our
          Services and
          to develop better features and promotions for users.
        </p>
        <br>
        <h4>4 -Purpose of collection and use of information</h4>
        <p>
          We collect, store and disclose User information relating in order to provide the Services either directly or
          through
          third party service providers and to fulfil your requests regarding Services including but not limited to
          confirming
          reservations, communicating transaction status, sending booking confirmations and updates and allowing
          customer service
          to contact You. We may also use information provided by you to: (i) enforce our Terms of Use Agreement; (ii)
          monitor
          user activity and effectively manage User accounts and our website; (iii) assist you with technical
          difficulties; (iv)
          maintain User accounts; (v) collect fees; and (vi) minimizing fraud and abuse of Services. Certain information
          may be
          collected each time Users access or use the website which is saved in server logs, which includes IP address,
          operating
          system and browser on/from which Users are accessing the Website. We may also collect User information to
          personalize
          your experience on the website and sending you information and notifications regarding Services. We also
          collect User
          information to provide you with offers and deals from third parties.
          OCOM will retain your information as long as it is reasonably necessary for the purposes listed in this
          policy. In some
          circumstances, it might be retained for longer periods of time in accordance with any legal, regulatory, tax
          or
          accounting requirements. When your personal data is no longer required it shall be securely deleted or stored
          in a way
          which means it will no longer be used by OCOM
        </p>
        <br>
        <h4>5 -Data Collection Devices</h4>
        <p>
          We may use data collection devices known to collect and store information of users accessing OCOM, including
          cookies and
          trackers. Cookies are used to identify information specific to you including your browsing history and search
          preference. Cookies allow us to serve you better and more efficiently. You also consent to sharing your
          geo-location
          data in order to publish information/ advertisements offered by Users closers to your location and vice-versa.
        </p>
        <br>
        <h4>6 -Disclosure to Third Parties</h4>
        <p>
          When we share User information with third parties, we hold all such parties to the same or higher standards as
          we do
          ourselves regarding the privacy of your information. OCOM publishes and discloses User information to provide
          effective
          access to Service, to comply with legal obligation and enforce the Terms of Use Agreement. We may use third
          party
          individuals/entities to perform certain parts of the Services and as a result may share your User information.
          You
          hereby authorize OCOM to collect, use, store, analyse, reproduce or publish (either on its own or through
          third party
          service provider(s) the information in relation to your use of OCOM for data analysis and for improving your
          experience
          in OCOM. We may also share some User information with third party advertisers who are interested in
          advertising their
          product to you. You can opt-out of this service by contacting us through the contact details given below or by
          clicking
          the unsubscribe link. We may disclose information about our users to law enforcement officers or others, in
          the good
          faith belief that such disclosure is reasonably necessary to enforce the Terms of Use Agreement and/or Privacy
          Policy;
          respond to claims that any content violates the rights of third-parties; or protect the rights, property, or
          personal
          safety of our users, the general public or ourselves. You agree and acknowledge that we may not inform you
          prior to or
          after disclosures made according to this section. If substantially all of our assets are sold or merged into
          another
          company, the acquiring company shall be given access to your personal or other information without your
          consent. If we
          sell only a part of our business, the acquiring entity shall have access to your User information without your
          consent.
        </p>

        <br>
        <h4>7 -Security Practices and Procedures</h4>
        <p>
          We strive to ensure confidentiality and security of User information by using commercially and legally
          acceptable means.
          We also have a comprehensive documented information security program and information security policies in
          place.
          Additionally, we use firewalls and data encryption. However, as with most electronic transactions, no method
          is 100%
          safe. Therefore, you acknowledge and agree that we assume no liability regarding the theft, loss, alteration
          or misuse
          of personal or other information or content, including, without limitation, such information that has been
          provided to
          third parties or other users, or with regards to the failure of a third party to abide by the agreement
          between us and
          such third party. You also play an important role in keeping your personal information secure. You should not
          share your
          user’s name, password, or other security information for your OCOM account with anyone. If we receive
          instructions using
          your username and password, we will consider that you have authorized the instructions. In the unlikely event
          that we
          come to learn that either our system or your account specifically has suffered a security breach, we may
          attempt to
          notify you electronically so that you can take appropriate protective steps, by e-mail, text message, phone
          call, a
          notice on our website, or any other way, depending upon the requirements of the applicable law.
        </p>
        <br>
        <h4>8 -Links to Other Websites</h4>
        <p>
          We may provide links to third-party websites, payment gateways and advertisements that collect User
          information on OCOM.
          The controllers of such third-party websites (Third Party Advertiser) or applications may receive some User
          information
          using devices and technologies to make their advertising more effective. We may not have control over such
          technologies
          or devices used by the Third-Party Advertiser and we do not represent or warrant anything pertaining to the
          security
          practices followed by the Third-Party Advertiser. The User information collected by such Third-Party
          Advertiser is
          governed by their respective privacy policy.
        </p>


        <br>
        <h4>9 -Change in Privacy Policy </h4>
        <p>

          We reserve the right to update, modify and amend any of the terms of this Privacy Policy, at any time without
          prior
          intimation to you. We will post these changes on OCOM. These changes will become effective immediately on
          posting. We
          shall not be liable for any failure or negligence on your part to review the updated Privacy Policy before
          accessing or
          using OCOM. Your continued use of OCOM, following changes to the Privacy Policy, will constitute your
          acceptance of
          those changes.
          If you choose to use the embedded link function, you agree not to embed a link on or within any illegal site,
          including
          but not limited to any site that is indecent, obscene or pornographic or that is determined to be otherwise
          objectionable in our sole and absolute discretion, and we reserve the right to disable embedded links at any
          time
          without notice to any Users.
          Any violation of these above terms and conditions will leave Users liable to having their Services terminated.
          The above
          statements are taken very seriously by OCOM and violation of these statements will not be tolerated.
        </p>
        <br>
        <h2 id="refund-policy">PRICING</h2>
        <p>
          All prices are subject to change without notice.
          Gift coupons/price discounts/ promotional offers etc. are at the sole discretion of OCOM.
          
        </p>
        <br>
        
        <h2>REFUND POLICY</h2>
        <p>
          Programs/ Products once purchased will NOT be refunded, except in case the same program is purchased twice due to some server error, for which the following refund rules will apply - If the buyer informs us within 7 days, full refund would be done for only one program.
        </p>

        <br>

        <h2>FEEDBACK:</h2>
        <p>
          Any feedback You provide with respect to the Application shall be deemed to be non-confidential. The
          Application shall
          be free to use such information on an unrestricted basis. For communicating feedback, contact us at
          ocomacc@gmail.com
        </p>


      </div>
    </div>
  </div>
</section>

<app-subscription-box></app-subscription-box>
<app-footer></app-footer>
<app-mobile-nav></app-mobile-nav>