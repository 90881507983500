import { Component, OnInit, ViewChild, ElementRef, ɵConsole } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ExamService } from '../../providers/exam.service';
import { CommonService } from '../../providers/common.service';
import { MatDialog } from '@angular/material/dialog';
import { EventPublishService } from '../../providers/event-publish';


@Component({
  selector: 'app-exam-result',
  templateUrl: './exam-result.component.html',
  styleUrls: ['./exam-result.component.scss']
})
export class ExamResultComponent implements OnInit {
  isLoading:boolean=false
  title = '';
  type = 'PieChart';
  data = [];
  loadingVideos:boolean=false;
  columnNames = ['Browser', 'Percentage'];

  dataCorrect = [
    
  ];
  optionsCorrect = {
    width: 150,
    height: 150,
    backgroundColor: 'transparent',
    pieHole: 0.7,
    pieSliceTextStyle: {
      color: 'transparent',
    },
    slices: {
      0: { color: '#50c690' },
      1: { color: 'gray' },
    },
    legend: 'none',
    chartArea: {
      top: "2%",
      height: "100%",
      width: "100%"
    },
  };

  dataWrong = [
   
  ];
  optionsWrong = {
    width: 150,
    height: 150,
    backgroundColor: 'transparent',
    pieHole: 0.7,
    pieSliceTextStyle: {
      color: 'transparent',
    },
    slices: {
      0: { color: '#50c690' },
      1: { color: 'gray' },
    },
    legend: 'none',
    chartArea: {
      top: "2%",
      height: "100%",
      width: "100%"
    },
  };

  dataSkip = [
    
  ];
  optionsSkipped = {
    width: 150,
    height: 150,
    backgroundColor: 'transparent',
    pieHole: 0.7,
    pieSliceTextStyle: {
      color: 'transparent',
    },
    slices: {
      0: { color: '#50c690' },
      1: { color: 'gray' },
    },
    legend: 'none',
    chartArea: {
      top: "2%",
      height: "100%",
      width: "100%"
    },
  };

  optionlist = {
    width: 150,
    height: 150,
    backgroundColor: 'transparent',
    pieHole: 0.7,
    pieSliceTextStyle: {
      color: 'transparent',
    },
    slices: {
      0: { color: '#50c690' },
      1: { color: 'gray' },
    },
    legend: 'none',
    chartArea: {
      top: "2%",
      height: "100%",
      width: "100%"
    },
  };
  examId: any
  constructor(public route: ActivatedRoute, public dialog: MatDialog,
    public exam: ExamService, public common: CommonService,
    public router: Router, public events: EventPublishService) {

      this.router.events.subscribe((evt) => {
        console.log(evt)
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0)
      });

    if (this.common.selectedCourse != null) {
      this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
    }
    this.route.params.subscribe((params) => {
      console.log(params)
      this.examId = params['id']
      this.loadViewExam()
    })
  }

  ngOnInit(): void {
  }


  loadViewExam() {
    this.loadingVideos=true;
    this.isLoading = true
    this.exam.myResultView(this.examId).subscribe(res => {
      if (res.success) {
        this.exam.resultData = res.data;
        console.log(this.exam.resultData)
        localStorage.removeItem(this.common.EXAM_QUESTIONS)
        localStorage.setItem(this.common.EXAM_QUESTIONS, JSON.stringify(res.data.questions));
        localStorage.setItem(this.common.EXAM_NAME, JSON.stringify(res.data.exam.name));
        this.exam.questions = JSON.parse(localStorage.getItem(this.common.EXAM_QUESTIONS))
        // //this.data["attended"] = this.exam.resultData.total_questions;
        if(this.exam.resultData.total_mark <= 0){
          this.data = [
            ['total',0],
            ['totalCorrect',1],
          ]
        }else{
          this.data = [
            ['total',this.exam.resultData.total_mark ],
            ['totalCorrect',this.exam.resultData.maximum_mark],
          ]
        }
       
        console.log(this.data)
        this.dataCorrect = [
          ['correct', this.exam.resultData.total_correct_answers],
          ['other', this.exam.resultData.total_questions - this.exam.resultData.total_correct_answers]
        ];
        console.log(this.dataCorrect)
        this.dataWrong = [
          ['wrong', this.exam.resultData.total_wrong_answers],
          ['other', this.exam.resultData.total_questions - this.exam.resultData.total_wrong_answers]
        ];
        this.dataSkip = [
          ['skipped', this.exam.resultData.total_unattended],
          ['skipped', this.exam.resultData.total_questions - this.exam.resultData.total_unattended]
        ];
      }
      this.isLoading = false
      this.loadingVideos=false;
    }, err => {
      console.error('ERROR', err);
      this.isLoading = false
      this.common.processError(err);
    })
  }

  getPercentage(value) {
    return ((value / this.exam.resultData.total_questions) * 100).toFixed(0)
  }

  allQuestion(){
    localStorage.setItem("timer",'true')
    localStorage.setItem(this.common.QUESTION_FLAG, 'result')
  }

}
