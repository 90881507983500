import { DatePipe, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../providers/common.service';
import { ItemService } from '../../providers/item.service';
import { UserService } from '../../providers/user.service';
import { PackageListComponent } from '../package-list/package-list.component';
import Player from '@vimeo/player';
import { ProfileService } from '../../providers/profile.service';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import { LoginActivate } from 'src/app/providers/loginActivate';
declare let jwplayer: any
declare let vimeo: any
@Component({
  selector: 'app-video-detail',
  templateUrl: './video-detail.component.html',
  styleUrls: ['./video-detail.component.scss']
})
export class VideoDetailComponent implements OnInit {
  
  @HostListener('window:blur', ['$event'])
  onWindowBlur(event: any): void {
    console.log('iframe clicked');
    var is_seen_video :boolean=false
    console.log(this.common.played_video.length)


        if(this.user.hasLoggedIn){
          is_seen_video=false
        }else{
          is_seen_video=true
        }
      

    

    if(is_seen_video){
      console.log("hhhhhhhhhhhhhhhhhh")
      if(!this.common.played_video.ids && !this.common.played_video.video_count){
        this.common.played_video.ids=[]
        this.common.played_video.video_count=0
      }

      console.log("kkkkkkkkkkkkkkkkk")
      if(!this.common.played_video.ids.find(x=>x == this.id)){
        this.common.played_video.ids.push(this.id)
        this.common.played_video.video_count +=1
        console.log(this.common.played_video)
        localStorage.setItem(this.common.VIDEO_COUNT,JSON.stringify(this.common.played_video))
      }else{
        is_seen_video=false
      }
 
    }
  }

  id: any
  type: any
  isReplay:boolean=false
  commentId:any
  loadingMaterial: boolean = false
  isMateriaTop: boolean = false
  detailsData: any = []
  commentForm: FormGroup = new FormGroup({
    comment: new FormControl('', [Validators.required]),
    type: new FormControl('main'),
    comment_id: new FormControl(''),
    comment_person_name: new FormControl(''),
  });
  playerInstance: any
  playerVimeo: any
  loadingVideos: boolean = false
  videoList: any = []
  pdfList: any = []
  page: any = 1
  perpage; any = 3
  loadingExams: boolean = false
  ExamList: any = []
  sub_id: any
  week_id: any
  navigation:any='next';
  previous:any='previous'
  day_id: any
  length: any
  day:any;
  week:any;
  is_subscribed:boolean=false
  get vcf() { return this.commentForm.controls; }
  loadingComments: boolean = false;
  loadingCommenting: boolean = false;
  itemComments: any = [];
  commentspage: any = 1;
  commentsperpage: any = 20;
  commentstotalPages: number;
  commentshasLoadMore: boolean = false;
  userDetails: any = []
  constructor(public common:CommonService,public dialogRef: MatDialogRef<VideoDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public item:ItemService,
    public user:UserService,public dialog:MatDialog,public profile:ProfileService,
    public datePipe:DatePipe,public itemService:ItemService, @Inject(PLATFORM_ID) private platformId: Object,public loginActivate:LoginActivate) {
    if (this.common.selectedCourse != null) {
      this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
    }
     console.log(data)
    if (data.id) {
      this.id = data.id;
      this.sub_id = data.sub_id;
      this.week_id = data.week_id;
      this.day_id = data.day_id;
      this.day=data.day
      this.week=data.week
      console.log(this.id)
    }

    this.commentspage = 1
    this.commentsperpage = 20
    this.commentstotalPages = 0
    this.itemComments = []
    if (!this.common.tab_type) this.common.tab_type = 'video'
    this.loadItemById(this.id,this.navigation,this.previous);
    this.loadCommentsByItemId()
    this.getProfile()
    this.loadItems(this.sub_id, this.week_id, this.day_id, 'video')
    this.loadItems(this.sub_id, this.week_id, this.day_id, 'pdf')
   }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      history.pushState({}, document.getElementsByTagName('title')[0].innerHTML, window.location.href);
  }
  }

  ionViewWillEnter() {
    this.common.isFetching = false
    this.playerInstance = jwplayer("jwplayerdiv");
  }

  loadItemById(_item_id,navigation,previous) {
    this.loadingMaterial = true;
    this.isMateriaTop = true
    this.detailsData = []
    this
      .item
      .getItemByid(_item_id,navigation,previous)
      .subscribe(res => {
        if (res.success == true) {
          this.detailsData = res.data;
          console.log(this.detailsData)
          if(!this.detailsData.can_view){
            this.is_subscribed=true
            this.common.showToast("Please subscribe to view the video")
          }
          this.loadItems('', this.detailsData.week_id, this.detailsData.day_id, 'video')
          this.loadItems('', this.detailsData.week_id, this.detailsData.day_id, 'pdf')
          this.type = this.detailsData.item_type;
          this.user.updateFreeCount(this.type)
          if (this.type == "video" || this.type == "audio") {
            if (this.detailsData.video_link && this.detailsData.video_source && this.detailsData.video_source == "jw") {
              setTimeout(() => {
                this.isMateriaTop = false
                this.playerInstance = jwplayer("jwplayerdiv");
                this.playerInstance.setup({
                  playlist: [{
                    file: this.detailsData.video_link,
                    image: this.detailsData.image,
                  }]
                });
                if (this.detailsData.last_video_stop_point && this.detailsData.last_video_stop_point > 2 &&
                  this.detailsData.last_video_stop_point < this.detailsData.video_duration - 20) {
                  this.playerInstance.seek(this.detailsData.last_video_stop_point - 2)
                }

                let self = this
                this.playerInstance.on('pause', function (data) {
                  self.pauseData(self.playerInstance.getCurrentTime())
                })
              }, 500);
            }
            else if (this.detailsData.video_link && this.detailsData.video_source && this.detailsData.video_source == "vimeo") {
              setTimeout(() => {
                this.isMateriaTop = false
                var iframe = document.querySelector('iframe');
                this.playerVimeo = new Player(iframe);
                let self = this
                if (this.detailsData.last_video_stop_point && this.detailsData.last_video_stop_point > 2 &&
                  this.detailsData.last_video_stop_point < this.detailsData.video_duration - 20) {
                  self.playerVimeo.setCurrentTime(this.detailsData.last_video_stop_point - 2).then(function (seeking) {
                    console.log('title:', seeking);
                  }).catch(function (error) {
                  });
                }

                self.playerVimeo.on('pause', function (data) {
                  console.log(data)
                  if (data && data.seconds > 0) {
                    self.pauseData(data.seconds)
                  }
                });
              }, 500);
            }
            else {
              this.isMateriaTop = false
            }
          }
        } else {

        }
        if (this.type == "pdf" || this.type == "trending") this.isMateriaTop = false
        this.loadingMaterial = false;
        // this.isMateriaTop = true
      }, err => {
        this.isMateriaTop = false
        this.loadingMaterial = false;
        console.error('ERROR', err);
        return false;
      });
  }

  pauseData(pausedTime: any = null) {
    let data = {
      "video_stop_point": pausedTime
    }
    this.item.addPauseTime(data, this.id).subscribe(res => {
      if (res.success) {
        console.log("success")
      }
    })
  }

  onViewPdf() {
    const dialogRef = this.dialog.open(PdfViewerComponent, {
      panelClass: "full_modal",
      data:{
        data:this.detailsData.pdf
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  onOpenExternalLink(external_link) {
    window.open(external_link, "_blank");
  }
  onScroll(e) {
    if (this.length <= this.itemComments.length) {
      return;
    }
    this.page = this.page + 1
    this.loadCommentsByItemId()
  }

  loadCommentsByItemId() {
    this.loadingComments = true;
    this
      .item
      .getItemComments(this.id, this.commentspage, this.commentsperpage)
      .subscribe(res => {
        console.log(res);
        if (res.success == true) {
          this.length = res.data.total
          res.data.data.forEach(element => {
            if (!this.itemComments.find(x => x._id == element._id)) {
              this.itemComments.push(element);
            }
          });
        }
        this.loadingComments = false;
      }, err => {
        this.loadingComments = false;
        console.error('ERROR', err);
        // this.common.processError(err);
        return false;
      });
  }

  getProfile() {
    this.profile.getUserDetails()
      .subscribe(res => {
        console.log(res)
        if (res.success == true) {
          this.userDetails = res.data;
        }
      }, err => {
        // this.common.processError(err);
        return false;
      });
  }

  onSubmitComment() {
    this.loadingComments = true
    this.itemComments = []
    if (!this.user.hasLoggedIn) {
      // this.loginActivate.presentLogin(null)
      return;
    }
    if (this.commentForm.invalid) {
      console.log('invalid');
      return;
    }
    if (this.commentForm.valid) {
      console.log('valid');
      var _data = {
        material_id: this.id,
        comment: this.vcf.comment.value,
      };
      if (this.vcf.type.value == 'reply') {
        _data['parent_comment_id'] = this.vcf.comment_id.value;
      } else {
        _data['parent_comment_id'] = 0
      }
      this.loadingCommenting = true;
      this
        .item
        .sendComment(_data)
        .subscribe(res => {
          console.log(res);
          if (res.success == true) {
            if (this.vcf.type.value == 'reply') {
              this.isReplay = false
              this.commentForm.reset()
              this.itemComments.forEach(x => {
                if (x._id == this.vcf.comment_id.value) {
                  if (x.reply) {
                    this.resetCommentForm();
                    x.reply.push(res.data.comment_reply)
                  } else {
                    this.resetCommentForm();
                    console.log(x);
                    // x.reply.push(res.data.comment_reply);
                    x['reply'] = [];
                    x['reply'].unshift(res.data.comment_reply)
                    console.log(x);
                    
                  }
                }
              });
            } else {
              this.itemComments.unshift(res.data.comments);
              this.resetCommentForm();
            }
            this.itemComments = []
            this.loadCommentsByItemId()
          }
          this.loadingCommenting = false;
          this.loadingComments = false
        }, err => {
          this.loadingCommenting = false;
          this.loadingComments = false
          console.error('ERROR', err);
          this.common.processError(err);
          return false;
        });
    }
  }
  deleteComment(cmt) {
     this.loadingComments = true;
     this.itemComments = []
    this.item.deleteComment(cmt._id).subscribe(res => {
      if (res.success) {
        this.common.showToast(res.message)
        this.loadingComments = false
        this.loadCommentsByItemId()
      }
    },error=>{
      this.common.processError(error)
    })
  }

  async onClickDeleteComment(cmt) {

    this.deleteComment(cmt)
    
  }

  resetCommentForm() {
    this.vcf.comment.patchValue('');
    this.vcf.type.patchValue('main');
    this.vcf.comment_id.patchValue('');
    this.vcf.comment_person_name.patchValue('');
  }
  onClickReplyToComment(_comment) {
    this.isReplay  = true
    this.commentId = _comment._id
    this.vcf.type.patchValue('reply');
    this.vcf.comment_id.patchValue(_comment._id);
    this.vcf.comment_person_name.patchValue(_comment.commenter.customer_name);

    document.querySelector('#comment_form').scrollIntoView({
      behavior: 'smooth'
    });
  }

  dismiss() {
    // this.modalCtrl.dismiss()
  }

  ionViewWillLeave() {
    if (this.type == "video" || this.type == "audio") {
      let self = this
      if (this.detailsData.video_link && this.detailsData.video_source && this.detailsData.video_source == "jw") {
        this.playerInstance.on('pause', function (data) {
          self.pauseData(self.playerInstance.getCurrentTime())
        })
      }
      if (this.detailsData.video_link && this.detailsData.video_source && this.detailsData.video_source == "vimeo") {
        self.playerVimeo.pause().then(function (seeking) {
          self.playerVimeo.getCurrentTime().then(function (seconds) {
            self.pauseData(seconds)
          }).catch(function (error) {
          });
        }).catch(function (error) {
        });
      }
    }
  }


  loadItems(sub_id: any = null, week_id: any = null, day_id: any = null, type) {
    this.loadingVideos = true;
    let course_id = null
    if (this.common.selectedCourse && this.common.selectedCourse != null) course_id = this.common.selectedCourse._id
    console.log(this.common.selectedCourse)
    console.log(course_id)
    this
      .item
      .getItems(this.perpage = 20, this.page, course_id, sub_id, week_id, day_id, type)
      .subscribe(res => {
        if (res.success == true) {
          if (type == 'video') this.videoList = res.data.data
          if (type == 'pdf') this.pdfList = res.data.data
          console.log(this.id)
          console.log(this.pdfList)
        }
        this.loadingVideos = false;
      }, err => {
        this.loadingVideos = false;
        console.error('ERROR', err);
        this.common.processError(err);
        return false;
      });
  }

  onClickMaterialView(type, value) {

    var is_view :boolean=false
     console.log(value)
    if(!this.user.hasLoggedIn){
      if(this.common.played_video.video_count >= 3){
        console.log(this.common.played_video.ids.find(x=>x._id == value._id ) )
        if(this.common.played_video.ids.find(x=>x == value._id )){
          is_view=true
        }else{
          is_view=false
          this.loginActivate.onLogin()
        }
      }else{
        is_view=true
      }
    }else{
      is_view=true
    }

    if(is_view){
    this.common.type = type
    if ((!value.need_to_subscribe || value.need_to_subscribe) && value.type =='free') {
      this.common.type = type
      this.materialView(type, value)
    }else if(value.need_to_subscribe && value.type =='premium'){
      this.common.showToast("Please Subscribe to Continue")
     }else if(!value.need_to_subscribe && value.type =='premium'){
      this.common.type = type
      this.materialView(type, value)
     }
    // else {
    //   if (value.need_to_subscribe) {
    //     this.common.showToast("Please Subscribe to Continue")
    //     this.subscribe(value.course_id)
    //   } else {
    //     if(value.published_on  !=null){
    //       this.common.showToast("Content not Available Now, Please Check On " + this.datePipe.transform(value.published_on, 'dd-MM-yyyy'))
    //     }
    //     if(!value.published_on  ==null){
    //       this.common.showToast("Content not Available Now, Please Check On ")
    //     }
    //   }
    // }
    }
  }



  



  materialView(type, value) {
    let tempType = type
    const dialogRef = this.dialog.open(VideoDetailComponent, {
      panelClass: "full_modal",
      data: {
        id: value._id,
        sub_id: value.subject_id ? value.subject_id : null,
        week_id: value.week_id ? value.week_id : null,
        day_id: value.day_id ? value.day_id : null,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      
      // this.itemsList = []
      
      // this.loadItems(tempType)
    });
  }

  subscribe(course_id) {
    this.dialogRef.close()
    const dialogRef = this.dialog.open(PackageListComponent, {
      data: {
        flag: true,
        course_id: course_id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    });
  }

  clear(){
    this.commentId = null
    this.isReplay = false
    this.vcf.type.patchValue(null);
    this.vcf.comment_id.patchValue(null);
    this.vcf.comment_person_name.patchValue(null);
  }


  viewPdf(data){
  // if(data =='pre'){
  //   this.
  // }
  }


  videoNavigation(data){
    console.log(data)
  if(data == 'next'){
    this.id=this.detailsData.next;
  }
  if(data == 'previous'){
    this.id=this.detailsData.previous;
  }
  console.log(this.detailsData)
  console.log(this.id)
  this.loadItemById(this.id,this.navigation,this.navigation)

  }

  playVideo(){
    console.log("wwwwwwwwwwwwwwwwwww")
  }
}
