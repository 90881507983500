<app-side-nav></app-side-nav>

<section class="exam_lsit_section">
  <div class="container">
    <div class="row mb20">
      <div class="col-md-12 tc">
        <h1 class="border_heading_bottom">Submit Exam</h1>
      </div>
      <div class="col-md-6 col-md-offset-3">
        <div class="exam_list_selection tc">
          <div class="exam_list_heading mb5">
            <h3>{{examName}}</h3>
          </div>
          <!-- <div class="exam_list_duration">
            <span>Duration: <span class="primary">2 hrs</span> </span>
            <table class="mt0 width100">
              <tr>
                <td class="pr20 tc"><span>50 Min | 30 Que | 60 Marks</span></td>
              </tr>
            </table>
          </div> -->
          <div class="div_for_time_rem mt20">
            <div class="exam_timer_div">
              <div class="exam_timer_child animation_border blink_me margin0auto" style="float: none;">
                <img src="assets/img/timer.svg">
                <h3> {{ exam.leftTimeSeconds * 1000 | date:'HH:mm:ss':'UTC' }}</h3>
              </div>
            </div>
            <span class="block tc mt5">Time Remaining</span>
          </div>

          <div class="for_text_unaswer mt20">
            <span class="medium primary">You have {{ skipped_question_count }} unanswered questions out of {{exam.questions.length}}</span>
          </div>

          <div class="div_for_submit_exam mt20">
            <table class="mt20 width100">
              <tr>
                <td class="pr20 tc">
                  <button class="button_s3" (click)="goToPreview()">Review Exam</button>
                </td>
                <td>
                  <button class="button_s1" (click)="submitExam()">Submit Exam</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-subscription-box></app-subscription-box>
<app-footer></app-footer>