import { Component, NgZone } from '@angular/core';
import { MessagingService } from './providers/messaging.service';
import { UserService } from './providers/user.service';
import { CommonService } from './providers/common.service';
import { ExamService } from './providers/exam.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { EventPublishService } from './providers/event-publish';
import { SigninComponent } from './pages/signin/signin.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'uex-web';
  message:any;
  _setInterval:any
  hasCounter:boolean=false;
  examName:any;

  constructor(public user:UserService,
    public messagingService:MessagingService,public common:CommonService,public exam:ExamService,
    public router: Router,    public route: ActivatedRoute,public events:EventPublishService,public zone:NgZone,
    public event:EventPublishService,public dialog:MatDialog){

      this.events.getObservable().subscribe((data) => {
        if (data == 'uexUnauthorized') {
          this.zone.run(async () => {
            localStorage.clear()
            this.user.hasLoggedIn = false
            this.user.logout()
            const dialogRef = this.dialog.open(SigninComponent, {
              panelClass: 'sign_in_box',
          });
          dialogRef.afterClosed().subscribe(result => {
           
                this.router.navigate(['/home'])
              
              // this.loadCategories();
          });
          })
        }
      })
      this.common.show_marquee=true;
  }

  ngOnInit() {
    // this.common.show_marquee=true;
    this.user.loadUser()
    this.activeMarquee();
    this.LoadData()
    const userId = 'user001';
    this.messagingService.requestPermission(userId)
      this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
    console.log(this.router.url)
  }


  activeMarquee(){
    setInterval(() => {
      var twentyMinutesLater = new Date();
      console.log("kkkkkkkkkkkkkkkkkkkk")
      this.common.show_marquee=true;
      let self=this
      setTimeout(function(){
        console.log('okk..................asdf')       
        self.common.show_marquee=false;
      },10000)
     

      }, 900000);
  }

  hideMarquee(){
this.common.show_marquee=false;
  }


  LoadData(){
    if(localStorage.getItem(this.common.VIDEO_COUNT)){
      this.common.played_video=JSON.parse(localStorage.getItem(this.common.VIDEO_COUNT))
      console.log(this.common.played_video)
    }
  }

}
