// import {LoginPage} from './../pages/login/login';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EventPublishService } from './event-publish';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './user.service';
import { LoginActivate } from './loginActivate';

@Injectable({ providedIn: 'root' })
export class CommonService {
    baseUrl = 'http://beta.api.anjos.shopping/orders';

    USER_LOGIN = "uexuser:login";
    MENTOR_LOGIN = "uexMentor:login";
    USER_LOGOUT = 'uexuser:logout';
    MENTOR_LOGOUT = 'uexMentor:logout';
    USER_PROFILE_UPDATED = 'uexuser:profileUpdated';
    TAB_CHANGES = 'uexUser:tabChanges';
    COURSE_CHANGES = 'uexUser:courseChanges';

    EXAM_TIME='uexExamTime'
    START_TIME='uexStartTime'
    EXAM_ID='uexExamId'
    EXAM_QUESTIONS='uexExamQuestion'
    EXAM_NAME='uexExamName'
    IS_EXAM_EXIST='uexExamExist'

    QUESTION_FLAG = 'uexQuestionFlag'
    LOGIN_MOBILE_NO = 'uexLoginMobileNo'
    CATEGORY_CHANGED = 'uexCategoryChanged';
    MATERIAL_CATEGORY_CHANGED = 'uexMaetrialCategoryChanged';
    VIDEO_CATEGORY_CHANGED = 'uexVideoCategoryChanged';
    TRENDING_CATEGORY_CHANGED = 'uexTrendingCategoryChanged';
    EXAM_CATEGORY_CHANGED = 'uexExamCategoryChanged';
    SELECTED_COURSE = 'uexCourse';
    EXAM_START = 'uexExamStart';
    UNAUTHORIZED = 'uexUnauthorized';
    TAB_CLICK = 'uexTabClick';
    COURSE = 'uexCourse';
    LOGIN_TYPE = 'uexLoginType';
    TIME_LINE = 'timelines';
    IS_FIRST_TIME='uexisfirsttime';

    FCM_TOKEN='uexFcmToken'

    REFERRAL_CODE='uexreferralcode'
    type:any=null
    selectedPackage=null
    sideActive:any=null
    VIDEO_COUNT='Video_count'
    played_video:any={}
    selectedCourse:any=[]
    currentIndex:number = 0
    currentBatchIndex:any=0
    currentTicketIndex:any=0
    isTabActive:boolean=false
    IS_TAB_METHOD = 'aceIsTabMethod'
    currentCourseIndex=0
    isFetching:boolean=false
    loginType:any='customer'
    tab_type:any
    selectedGroup:any
    show_marquee:boolean=false;
    constructor(public toastr:ToastrService,public events:EventPublishService,
        public router:Router) { }
        processError(err) {
            console.log(err)
            if (!navigator.onLine) {
              this.showToastError('No Internet Connection. Please turn ON your data or wifi');
              return;
            }
            // if (this.isLoginError(err)) {
            //   this.showToastError('Invalid Login. Please login again');
            //   // let modal = this.modalCtrl.create('LoginPage'); modal.present();
        
            // } 
            
            
            else {
                if(localStorage.getItem('aceHasLoggedIn')){
              if (err.status == 401) {
                  console.log(err)
                this.showToastSuccess("User Expired. Please Login Again");
                this.events.publishSomeData(this.UNAUTHORIZED)
                return;
              }
            }
          
              let error = 'Something Went wrong';
              try {
                let body = err.json();
                if (body.message) {
                  error = String(body.message).replace(/<[^>]+>/gm, '')
                }
              } catch (e) {
        
              }
              // this.presentToast(error);
        
            }
            
          }
    
    
    
        // TOAST
        showToast(message?: string, title?: string) {
            this.toastr.show(message, title, { enableHtml: true })
        }
        showToastSuccess(message?: string, title?: string) {
            this.toastr.success(message, title, { enableHtml: true });
        }
        showToastError(message?: string, title?: string) {
            this.toastr.error(message, title, { enableHtml: true })
        }


    async showIonicLoader(msg) {
        if (!msg) {
            msg = 'Loading..';
        }
        // const loading = await this.loadingCtrl.create({
        //     message: msg,
        //     // duration: 2000
        // });
        // await loading.present();
    }
    // hideIonicLoader() {
    //     this
    //         .loader
    //         .dismiss();
    // }


    
}
