<section class="auth_section">
  <div class="container-model">
    <div class="row">
      <div class="col-md-12">
        <button type="button" aria-label="Close" class="close_model_div" (click)="close()">
          <img src="assets/img/close.svg" class="close_model">
        </button>
        <div class="auth_left">
          <div class="auth_left_child">
            <div class="auth_heading">
              <h3>Yaay...</h3>
              <h1 class="bold h1">Successfully <span class="primary h1">Subscribed!</span></h1>
            </div>
            <div class="auth_form">
              <span class="f16">Course name : {{courseData?.title}} </span><br>
              <span class="f16">Start Date : {{courseData?.created_at |date:'mediumDate'}} </span><br>
              <span class="f16">Expiry Date : {{SubscriptionData?.expiry_date |date:'mediumDate'}} </span><br>
            </div>
            <div class="tc mt20">
              <button (click)="close()" class="button_s1">Done</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
