<section class="subscription_box  mt50"  *ngIf="!is_Subscribed">
  <div class="container" style="background-image: url(assets/img/bg_sub_box.png);">
    <div class="row">
      <div class="col-md-12">
        <div class="sub_box_bg  radius10">
          <div class="row">
            <div class="col-md-12">
              <h3 class="m0">Subscription Details</h3>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-7 col-xs-12">
                  <p class="mb0">
                    Make the most of our competitive courses, to gain in-depth access to the righteous accounting practise that you have been seeking.  	
                  </p>
                </div>
                <div class="col-md-5 col-xs-12 tr btn_sub_now">
                  <button class="button_s3"  routerLink="/select-course">Subscribe Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>