<app-side-nav></app-side-nav>

<ng-container *ngIf="course==null || !course.is_subscribed">
    <section class="pt50 whyux">
        <div class="container">
            <div class="row">
                <div class="col-md-12 tc mt20">
                    <h1 class="border_heading_bottom mt0 pt0">{{courseDetails.title}}</h1>
                </div>
                <div class="col-md-offset-1 col-md-10 col-xs-12 tc">
                    <p class="p0 m0 trim_data" #content [innerHTML]="courseDetails.description"></p>
                    <span class="read_more" (click)="shoMore()" style="display: none;">Read More <img
              src="assets/img/rel_play.svg"> </span>
                </div>
            </div>
            <div class="row m_mt0">
                <div class="col-md-12">
                    <div class="block tc">
                        <img [src]="courseDetails.image_url" onError="this.src='assets/img/video_thumb_2.svg'" class="course_detail_image">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="course_over_view pt50">

        <div class="container" *ngIf="loadingVideos">
            <div class='row'>
                <div class="col-md-4" *ngFor="let itm of [1,2,3,4,5,6,7,8,9]">
                    <ngx-skeleton-loader [theme]="{ height: '200px'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-md-12 tc">
                    <h1 class="border_heading_bottom">Courses Overview</h1>
                </div>
                <div class="col-md-offset-2 col-md-8 col-xs-12 tc">
                    <!-- <p class="p0 m0">But I must explain to you how all this mistaken idea of denouncing
            pleasure and praising pain was born and I.</p> -->
                </div>
            </div>
            <div class="row mt50">
                <div class="col-md-3 col-xs-6 mdmb20">
                    <div class="cov_img tc">
                        <img src="assets/img/video_class.svg" class="height200p">
                        <h1 class="mb0">{{courseDetails.video_count}}</h1>
                        <span class="medium block mt20 mb20">Video Classes</span>
                    </div>
                </div>
                <div class="col-md-3 col-xs-6 mdmb20">
                    <div class="cov_img tc">
                        <img src="assets/img/materials.svg" class="height200p">
                        <h1 class="mb0">{{courseDetails.pdf_count}}</h1>
                        <span class="medium block mt20 mb20">Materials</span>
                    </div>
                </div>

                <div class="col-md-3 col-xs-6 mdmb20">
                    <div class="cov_img tc">
                        <img src="assets/img/mocktest.svg" class="height200p">
                        <h1 class="mb0">{{courseDetails.exam_count}}</h1>
                        <span class="medium block mt20 mb20">Mock Test</span>
                    </div>
                </div>

                <div class="col-md-3 col-xs-6 mdmb20">
                    <div class="cov_img tc">
                        <img src="assets/img/fee.svg" class="height200p">
                        <h1 class="mb0">{{packageList[0]?.price}}</h1>
                        <span class="medium block mt20 mb20">Course Fee</span>
                    </div>
                </div>

            </div>
        </div>
    </section>

    <section class="course_over_view pt50">
        <div class="container" *ngIf="itemsList.length > 0">
            <div class="row mb20">
                <div class="col-md-12">
                    <div class="crs_tab_block tc mt20 mb20">
                        <button class="btn button_s4" (click)="onClickItem('video')" [ngClass]="{'active':type=='video'}">Video</button>
                        <button class="btn button_s4" (click)="onClickItem('pdf')" [ngClass]="{'active':type=='pdf'}">Materials</button>
                        <button class="btn button_s4" (click)="onClickItem('mock')" [ngClass]="{'active':type=='mock'}">Mock
              Tests</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" *ngIf="type=='video'">
                    <div class="row">
                        <div class="col-md-4 mb20" *ngFor="let item of itemsList" (click)='onClickMaterialView("video",item)'>
                            <div class="course_select_box p0">
                                <div class="csb_inner_div">
                                    <span class="badge_1 video_time">15:26</span>
                                    <img [src]="item.image" onError="this.src='assets/img/video_thumb.svg'">
                                </div>
                                <div class="csb_inner_text">
                                    <div class="row">
                                        <div class="col-md-8 tl col-xs-8">
                                            <span class="medium block item_ttle">{{item?.title}}</span>
                                            <span class="block f12 item_tt_subject">{{item?.subject?.title}}</span>
                                        </div>
                                        <div class="col-md-4 tr col-xs-4">
                                            <span class="badge_2">{{item?.type}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="type == 'pdf'" (click)='onClickMaterialView("pdf",item)'>
                    <div class="related_video_detail">
                        <div class="rel_video_boxes">
                            <div class="col-md-4 mb30" *ngFor="let item of itemsList" (click)='onClickMaterialView("pdf",item)'>
                                <div class="rel_mat_box_main">
                                    <div class="rel_mat_box_child">
                                        <div class="rel_mat_box_text_box">
                                            <h3 class="medium block mt0 mb10">{{item.title}} </h3>
                                            <p class="m0 f12 mb20" [innerHTML]="item.description"></p>
                                        </div>
                                        <div class="rel_mat_box_actions">
                                            <div class="rel_mat_file_icon cell tl">
                                                <img src="assets/img/pdf.svg">
                                            </div>
                                            <div class="rel_mat_date_inner cell tl" *ngIf='(!item.completed_on || item.completed_on==null) && item.published_on && item.published_on != null && !item.need_to_subscribe'>
                                                <span class="f12">{{item.published_on | date:'dd MMM yyyy, hh:mm a'}} Onwards</span>
                                            </div>
                                            <div class="rel_mat_date_inner cell tl" *ngIf='item.completed_on && item.completed_on != null'>
                                                <span class="f12">{{item.completed_on | date:'dd MMM yyyy'}}</span>
                                            </div>
                                            <div class="rel_mat_button_inner cell tr">
                                                <button class="badge_2">{{item.type}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="type == 'mock'">
                    <div class="col-md-4 mb30 cauto" [ngStyle]="{'height': cal_height}" *ngFor="let exam of itemsList ">
                        <div class="exam_list_selection ">
                            <div class="exam_list_heading mb5">
                                <!-- <ng-container *ngIf="exam.is_completed == true">
                  <button class="selected_crs_button_2 mb10"> <img src="assets/img/circle_check.svg"> <span>Completed
                      Exam</span>
                  </button>
                </ng-container> -->
                                <h3 class="medium mt0 mb0">{{exam.name}} <img src="assets/img/circle_check.svg" width="20"></h3>
                            </div>
                            <div class="exam_list_duration">
                                <span>Duration: <span class="primary">{{exam.duration}} Min</span> </span>
                                <table class="mt20">
                                    <tr>
                                        <td class="pr20 tl"><span class="medium">{{exam.no_of_questions}} Que | {{exam.total_mark}}
                        Marks</span>
                                        </td>
                                        <td class="tr">
                                            <button class="button_s6" *ngIf='exam.completed_on && exam.completed_on != null' (click)='onClickView(exam)'>View Result</button>

                                        </td>
                                        <td class="tr">
                                            <button class="button_s6" *ngIf='!exam.completed_on || exam.completed_on == null' (click)='onClickView(exam)'>Start & View Exam</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="package_list_tab pt50" *ngIf="packageList && packageList.length > 0 && !courseDetails.is_subscribed ">
        <div class="container">
            <div class="row mb20">
                <div class="col-md-12 tc">
                    <h1 class="border_heading_bottom">Packages</h1>
                </div>
                <div class="col-md-offset-3 col-md-6 col-xs-12 tc">
                    <p class="p0 m0">Make the most of our competitive courses, to gain in-depth access to the righteous accounting practise that you have been seeking.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 pt30">
                    <owl-carousel-o [options]="package">
                        <ng-template carouselSlide *ngFor="let pack of packageList">
                            <div class="uex_package_div">
                                <div class="item_for_package_caro">
                                    <div class="item_package_text_box">
                                        <h2 class="pt0 mt0">{{ pack.name }}</h2>
                                    </div>
                                    <div class="i_package_price">
                                        <span class="medium strike_price">
                      <del *ngIf="pack.strike_price > pack.price">₹
                        {{pack.strike_price}}
                      </del>
                    </span>
                                        <h2>₹ {{ pack.price | number:'.2-2' }} <span style="display: block;
                      text-decoration: none;
                      color: black;">(Including tax)</span> </h2>

                                    </div>
                                    <div class="ip_valid">
                                        <span class="medium">Validity: {{pack.validity}} Days</span>
                                    </div>
                                    <div class="package_ul">
                                        <ul>
                                            <li *ngFor='let ben of pack.benefites'><img src="assets/img/circle_check.svg">{{ ben }} </li>
                                        </ul>
                                    </div>
                                    <div class="package_action">
                                        <button class="button_s1" (click)='buyNow(pack)'>Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </section>

    <div id="open-modal" class="modal-window show" *ngIf="buyPackage">
        <div>
            <a title="Close" class="modal-close pointer" (click)="buyPackage=!buyPackage">
                <img src="assets/img/close.svg">
            </a>
            <h2>{{ common.selectedPackage.name }}</h2>
            <div class="md_i_package_price">
                <span class="medium" *ngIf="common.selectedPackage.strike_price > 
          common.selectedPackage.price">₹ {{common.selectedPackage.strike_price | number:'.2-2' }}</span>
                <h2>₹ {{ common.selectedPackage.price | number:'.2-2' }}</h2>
            </div>
            <!-- <div><small>Get an Amazing Offer</small></div> -->
            <!-- <div class="promo_div">
        <form [formGroup]="promoCodeForm">
          <input class="promo_input" formControlName='coupon_code' placeholder="Enter Promo Code">
          <button class="white" *ngIf='!isPromo' (click)='submitPromoCode()'>Submit</button>
          <div *ngIf="isSubmit && epf.coupon_code.errors" class="form-error-msg">
            <div class="form-error-msg" *ngIf="epf.coupon_code.errors.required">Code is required</div>
          </div>
          <div class="form-error-msg" *ngIf="isError">Invalid Coupon Code</div>
          <button *ngIf='isPromo' (click)="couponCodeCancelled()">
            x
          </button>
        </form>
      </div> -->
            <div *ngIf='isPromo' class="p-0 mt-20">
                <div *ngIf='appliedPromoCode.coupon_code'>
                    <span>
            Applied PromoCode
          </span>
                    <span>
            {{appliedPromoCode.coupon_code}}
          </span>
                </div>
                <div *ngIf='appliedPromoCode.coupon_code'>
                    <span>
            Applied Amount
          </span>
                    <span>
            {{appliedAmount(appliedPromoCode)}}
          </span>
                </div>
                <div *ngIf='appliedPromoCode.coupon_value'>
                    <span>
            Payable Price
          </span>
                    <span>
            {{appliedValue(appliedPromoCode) }}
          </span>
                </div>
            </div>
            <div class="submit_promo_purchase mt20 tc" [disabled]="isProcessing">
                <button class="button_s1" (click)="proceed()">Purchase
          <svg *ngIf="isLoading" class="spinner" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
          </svg>
        </button>
            </div>
        </div>
    </div>



</ng-container>

<ng-container *ngIf="course && course.is_subscribed">
    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="detail_page_date_section tl mb20" *ngIf="courseDetails.course_start_date">
                        <span>Start Date : {{courseDetails.course_start_date | date:'dd MMM yyyy hh : mm a'}}</span>
                    </div>
                    <div class="div_left_video_box">
                        <div class="canvas_video_div">
                            <div class="div_video_canvas">
                                <!-- mat box -->
                                <div class="mat_big_box">
                                    <div class="matbox_inner_child">
                                        <div class="mat_big_box_right_text tl cell">
                                            <!-- <h3 class="mt0 mb10">{{courseDetails.title}}</h3> -->
                                            <p class="p0 m0 trim_data" #content [innerHTML]="courseDetails.description"></p>
                                            <span class="read_more" (click)="shoMore()" style="display: none;">Read More <img
                          src="assets/img/rel_play.svg"> </span>

                                            <!-- <button class="button_s6">View</button> -->
                                            <button class="button_s6" (click)='subscribe()' *ngIf='!courseDetails.is_subscribed'>
                        Subscribe</button>
                                        </div>
                                    </div>
                                </div>
                                <!-- mat box -->
                            </div>
                        </div>


                        <div class="mat_text_heading mt50" *ngIf="courseDetails.timelines">
                            <h2 class="mb20 mt0">Timeline</h2>
                        </div>
                        <ng-container *ngFor='let week of courseDetails.timelines'>
                            <div class="mat_text_heading mt20" *ngIf="week.week">
                                <div class="wee_div_parent">
                                    <div class="week_time_line pointer" (click)="weekDays(week._id)">
                                        <h3 class="mb0 mt0">Week - {{week.week}} {{week.title}}
                                            <img src="assets/img/rel_play.svg" [ngClass]="{'rt45': rotate==week._id}" class="week_collapse_img">
                                        </h3>
                                        <p class="mt5 mb0" [innerHTML]="week.description">
                                    </div>
                                    <div class="week_collapse" *ngIf="rotate==week._id && rotate !=null" [@inOutAnimation]>
                                        <div class="related_video_detail mt20" *ngIf="week.days && week.days.length > 0">
                                            <!-- looooooooooooop -->
                                            <div class="rel_video_boxes">
                                                <div class="row">
                                                    <div class="col-md-12" *ngFor="let day of week.days" (click)='onClickWeekCourseDetails(day,week)'>
                                                        <div class="week_view_div mb20">
                                                            <div class="week_div_q">
                                                                <h4 class="mt0 mb5">Day - {{day.days}} {{day.title}}</h4>
                                                                <span class="block medium" *ngIf='day.day_date'>{{day.day_date | date:'dd MMM
                                  yyyy'}}</span>
                                                                <p class="mt5 mb0" [innerHTML]="day.description">
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="div_right_video_box">
                        <div class="rel_video_right">
                            <div class="rel_video_title">
                                <h2 class="mb20 mt0">Modules</h2>
                            </div>
                            <div class="rel_video_boxes">

                                <div class="mat_big_box mb20 pointer" *ngFor="let subject of courseSubjects">
                                    <div class="matbox_inner_child" (click)="openVideoList(subject._id)">
                                        <div class="mat_big_box_right_text tl cell">
                                            <h3 class="mt0 mb0">{{subject.title}}</h3>
                                            <!-- <table class="mt5">
                        <tr>
                          <td class="pr20 tl">
                            <span class="medium">Videos {{courseDetails?.video_count}} | Materials {{courseDetails?.pdf_count}} | Mocktest {{courseDetails?.exam_count}}</span>
                          </td>
                        </tr>
                      </table> -->
                                            <!-- <button class="button_s6">View</button> -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>




</ng-container>
<!-- <app-subscription-box></app-subscription-box> -->
<app-footer></app-footer>