<app-header></app-header>
<section class="banner_box">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 p0">
                <owl-carousel-o [options]="banner">
                    <!-- <ng-template carouselSlide>
            <div class="bnr_grid">
              <div class="bnr_iimg">
                <img src="assets/img/home_banner2.png">
              </div>
            </div>
          </ng-template> -->
                    <ng-template carouselSlide>
                        <div class="bnr_grid">
                            <div class="bnr_iimg">
                                <img src="assets/img/mobile.png" class="hide_web">
                                <img src="assets/img/banner.png" class="hide_mob">
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="bnr_grid">
                            <div class="bnr_iimg">
                                <img src="assets/img/mobile2.png" class="hide_web">
                                <img src="assets/img/banner2.png" class="hide_mob">
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="bnr_grid">
                            <div class="bnr_iimg">
                                <img src="assets/img/mobile3.png" class="hide_web">
                                <img src="assets/img/banner3.png" class="hide_mob">
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
                <!-- <div class="banner_text_div tc">
          <div class="banner_text_white">
            <h1>Crack Your Learning with the best
              Platform </h1>
            <p>But I must explain to you how all this mistaken idea of denouncing
              pleasure and praising pain was born and I.</p>
          </div>
        </div> -->
            </div>
        </div>
    </div>
</section>

<section class="home_course_tab mt50">
    <div class="container">
        <div class="row mb20">
            <div class="col-md-12 tc">
                <h1 class="border_heading_bottom">OCOM - Universal Accounting Solutions</h1>
            </div>
            <div class="col-md-offset-2 col-md-8 col-xs-12 tc">
                <p class="p0 m0">OWN COMMERCIAL MASTERY (OCOM) is affiliated with BSS (Bharat Sevak Samaj) a unique accounting training institute created under a team of gulf and India experienced accounts experts.  It is designed to impart practical training to the students on live projects using methods and procedures actually followed in accounting both in India and abroad under the guidance of the experts.  This will help them enhance their knowledge in the accounting field and will ensure their job with good salary in big corporate companies as accountant, chief accountant and finance manager. As competition is increasing day by day, only the best trained and skilled can get best opportunities in reputed companies.  We equip our students for such a task.
                </p>
            </div>
        </div>


        <div class='row' *ngIf="loadingCourseGroup">
            <div class="col-md-12">
                <div class="crs_tab_block tc mt20 mb20">
                    <ngx-skeleton-loader count="8" [theme]="{  height: '50px',  width: '120px', margin: '10px'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="!loadingCourseGroup">
            <!-- <div class="col-md-12">
        <div class="crs_tab_block tc mt20 mb20">
          <button class="btn button_s1" (click)="onClickView(group._id)" [ngClass]="{'active':selectedGroup==group._id}"
            *ngFor="let group of courseGroupList">{{group.title}}</button>
        </div>
      </div> -->

            <div *ngIf='!loadingCourse && (!courseList || courseList.length < 1)'>
                <div class="col-md-12">
                    <div class="text-center">
                        <!-- <div><img src="assets/images/nodata.svg" width="80"></div> -->
                        <span>No Course Found!</span>
                    </div>
                </div>
            </div>

            <div class='col-md-12 pt20' *ngIf='loadingCourse'>
                <div class="csr_owl_carowsel">
                    <owl-carousel-o [options]="course">
                        <ng-template carouselSlide *ngFor="let itm of [1,2,3,4]">
                            <div class="item_car">
                                <ngx-skeleton-loader [theme]="{ height: '220px',margin: '10px'}"></ngx-skeleton-loader>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>





            <div class="col-md-12 pt20" *ngIf='!loadingCourse'>
                <div class="csr_owl_carowsel course_select_box">
                    <owl-carousel-o [options]="course">
                        <ng-template carouselSlide *ngFor="let course of courseList">
                            <div class="item_car" (click)='onSelectCourse(course)'>
                                <div class="crs_item_img">
                                    <img [src]="course.image_url" onError="this.src='assets/img/home_slider1.png'">
                                </div>
                                <ng-container *ngIf="course.is_subscribed == true">
                                    <button class="selected_crs_button"> <img src="assets/img/circle_check.svg"> <span>Subscribed</span>
                  </button>
                                </ng-container>
                                <div class="crs_item_text">
                                    <span class="medium">{{course.title}}</span>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>


        </div>
    </div>
</section>

<section class="pt50">
    <div class="container">
        <div class="row">
            <div class="col-md-10 col-md-offset-1 col-lg-offset-1">
                <div class="row mb20">
                    <div class="col-md-12 tc">
                        <h1 class="border_heading_bottom">How it Works</h1>
                    </div>
                    <div class="col-md-offset-2 col-md-8 col-xs-12 tc">
                        <p class="p0 m0">At OCOM we have designed courses for you assuring ease of access, in a hassle-free and user-friendly manner. Our main aim is to empower your knowledge and skills in Accounts, by achieving and experiencing both literal and practical
                            aspects of accounting to ultimately benefit you in the long run. </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="csr_owl_carowsel">
                            <owl-carousel-o [options]="howitwork">
                                <ng-template carouselSlide>
                                    <div class="item_car">
                                        <div class="crs_item_img">
                                            <!-- <iframe class="h_it_w_frame" src="https://www.youtube.com/watch?v=U0rsxvGVPIQ"></iframe> -->
                                            <iframe class="h_it_w_frame" src="https://www.youtube.com/embed/U0rsxvGVPIQ"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </ng-template>
                                <!-- <ng-template carouselSlide>
                  <div class="item_car">
                    <div class="crs_item_img">
                      <img src="assets/img/slider2.png">
                    </div>
                  </div>
                </ng-template> -->
                            </owl-carousel-o>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="howit_work_right">
                            <div class="mt50">
                                <p>Simply subscribe by entering your contact details (email ID/mobile number) in the space provided in order to participate in our available short-term or long-term courses, as per your preference. You can then gain access
                                    to our online video classes, pdf. materials, practice modules, online test papers and assessment sheets. Once your chosen course is completed you will be provided with a Certificate of Completion. Apart from this, completing
                                    an additional test paper guarantees you an Experience Certificate which would be an added asset when you go seeking the right placement. </p>
                            </div>
                            <div class="mt20">
                                <button class="btn button_s1" *ngIf="!user.hasLoggedIn" (click)="onLogin()">Signin</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="home_study_material mt50" *ngIf="selected_course !=null">
    <div class="container">
        <div class="row mb20">
            <div class="col-md-12 tc">
                <h1 class="border_heading_bottom"> Study Materials </h1>
            </div>
            <div class="col-md-offset-2 col-md-8 col-xs-12 tc">
                <p class="p0 m0">Students can avail all relevant course-related study materials by clicking on the icons below, for each respective course. Study materials are in document and video format for ease of access, where the former included bills, invoices,
                    vouchers, etc. Students can also gain access to Mock-test-sample question papers that are available aplenty.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="crs_tab_block tc mt20 mb20">
                    <button class="btn button_s1" [ngClass]="{'active':type=='video'}" (click)="loadItems('video')">Video</button>
                    <button class="btn button_s1" [ngClass]="{'active':type=='pdf'}" (click)="loadItems('pdf')">Materials</button>
                    <button class="btn button_s1" [ngClass]="{'active':type=='mock'}" (click)="loadItems('mock')">Mock Tests</button>
                </div>
            </div>

            <div *ngIf='!loadingItems && (!itemsList || itemsList.length < 1)'>
                <div class="col-md-12">
                    <div class="text-center">
                        <!-- <div><img src="assets/images/nodata.svg" width="80"></div> -->
                        <span>No <span class="upper">{{type}}</span> Found!</span>
                    </div>
                </div>
            </div>

            <div class="col-md-12 pt30" *ngIf='loadingItems'>
                <div class="csr_owl_carowsel study_material_owl">
                    <owl-carousel-o [options]="course">
                        <ng-template carouselSlide *ngFor="let itm of [1,2,3,4]">
                            <ngx-skeleton-loader [theme]="{ height: '200px'}"></ngx-skeleton-loader>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>


            <div class="col-md-12 pt30" *ngIf="type == 'video'">
                <div class="csr_owl_carowsel study_material_owl">
                    <owl-carousel-o [options]="course">
                        <ng-template carouselSlide *ngFor="let item of itemsList">
                            <div class="course_select_box p0" (click)='onClickMaterialView("video",item)'>
                                <div class="csb_inner_div">
                                    <span class="badge_1 video_time">{{item.video_duration * 1000 * 60| date:'HH:mm':'UTC'}}</span>
                                    <img [src]="item.image" onError="this.src='assets/img/video_thumb.svg'">
                                </div>
                                <div class="csb_inner_text">
                                    <div class="row">
                                        <div class="col-md-8 tl col-xs-8">
                                            <span class="medium block item_ttle">{{item?.title}}</span>
                                            <span class="block f12 item_tt_subject">{{item?.subject?.title}}</span>
                                        </div>
                                        <div class="col-md-4 tr col-xs-4">
                                            <span class="badge_2">{{item?.type}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
                <div class="view_all_section tc" *ngIf="itemsList.length > 0">
                    <span class="medium block mt20 mb20 pointer" [routerLink]="['/video-list','video']">View All</span>
                </div>
            </div>

            <div class="col-md-12 mt20" *ngIf="type == 'pdf'">
                <div class="related_video_detail mt20">
                    <!-- looooooooooooop -->
                    <div class="rel_video_boxes">
                        <div class="row">
                            <div class="col-md-4 col-xs-12" *ngFor="let item of itemsList" (click)='onClickMaterialView("pdf",item)'>
                                <div class="rel_mat_box_main">
                                    <div class="rel_mat_box_child">
                                        <div class="rel_mat_box_text_box">
                                            <h3 class="medium block mt0 mb10">{{item.title}} </h3>
                                            <p class="m0 f12 mb20" [innerHTML]="item.description"></p>
                                        </div>
                                        <div class="rel_mat_box_actions">
                                            <div class="rel_mat_file_icon cell tl">
                                                <img src="assets/img/pdf.svg">
                                            </div>
                                            <div class="rel_mat_date_inner cell tl">
                                                <span class="f12">{{item.created_at | date:'dd MMM yyyy'}}</span>
                                            </div>
                                            <div class="rel_mat_button_inner cell tr">
                                                <button class="button_s6">{{item.type}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="view_all_section tc" *ngIf="itemsList.length > 0">
                        <span class="medium block mt20 mb20 pointer" [routerLink]="['/video-list','pdf']">View All</span>
                    </div>
                </div>
            </div>


            <div class="col-md-12 mt20" *ngIf="type == 'mock'">
                <div class="row">
                    <div class="col-md-4 mb30 cauto" [ngStyle]="{'height': cal_height}" *ngFor="let exam of itemsList ">
                        <div class="exam_list_selection ">
                            <div class="exam_list_heading mb5">
                                <h3 class="medium mt0 mb0">{{exam.name}}</h3>
                            </div>
                            <div class="exam_list_duration">
                                <span>Duration: <span class="primary">{{exam.duration}} Min</span> <span *ngIf="exam.is_completed == true">| Completed on <span class="primary">20/20/20</span> </span>
                                </span>
                                <table class="mt20">
                                    <tr>
                                        <td class="pr20 tl"><span class="medium">{{exam.no_of_questions}} Que | {{exam.total_mark}}
                        Marks</span>
                                        </td>
                                        <td class="tr">
                                            <button class="badge_2" *ngIf='exam.completed_on && exam.completed_on != null' (click)='onClickViewExam(exam)'>View Result</button>

                                        </td>
                                        <td class="tr">
                                            <button class="badge_2" *ngIf='!exam.completed_on || exam.completed_on == null' (click)='onClickViewExam(exam)'>Start & View Exam</button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="view_all_section tc" *ngIf="itemsList.length > 0">
                        <span class="medium block mt20 mb20 pointer" [routerLink]="['/video-list','mock']">View All</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="pt50">
    <div class="container">
        <div class="row">
            <div class="col-md-12 tc">
                <h1 class="border_heading_bottom">Key Features</h1>
            </div>
            <div class="col-md-offset-3 col-md-6 col-xs-12 tc">
                <p class="p0 m0">Once students step into the OCOM virtual world, we ensure to enrich them with the apt knowledge, skills and professional experience required to showcase their talents and expertise in the field of professional accounts. </p>
            </div>
        </div>
        <div class="row mt50">
            <div class="col-md-12 mb30">
                <owl-carousel-o [options]="keyfee">
                    <ng-template carouselSlide>
                        <div class="key_features tc">
                            <img src="assets/img/keyfeature3.svg" class="height200p">
                            <span class="medium block mt20 mb20">Training</span>
                            <p class="p0 m0">Extensive trainings is conducted throughout the duration of the course granting an in-depth insight into professional accounting. Students are taught to enhance their analytical skills through efficient and accurate tallying
                                and are provided with sufficient materials to emerge as the best among. </p>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="key_features tc">
                            <img src="assets/img/keyfeature2.svg" class="height200p">
                            <span class="medium block mt20 mb20">Job Opportunities</span>
                            <p class="p0 m0">Attend interviews and face questions from employers with a renewed sense of confidence. Your enhanced expertise garnered from OCOM will land you the right jobs best matching your profiles.</p>
                        </div>
                    </ng-template>

                    <ng-template carouselSlide>
                        <div class="key_features tc">
                            <img src="assets/img/job.svg" class="height200p">
                            <span class="medium block mt20 mb20">Placement</span>
                            <p class="p0 m0">
                                Trust us to grant you the right training, time-management skills, apt working-hours and the experience as well as expertise required to find the right placement in your dream company.
                            </p>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

        </div>
    </div>
</section>



<section class="pt50 whyux">
    <div class="container">
        <div class="row">
            <div class="col-md-12 tc">
                <h1 class="border_heading_bottom">Why OCOM ?</h1>
            </div>
            <div class="col-md-offset-2 col-md-8 col-xs-12 tc">
                <p class="p0 m0">Looking to carve your niche in the accounting world by being enlisted among the best in the field in terms of capability, ability and skills? Then look no further for OCOM has the right solution for you. </p>
            </div>
        </div>
        <div class="row m_mt0 mt50" id="WhyUxMain">
            <div class="col-md-6 col-xs-12" id="WhyUxFullText">
                <div class="mt50">
                    <p class="block width80">OCOM specializes in giving students a realistic approach in Accounting, whereby they are given ample experience and training as if they were to work in a professional environment. By the time they complete the course they would have
                        garnered enough knowledge to face any challenges thrown their way. The vouchers, invoices, bills and the likes issued in the courses are those of real companies to ensure with ease they can carry out their work once they venture
                        into the professional world outside.
                    </p>
                </div>
                <div class="mt50" *ngIf="">
                    <button class="btn button_s1" *ngIf="!user.hasLoggedIn" (click)="onLogin()">Signin</button>
                </div>
            </div>
            <div class="col-md-6 col-xs-12" id="WhyUxImage">
                <div class="block">
                    <img src="assets/img/learning.svg" class="width100 p50">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section class="pt50 pb50 download_app_section_div">
  <div class="container">
    <div class="row dasd_main_row">
      <div class="col-md-8">
        <div class="block dads_text_div">
          <h1 class="dads_h1">Start Immediately In a Click </h1>
          <h2>Learn on the Go</h2>
          <p class="block width80">Zwei flinke Boxer jagen die quirlige Eva und ihren Mops durch Sylt. Franz jagt im
            komplett verwahrlosten Taxi quer durch Bayern. Zwölf Boxkämpfer jagen Viktor quer über den großen Sylter
            Deich. Vogel Quax zwickt Johnys Pferd Bim. Sylvia wagt quick den Jux bei Pforzheim. Polyfon zwitschernd
            aßen.</p>
        </div>
        <div class="dasd_second_div">
          <div class="block">
            <ul class="download_app_section">
              <li>
                <a href="#">
                  <img class="main_logo_header_download_app" src="assets/img/ios_icon.png">
                </a>
              </li>
              <li>
                <a href="#">
                  <img class="main_logo_header_download_app" src="assets/img/gplay_icon.png">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="block dnld_app_img_div">
          <img src="assets/img/dwnlo_ap.svg" class="width100 download_app_img">
        </div>
      </div>
    </div>
  </div>
</section> -->
<section class="pt50 testimonial_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12 tc">
                <h1 class="border_heading_bottom">Testimony Spotlights</h1>
            </div>
            <div class="col-md-offset-2 col-md-8 col-xs-12 tc">
                <p class="p0 m0">"Never call an accountant a credit to his profession, a good accountant is a debit to his profession.", rightfully said by Sir Charles Iyell. The confidence and experience garnered by tutees from OCOM is our true asset. Hear them out.</p>
            </div>
        </div>
        <div class="row mt50 tc">
            <div class="col-md-4 col-md-offset-4">
                <div class="testi_div">
                    <owl-carousel-o [options]="howitwork">
                        <ng-template carouselSlide>
                            <div class="item_car tc">
                                <div class="crs_item_img">
                                    <img src="assets/img/user.svg">
                                    <span class="medium block mt20">Sayyid Alam </span>
                                    <span class="block  mb20"></span>
                                    <p>The team does a great job in training you up to understand the program and are always available if you have any questions or to guide you through any problem</p>
                                </div>
                            </div>
                        </ng-template>
                        <!-- <ng-template carouselSlide>
              <div class="item_car tc">
                <div class="crs_item_img">
                  <img src="assets/img/user.svg">
                  <span class="medium block mt20">Peter John Lentin </span>
                  <span class="block  mb20"> San Francisco ( USA)</span>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                    et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    Lorem ipsum dolor sit amet.</p>
                </div>
              </div>
            </ng-template> -->
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</section>

<app-subscription-box></app-subscription-box>
<app-footer></app-footer>
<app-mobile-nav></app-mobile-nav>