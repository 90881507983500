import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class PlacementService {

  constructor(public api: Api) { }
  getJobs(){
    let endpoint = 'placement';
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }

}
