import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SigninComponent } from '../pages/signin/signin.component';
import { ExamService } from './exam.service';

@Injectable({ providedIn: 'root' })
export class LoginActivate implements CanActivate {
    constructor(private user: UserService, public router: Router,
        public dialog: MatDialog) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        if (this.user.hasLoggedIn) {
            return true
        } 
        // if (this.user.isFirstTime) {
            if (!this.user.hasLoggedIn && state.url != '/tabs/home') {
                console.log('Not logged in ');
                this.onLogin(state.url)
                // this.presentLogin(state.url)
            } else {
                return true;
            }
        //   } else {
        //     // this.landing()
        //     return false;
        //   }

       

    }

    onLogin(url:any=null) {
        const dialogRef = this.dialog.open(SigninComponent, {
            panelClass: 'sign_in_box',
        });
        dialogRef.afterClosed().subscribe(result => {
            if (url && this.user.hasLoggedIn) {
                this.router.navigateByUrl(url)
            }else{
              this.router.navigate(['/home'])
            }
            // this.loadCategories();
        });
      }
    // async presentLogin(redirectUrl) {
    //     const modal = await this.modalCtrl.create({
    //         component: LoginComponent,
    //         componentProps: { data: { redirectUrl: redirectUrl } }
    //     });
    //     await modal.present();

    //     await modal.onWillDismiss().then((data) => {
    //         console.log(data)
    //         if (data.data && data.data.loginSuccess) {
    //             if (redirectUrl) this.router.navigateByUrl(redirectUrl);
    //         } else {
    //             this.navCtrl.setDirection('back')
    //         }
    //     })
    // }

    // async landing() {
    //     const modal = await this.modalCtrl.create({
    //         component: LandingsComponent,
    //     });
    //     await modal.present();

    //     await modal.onWillDismiss().then((data) => {
    //         console.log(data)
    //     })
    // }
}

@Injectable({ providedIn: 'root' })
export class CanActivateChildGuard implements CanActivateChild {
    constructor(private dialog: MatDialog) {
        console.log("KKKKKKKKKK")
    }

    canActivateChild(): boolean {
        if (this.dialog.openDialogs.length > 0) {
            this.dialog.closeAll();
            return false;
        } else {
            return true;
        }
    }
}


@Injectable({ providedIn: 'root' })
export class SubmitActivate implements CanActivate {
    constructor(private exam: ExamService, public router: Router,
        public dialog: MatDialog) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {


        
            if (this.exam.leftTimeSeconds > 0) {
              return true
            } else {
                this.router.navigate(['/select-course'])
            }
        //   } else {
        //     // this.landing()
        //     return false;
        //   }

       

    }

  
}


