import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CategoryService } from 'src/app/providers/category.service';
import { CommonService } from '../../providers/common.service';
import { EventPublishService } from '../../providers/event-publish';

@Component({
  selector: 'app-select-course',
  templateUrl: './select-course.component.html',
  styleUrls: ['./select-course.component.scss']
})
export class SelectCourseComponent implements OnInit {

  loadingCourse: boolean = false
  courseList: any = []
  loadingCourseGroup: boolean = false
  courseGroupList: any = []
  is_subscribed: any
  selectedGroup: any
  loadingSubCourse:boolean=false
  subscribedCourseList:any=[]
  alreadySelectedCourse:any
  constructor(public category: CategoryService,public common:CommonService,
    public events:EventPublishService,public router:Router) { 
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0)
      });
      this.common.sideActive = 'course'
    }

  ngOnInit(): void {
    this.loadCourseGroup()
    this.subscribedCourses()
    if(localStorage.getItem(this.common.SELECTED_COURSE)){
      this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
    }
  }

  loadCourseGroup() {
    this.loadingCourseGroup = true;
    this
      .category.getAllCourseGroups()
      .subscribe(res => {
        if (res.success == true) {
          this.courseGroupList = res.data;
          if (this.courseGroupList && this.courseGroupList.length > 0) {
            this.onClickView(this.courseGroupList[0]._id)
          }

        }
        this.loadingCourseGroup = false;
      }, err => {
        this.loadingCourseGroup = false;
        
        return false;
      });
  }

  onClickView(id) {
    this.common.selectedGroup = id
    this.loadingCourse = true
    this
      .category.getCourseGroupById(id, this.is_subscribed = 0)
      .subscribe(res => {
        if (res.success == true) {
          this.courseList = res.data;
        }
        this.loadingCourse = false
      }, err => {
        
        this.loadingCourse = false
        return false;
      });
  }

  subscribedCourses() {
    this.loadingSubCourse = true
    this
      .category.getAllCourses(this.is_subscribed = 1)
      .subscribe(res => {
        if (res.success == true) {
          this.subscribedCourseList = res.data;
        }
        this.loadingSubCourse = false
      }, err => {
        this.loadingSubCourse = false
        
        return false;
      });
  }

  onSelectSubscribedCourse(course) {
    localStorage.setItem(this.common.SELECTED_COURSE,JSON.stringify(course))
    localStorage.removeItem(this.common.IS_TAB_METHOD)
    this.common.selectedCourse=course
    this.alreadySelectedCourse = course.title
    this.onSelectCourse(course) 
  }
  onSelectCourse(course) {
    localStorage.setItem(this.common.COURSE,JSON.stringify(course))
    this.events.publishSomeData(this.common.COURSE_CHANGES);
    
    this.router.navigate(['/course-week'])
    // this.navCtrl.navigateForward('/course-details', {
    //   queryParams: {
    //     course: course,
    //   }
    // });
  }

}
