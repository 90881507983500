import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventPublishService {

    private fooSubject = new Subject<any>();
    private fcmValue = new Subject<any>();
    private pauseTime = new Subject<any>();
    private login = new Subject<any>();

    publishSomeData(data: any) {
        this.fooSubject.next(data);
    }

    getObservable(): Subject<any> {
        return this.fooSubject;
    }
    publishFcmData(data: any,value:any=null) {
        this.fooSubject.next(data);
        this.fcmValue.next(value);
    }

    getFcmObservable(): Subject<any> {
        return this.fooSubject,this.fcmValue;
    }

    publishPauseTime(data: any) {
        this.pauseTime.next(data);
    }

    getPauseTimeObservable(): Subject<any> {
        return this.pauseTime;
    }

    publishLogin(data: any) {
        this.login.next(data);
    }

    getLoginObservable(): Subject<any> {
        return this.login;
    }
}