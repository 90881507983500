import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../providers/profile.service';
import { CommonService } from '../../providers/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Validator } from 'src/app/_helpers/validators';
import { UserService } from 'src/app/providers/user.service';
import { EventPublishService } from '../../providers/event-publish';
import * as moment from 'moment';
import { NavigationEnd, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Http, ResponseContentType } from '@angular/http';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profileData: any = []
  isEdit: boolean = false
  countryList: any = [];
  stateList: any = [];
  districtList: any = [];
  subscribedCources:any=[];
  certificate:any;
  editProfileForm: FormGroup
  editNameForm: FormGroup
  fileUrl:any;
  file_name:any;
  is_Certificate:boolean=false;
  saveProfileSubmitted: boolean = false
  d_o_b: any = null
  constructor(public profile: ProfileService, public common: CommonService,
    public formBuilder: FormBuilder, public validator: Validator, public datePipe: DatePipe,
    public user: UserService, public events: EventPublishService,
    public router: Router,private sanitizer: DomSanitizer,private _http: Http, private _FileSaverService: FileSaverService) {

    this.router.events.subscribe((evt) => {
      
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.editProfileForm = this.formBuilder.group({
      customer_name: ['', Validators.required],
      customer_email: ['', [Validators.required, Validators.pattern(this.validator.emailPattern())]],
      customer_mobile: [''],
      customer_gender: [''],
      customer_address: [''],
      customer_state: [''],
      customer_district: [''],
      customer_country: [''],
      customer_dob: ['']
    });

    this.editNameForm = this.formBuilder.group({
      customer_name: ['', Validators.required],
    });

    console.log(this.common.selectedCourse)
  }

  ngOnInit(): void {
    console.log(this.common.selectedCourse)
    this.loadCountry()
    this.getProfile();
  }

  get epf() { return this.editProfileForm.controls; }
  get enf() { return this.editNameForm.controls; }

  getProfile() {
    this.profile.getUserDetails()
      .subscribe(res => {
        if (res.success == true) {
          this.profileData = res.data;
          console.log(this.profileData)
          this.subscribedCources=this.profileData.subscription;
          console.log(this.subscribedCources)
          this.epf.customer_gender.patchValue(this.profileData.customer_gender);
          this.epf.customer_name.patchValue(this.profileData.customer_name);
          this.epf.customer_email.patchValue(this.profileData.customer_email);
          this.epf.customer_mobile.patchValue(this.profileData.customer_mobile);
          this.epf.customer_address.patchValue(this.profileData.customer_address);
          if (this.profileData.district && this.profileData.customer_district) {
            this.epf.customer_country.patchValue(this.profileData.district.state.country_id);
            this.getState(this.profileData.district.state.country_id)
            this.epf.customer_state.patchValue(this.profileData.district.state.title);
            this.getDistrict(this.profileData.district.state_id)
            this.epf.customer_district.patchValue(this.profileData.district.title)
          }
          if (this.profileData.customer_dob) this.d_o_b = this.datePipe.transform(this.profileData.customer_dob);
          if (this.profileData.customer_dob) this.epf.customer_dob.patchValue(this.profileData.customer_dob);
        }
      }, err => {
        // this.common.processError(err);
        return false;
      });
  }



  onSaveProfile() {
    if (!this.isEdit) {
      return
    }
    this.saveProfileSubmitted = true;
    if (this.editProfileForm.invalid) {
      return;
    }
    if (this.epf.customer_country.value && this.epf.customer_country.value != null) {
      if (!this.epf.customer_state.value || this.epf.customer_state.value == null) {
        return
      } else if (!this.epf.customer_district.value || this.epf.customer_district.value == null) {
        return
      }
    }

    if (this.editProfileForm.valid) {
      // this.isLoadingSaveProfile = true;
      var data = {
        customer_name: this.epf.customer_name.value,
        customer_mobile: this.epf.customer_mobile.value,
        customer_email: this.epf.customer_email.value,
        customer_gender: this.epf.customer_gender.value,
        customer_dob: moment(this.epf.customer_dob.value).toISOString(),
        customer_address: this.epf.customer_address.value,
        customer_district: this.epf.customer_district.value
      };
      
      this
        .profile
        .updateUserDetails(data)
        .subscribe(res => {
          if (res.success == true) {
            this.common.showToastSuccess(res.message)
            this.editProfileForm.reset();
            this.user.updateUserData(res.data);
            this.events.publishSomeData(this.common.USER_PROFILE_UPDATED);
            this.getProfile()
            this.isEdit = false
          } else {
            this.common.showToastError('Something went wrong')
          }
          // this.isLoadingSaveProfile = false;
        }, err => {
          // this.isLoadingSaveProfile = false;
          
          this.common.processError(err);
          return false;
        });
    }
  }

  onEdit() {
    this.isEdit = true
    this.epf.customer_country.patchValue(this.profileData.district.state.country_id);
    this.epf.customer_state.patchValue(this.profileData.district.state_id);
    this.epf.customer_district.patchValue(this.profileData.customer_district)
  }

  loadCountry() {
    this.profile.getCountry().subscribe(res => {
      if (res.success) {
        this.countryList = res.data
      }
    },error =>{
      this.common.processError(error)
    })
  }

  loadState(id) {
    this.profile.getState(id).subscribe(res => {
      if (res.success) {
        this.stateList = res.data
      }
    })
  }
  loadDistrict(id) {
    this.profile.getDistrict(id).subscribe(res => {
      if (res.success) {
        this.districtList = res.data
      }
    })
  }
  getState(event) {
    this.loadState(event)
  }
  getDistrict(event) {
    this.loadDistrict(event)
  }
  onLogout() {
    this.user.logout()
    this.events.publishSomeData(this.common.USER_LOGOUT);
    this.router.navigate(['/home']);
  }



  selectCource(event){
    console.log(event.target.value)
    var courceId=event.target.value
    this.profileData.certificate.forEach(element => {
      console.log(element.course_id)
      if(courceId ==element.course_id){
        this.certificate=element.file_url;
        this.is_Certificate=true;
        this.file_name=element.file
      }else{
        this.is_Certificate=false;
      }
    });
  }


 
  onSave() {
    this._http.get(this.certificate, {
      responseType: ResponseContentType.Blob // This must be a Blob type
    }).subscribe(res => {
      this._FileSaverService.save((<any>res)._body, this.file_name);
    });
  }
}
