import { Component, OnInit } from '@angular/core';
import { PlacementService } from '../../providers/placement.service';


@Component({
  selector: 'app-placement-cell',
  templateUrl: './placement-cell.component.html',
  styleUrls: ['./placement-cell.component.scss']
})
export class PlacementCellComponent implements OnInit {
  showModal:boolean = false;
  jobData:any;
  loader:boolean = true;
  constructor(public placement:PlacementService) { }

  ngOnInit(): void {
    this.getPlacement()
  }
  showInfo(){
    this.showModal = true;
  }
  clossModal(){
    this.showModal = false;
  }

  getPlacement(){
    this.placement.getJobs().subscribe(res=>{
      if(res.status == "true"){
        this.loader = false;
        this.jobData = res.data;
        console.log(this.jobData);
      }
    })
  }

}
