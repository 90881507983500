<app-side-nav></app-side-nav>

<section class="exam_lsit_section">
    <div class="container">
        <div class="row mb20">
            <div class="col-md-12 tc">
                <h1 class="border_heading_bottom">Results</h1>
            </div>
            <div class="col-md-offset-3 col-md-6 col-xs-12 tc">
                <p class="p0 m0">Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a
                    document without relying on meaningful content.</p>
            </div>
        </div>

        <div *ngIf='!loadingResult && (!resultList || resultList.length < 1)'>
            <div class="col-md-12">
              <div class="text-center">
                <!-- <div><img src="assets/images/nodata.svg" width="80"></div> -->
                <span>No Result Found!</span>
              </div>
            </div>
          </div>

          <div class='row mt50' *ngIf='loadingResult'>
            <div class="col-md-4 mb30" *ngFor="let itm of [1,2,3,4,5,6,7,8,9]">
                <div class="item">
                    <ngx-skeleton-loader [theme]="{ height: '150px'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>

        <div class="row mt50" infiniteScroll [infiniteScrollDistance]="0.5" (scrolled)="onScroll($event)">
            <div class="col-md-4 mb30" *ngFor="let result of resultList"
            (click)='onClickResultView(result)'>
                <div class="exam_list_selection">
                    <div class="exam_list_heading mb5">
                        <h3 class="medium mt0 mb0">{{result.exam.name}}</h3>
                    </div>
                    <div class="exam_list_duration">
                        <span *ngIf='result.completed_on && result.completed_on != null'>Completed On:
                            <span class="primary">{{ result.completed_on | date:'dd MMM yyyy' }}</span> </span>
                        <table class="mt20">
                            <tr>
                                <td class="pr20 tl">
                                    <span class="medium">{{result.total_mark}} Mark | {{result.total_correct_answers}}
                                        True |
                                        {{result.total_wrong_answers}} Wrong | {{result.total_unattended}} Unattended
                                    </span></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>