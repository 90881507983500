import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(public api: Api) { }
  getItems(per_page, page_no,course_id: any = 0, subject_id: any = 0,week_id:any=0,
    day_id:any=0,item_type:any=null,description=0,is_home:any=0,cur_day=0,) {
    let endpoint = 'materials?' ;
    if(page_no > 0) endpoint += 'page=' + page_no + '&'
    if(per_page > 0) endpoint += 'per_page=' + per_page + '&'
    if(course_id > 0) endpoint += 'course_id=' + course_id + '&'
    if(subject_id > 0) endpoint += 'subject_id=' + subject_id + '&'
    if(week_id > 0) endpoint += 'week_id=' + week_id + '&'
    if(day_id > 0) endpoint += 'day_id=' + day_id + '&'
    if(item_type != null) endpoint += 'item_type=' + item_type + '&'
    if(description > 0) endpoint += 'description=' + description + '&'
    // if(is_home > 0) endpoint += 'is_home=' + is_home + '&'
    if(cur_day > 0) endpoint += 'cur_day_only=' + cur_day 
    return this
      .api 
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }

  getTimeLineItems(course_id: any = null) {
    let endpoint = 'courses/'+course_id+'?count=1';
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  getTimeLineView(course_id: any = null,timeline_id: any = null) {
    let endpoint = 'courses/'+course_id+'/timelines/' + timeline_id ;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  getFeaturedItems(per_page, page_no, type: any = null, course_id: any = null, categoryId: any = null) {
    let endpoint = 'featured_items/' + page_no + '/' + per_page + '/' + type;
    if (categoryId) endpoint += '/0/' + categoryId;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  getSubject(course_id: any = null) {
    let endpoint = 'courses/'+course_id+'/subjects';
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  // getVideosByCatId(cat_id) {
  //   let endpoint = 'video/list/0/' + cat_id + '/0';
  //   return this
  //     .api
  //     .get(endpoint)
  //     .pipe(map(resp => resp.json()));
  // }
  getItemByid(_id,navigation,previous) {
    let endpoint = 'materials/' + _id+'?next='+navigation+'&previous='+previous;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }

  addPauseTime(data,id) {
    let endpoint = 'materials/' + id + '/history';
    return this
      .api
      .post(endpoint,data)
      .pipe(map(resp => resp.json()));
  }

  // CPMMENTS CPMMENTS CPMMENTS CPMMENTS CPMMENTS 
  getItemComments(_item_id,page_no,per_page) {
    let endpoint = 'materials/' + _item_id + '/comments?' ;
    if(page_no > 0) endpoint += 'page=' + page_no + '&'
    if(per_page > 0) endpoint += 'per_page=' + per_page 
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  sendComment(_data) {
    let endpoint = 'materials/comments';
    return this
      .api
      .post(endpoint, _data)
      .pipe(map(resp => resp.json()));
  }
  deleteComment(_id) {
    let endpoint = 'materials/comments/'+_id;
    return this
      .api
      .delete(endpoint)
      .pipe(map(resp => resp.json()));
  }

  // FAQ FAQ FAQ FAQ FAQ FAQ FAQ FAQ FAQ FAQ FAQ FAQ FAQ FAQ FAQ FAQ FAQ FAQ FAQ 
  addFaq(_data) {
    let endpoint = 'items/faq_save';
    return this
      .api
      .post(endpoint, _data)
      .pipe(map(resp => resp.json()));
  }
  // HISTORY
  getHistories(page_no, per_page) {
    let endpoint = 'myhistory/' + page_no + '/' + per_page;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }

  myFaqs(page_no, per_page) {
    let endpoint = 'my_faq/' + page_no + '/' + per_page;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  // Ticket
  getTicketsCategories() {
    let endpoint = 'tickets/categories' ;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  getTickets(page_no,per_page,status:any=null) {
    let endpoint = 'tickets?';
    if(page_no > 0) endpoint += 'page=' + page_no + '&'
    if(per_page > 0) endpoint += 'per_page=' + per_page +'&'
    if(status != null) endpoint += 'status=' + status
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  getMyTickets(page_no,per_page) {
    let endpoint = 'tickets/my-tickets?';
    if(page_no > 0) endpoint += 'page=' + page_no + '&'
    if(per_page > 0) endpoint += 'per_page=' + per_page 
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  viewTickets(id) {
    let endpoint = 'tickets/'+id;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  addTickets(_data) {
    let endpoint = 'tickets';
    return this
      .api
      .post(endpoint, _data)
      .pipe(map(resp => resp.json()));
  }
  getTicketComments(ticket_id,page,per_page) {
    let endpoint = 'tickets/'+ticket_id+'/comments?';
    if(page > 0) endpoint += 'page=' + page + '&'
    if(per_page > 0) endpoint += 'per_page=' + per_page + '&'
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  addTicketComments(_data) {
    let endpoint = 'tickets/comments';
    return this
      .api
      .post(endpoint, _data)
      .pipe(map(resp => resp.json()));
  }
  deleteTicketComments(comment_id) {
    let endpoint = 'tickets/comments/'+comment_id;
    return this
      .api
      .delete(endpoint)
      .pipe(map(resp => resp.json()));
  }
  // contact Us

  addContactUs(data) {
    let endpoint = 'contact';
    return this
      .api
      .post(endpoint,data)
      .pipe(map(resp => resp.json()));
  }

  //  Media

  getNewsUpdate() {
    let endpoint = 'newsupdate/list';
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }

  getStatus(course_id:any=0,type:any=null,day_id:any=0,week_id:any=0) {
    let endpoint = 'report/status?';
     if(course_id > 0) endpoint += 'course_id='+course_id + '&'
     if(type != null) endpoint += 'type='+type + '&'
     if(day_id > 0) endpoint += 'day_id='+day_id + '&'
     if(week_id > 0) endpoint += 'week_id='+week_id 
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }


  getCurDayOnly(){
    let endpoint = 'materials?cur_week_only=1&cur_day_only=1';
   return this
     .api
     .get(endpoint)
     .pipe(map(resp => resp.json()));
  }

  getCurDayCourse(id){
    let endpoint = 'courses/cur_day?'
    if(id != null) endpoint += 'course_id='+id
   return this
     .api
     .get(endpoint)
     .pipe(map(resp => resp.json()));
  }
  addCustomerProfilePic(data) {
    let endpoint = 'profile/edit/image';
    let fData = new FormData();
    for (var key in data) {
        fData.append(key, data[key]);
    }
    return this
      .api
      .postAsFormData(endpoint,fData)
      .pipe(map(resp => resp.json()));
  }
}
