import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SigninComponent } from '../../signin/signin.component';
import { UserService } from '../../../providers/user.service';
import { Router } from '@angular/router';
import { CommonService } from '../../../providers/common.service';
import { EventPublishService } from '../../../providers/event-publish';
import { ProfileService } from '../../../providers/profile.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showNav: boolean = false;
  userDetails:any=[];
  user_Details:any;
  isprint:boolean = false;
  constructor(public dialog: MatDialog, public user: UserService,
    public router: Router, public common: CommonService, public events: EventPublishService,
    public profile:ProfileService,public zone:NgZone) { 

      this.events.getObservable().subscribe((data) => {
        if (data == 'uexuser:logout') {
          this.zone.run(() => {
            setTimeout(() => {
              this.user.logout()
            }, 500)
  
          })
        }
        if (data == 'uexuser:login') {
          this.zone.run(() => {
            if (localStorage.getItem(this.user.PROFILE)){
              this.user_Details=JSON.parse(localStorage.getItem(this.user.PROFILE))
              console.log(this.user_Details)
             }
            setTimeout(() => {
              this.getProfile()
            }, 500)
  
          })
        }
      })
      
    }

  ngOnInit(): void {
    if (this.user.hasLoggedIn) {
      // this.getProfile()
      if (localStorage.getItem(this.common.SELECTED_COURSE)) {
        this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
        console.log(this.common.selectedCourse)
      }
      if (localStorage.getItem(this.user.PROFILE)){
       this.user_Details=JSON.parse(localStorage.getItem(this.user.PROFILE))
       console.log(this.user_Details)
      }
     
    }
    

  }

  getProfile() {
    this.profile.getUserDetails()
      .subscribe(res => {
        if (res.success == true) {
          this.userDetails = res.data;
          if (this.userDetails.subscription && this.userDetails.subscription.length == 1) {
            this.common.selectedCourse = this.userDetails.subscription[0].course
            localStorage.setItem(this.common.SELECTED_COURSE, JSON.stringify(this.common.selectedCourse))
          }else if(this.userDetails.subscription.length > 1){
            this.common.selectedCourse = this.userDetails.subscription.find(x=>x.course_id == this.userDetails.current_course_id).course
            localStorage.setItem(this.common.SELECTED_COURSE, JSON.stringify(this.common.selectedCourse))
          }
        }
      }, err => {
        this.common.processError(err);
        return false;
      });
  }

  onLogin() {
    const dialogRef = this.dialog.open(SigninComponent, {
      panelClass: 'sign_in_box',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.success && result.success == true) {
        // this.loadCategories();
      }
    });
  }

  onLogout() {
    this.user.logout()
    this.events.publishSomeData(this.common.USER_LOGOUT);
    this.router.navigate(['/home']);
  }


  getCustomerName(name){
    return name.split(' ').map(n => n[0]).join('');
  }

  mouseEnter(e){
    this.isprint = true;
  }
  mouseLeave(e){
    this.isprint = false;
  }

  
}
