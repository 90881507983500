import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { CourseWeekComponent } from './pages/course-week/course-week.component';
import { ExamListComponent } from './pages/exam-list/exam-list.component';
import { ExamResultComponent } from './pages/exam-result/exam-result.component';
import { ExamViewComponent } from './pages/exam-view/exam-view.component';
import { HomeComponent } from './pages/home/home.component';
import { MaterialDetailComponent } from './pages/material-detail/material-detail.component';
import { MaterialViewComponent } from './pages/material-view/material-view.component';
import { PackageListComponent } from './pages/package-list/package-list.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { QuestionPoolComponent } from './pages/question-pool/question-pool.component';
import { RegistrationFormComponent } from './pages/registration-form/registration-form.component';
import { SelectCourseComponent } from './pages/select-course/select-course.component';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SubmitExamComponent } from './pages/submit-exam/submit-exam.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { VideoDetailComponent } from './pages/video-detail/video-detail.component';
import { VideoListComponent } from './pages/video-list/video-list.component';
import { ResultComponent } from './pages/result/result.component';
import { CanActivateChildGuard, LoginActivate, SubmitActivate } from './providers/loginActivate';
import { SuccessPackageComponent } from './pages/success-package/success-package.component';
import { TermsComponent } from './pages/terms/terms.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PlacementCellComponent } from './pages/placement-cell/placement-cell.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path:'signup', component: SignupComponent
  },
  {
    path:'signin', component: SigninComponent
  },
  {
    path:'verify', component: VerifyComponent
  },
  {
    path:'home', component: HomeComponent
  },
  {
    path:'video-list', component: VideoListComponent,canActivate:[LoginActivate]
  },
  {
    path:'video-list/:id', component: VideoListComponent,canActivate:[LoginActivate]
  },
  {
    path:'video-detail', component: VideoDetailComponent,canActivate:[LoginActivate]
  },
  {
    path:'material-detail', component: MaterialDetailComponent
  },
  {
    path:'material-view', component: MaterialViewComponent
  },
  {
    path:'profile', component: ProfileComponent,canActivate:[LoginActivate]
  },
  // {
  //   path:'registration-form', component: RegistrationFormComponent
  // },
  {
    path:'select-course', component: SelectCourseComponent
  }
  ,
  {
    path:'package-list', component: PackageListComponent
  },
  {
    path:'exam-list', component: ExamListComponent,canActivate:[LoginActivate]
  },
  {
    path:'exam-view/:id', component: ExamViewComponent
  },
  {
    path:'question-pool/:id', component: QuestionPoolComponent
  },
  {
    path:'submit-exam', component: SubmitExamComponent ,canActivate:[SubmitActivate]
  },
  {
    path:'exam-result/:id', component: ExamResultComponent
  },
  {
    path:'course-week', component: CourseWeekComponent,canActivateChild: [ CanActivateChildGuard ],
  },
  {
    path:'result', component: ResultComponent,canActivate:[LoginActivate]
  },
  {
    path:'terms', component: TermsComponent
  },
  {
    path:'privacy', component: TermsComponent
  },
  {
    path:'refund-policy', component: TermsComponent
  },
  {
    path:'about-us', component: AboutUsComponent
  },
  {
    path:'placement-cell', component: PlacementCellComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
