<!-- <app-side-nav></app-side-nav> -->
<form [formGroup]="registrationForm">
    <section class="profile_Card video_frame">
        <div class="container">
            <div class="row">
                <button type="button" aria-label="Close" class="close_model_div" (click)="dialogRef.close()">
          <img src="assets/img/close.svg" class="close_model">
        </button>
                <div class="col-md-12 tc">
                    <h1 class="border_heading_bottom">Registration Form</h1>
                    <!-- <p>But I must explain to you how all this mistaken idea of denouncing
            pleasure and praising pain was born and I.</p> -->
                </div>
                <div class="col-md-12 mt50">
                    <div class="pr_info_title mb10">
                        <div class="row">
                            <div class="col-md-6 tl">
                                <h3 class="mt0 block">Personal Details</h3>
                            </div>
                            <div class="col-md-6 tr">
                                <!-- <img src="assets/img/edit.svg" class="edit_icon"> -->
                            </div>
                        </div>
                    </div>
                    <div class="profile_form">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" class="input_control_1" formControlName="first_name" placeholder="First Name">
                                <div *ngIf="submitted && acf.first_name.errors">
                                    <div class="form-error-msg" *ngIf="acf.first_name.errors.required">*Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="input_control_1" formControlName="last_name" placeholder="Last Name">
                            </div>
                            <!-- <div class="col-md-6">
                <input type="text" class="input_control_1" formControlName="father_name" placeholder="Father's Name">
              </div> -->
                            <div class="col-md-6">
                                <input type="date" class="input_control_1" formControlName="dob" placeholder="Date of Birth ">
                                <div *ngIf="submitted && acf.dob.errors">
                                    <div class="form-error-msg" *ngIf="acf.dob.errors.required">*DOB is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt20">
                    <div class="pr_info_title mb10">
                        <div class="row">
                            <div class="col-md-6 tl">
                                <h3 class="mt0 block">Contact Details</h3>
                            </div>
                            <div class="col-md-6 tr">
                                <!-- <img src="assets/img/edit.svg" class="edit_icon"> -->
                            </div>
                        </div>
                    </div>
                    <div class="profile_form">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="email" class="input_control_1" formControlName="email" placeholder="Email * ">
                                <div *ngIf="submitted && acf.email.errors">
                                    <div class="form-error-msg" *ngIf="acf.email.errors.required">*Email is required</div>
                                    <div class="form-error-msg" *ngIf="acf.email.errors.pattern">Enter Valid email</div>
                                </div>

                            </div>
                            <div class="col-md-6">
                                <input type="number" class="input_control_1" formControlName="mobile" placeholder="Phone">
                                <div *ngIf="submitted && acf.mobile.errors">
                                    <div class="form-error-msg" *ngIf="acf.mobile.errors.required">*Mobile Number is required</div>
                                    <div class="form-error-msg" *ngIf="acf.mobile.errors.pattern || acf.mobile.errors.minlength">
                                        Enter Valid mobile number</div>
                                </div>

                            </div>
                            <!-- <div class="col-md-6">
                <input type="text" class="input_control_1" formControlName="building_name"
                  placeholder="Building Name & Number * ">

              </div> -->
                            <!-- <div class="col-md-6">
                <input type="text" class="input_control_1" formControlName="street_address"
                  placeholder="Street Address">
                <div *ngIf="submitted && acf.street_address.errors">
                  <div class="form-error-msg" *ngIf="acf.street_address.errors.required">*Address is
                    required</div>
                </div>
              </div> -->
                            <!-- <div class="col-md-6">
                <input type="text" class="input_control_1" formControlName="city" placeholder="City *">
                <div *ngIf="submitted && acf.city.errors">
                  <div class="form-error-msg" *ngIf="acf.city.errors.required">*City is
                    required</div>
                </div>
              </div> -->
                            <!-- <div class="col-md-6">
                <input type="number" class="input_control_1" formControlName="pincode" placeholder="Pincode *">
                <div *ngIf="submitted && acf.pincode.errors">
                  <div class="form-error-msg" *ngIf="acf.pincode.errors.required">*Pincode is
                    required</div>
                </div>
              </div> -->
                        </div>
                    </div>
                </div>

                <!-- <div class="col-md-12 mt20">
          <div class="pr_info_title mb10">
            <div class="row">
              <div class="col-md-6 tl">
                <h3 class="mt0 block">Education Qualification</h3>
              </div>
              <div class="col-md-6 tr">
                <img src="assets/img/edit.svg" class="edit_icon">
              </div>
            </div>
          </div>
          <div class="profile_form">
            <div class="row">
              <div class="col-md-6">
                <input type="text" class="input_control_1" formControlName="doc" placeholder="Degree Of Study*">
              </div>
              <div class="col-md-6">
                <input type="number" class="input_control_1" formControlName="yoc" placeholder="Year of Completion*">
              </div>
              <div class="col-md-6">
                <input type="text" class="input_control_1" formControlName="boe" placeholder="Board Of Exam*">
              </div>
            </div>
          </div>
        </div> -->

                <div class="col-md-12 mt20">
                    <div class="check_input">
                        <input type="checkbox" (change)="checkBox($event)">
                        <p>The Above Given Details will taken For your Certification and Further Need So Once check the Detail Before Register </p>
                    </div>
                </div>

                <div class="col-md-12 mt20 tr mb50">
                    <button class="button_s1" (click)="saveAddress()">
            <svg *ngIf="isLoading" class="spinner" viewBox="0 0 50 50">
              <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
            Register</button>
                </div>
            </div>
        </div>

    </section>
</form>