<app-side-nav></app-side-nav>

<section class="home_course_tab" *ngIf="subscribedCourseList && subscribedCourseList.length > 0">
    <div class="container">
        <div class="row mb20">
            <div class="col-md-12 tc">
                <h1 class="border_heading_bottom">Subscribed Courses</h1>
            </div>
            <div class="col-md-offset-3 col-md-6 col-xs-12 tc">
                <p class="p0 m0">OCOM, one of the best Practical Accounts Training provider facilitate International Accounting Workshop for those who want International accounting experience. Our training method is entirely different from others. Here, OCOM appoint you
                    as an accountant of Honesty International Company and gives on the job training. Through this workshop, OCOM transforms you an accountant to International Accounting Professional.</p>
            </div>
        </div>

        <div class='row' *ngIf="loadingSubCourse">
            <div class="col-md-12 pt30">
                <div class="row">
                    <div class="col-md-3 mb15" *ngFor="let itm of [1,2,3,4,5,6]">
                        <div class="item">
                            <ngx-skeleton-loader [theme]="{ height: '155px'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 pt30">
                <div class="row">
                    <div class="col-md-3 mb15" *ngFor="let course of subscribedCourseList" (click)='onSelectSubscribedCourse(course)'>
                        <div class="course_select_box">
                            <div class="csb_inner_div">
                                <img [src]="course.image_url" onError="this.src='assets/img/home_slider1.png'">
                                <ng-container *ngIf="common.selectedCourse && course._id == common.selectedCourse._id">
                                    <button class="selected_crs_button"> <img src="assets/img/circle_check.svg"> <span>Selected
                      Course</span> </button>
                                </ng-container>
                            </div>
                            <div class="csb_inner_text">
                                <span class="medium">{{course.title}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home_course_tab">
    <div class="container">
        <div class="row mb20">
            <div class="col-md-12 tc">
                <h1 class="border_heading_bottom">Courses</h1>
            </div>
            <div class="col-md-offset-3 col-md-6 col-xs-12 tc">
                <p class="p0 m0">OCOM, one of the best Practical Accounts Training provider facilitate International Accounting Workshop for those who want International accounting experience. Our training method is entirely different from others. Here, OCOM appoint you
                    as an accountant of Honesty International Company and gives on the job training. Through this workshop, OCOM transforms you an accountant to International Accounting Professional.</p>
            </div>
        </div>

        <div class='row' *ngIf="loadingCourseGroup">
            <div class="col-md-12">
                <div class="crs_tab_block tc mt20 mb20">
                    <ngx-skeleton-loader count="8" [theme]="{ 
            margin:'10px', height: '50px',  width: '120px'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- <div class="col-md-12">
        <div class="crs_tab_block tc mt20 mb20">
          <button class="btn button_s1" (click)="onClickView(group._id)"
            [ngClass]="{'active':common.selectedGroup==group._id}"
            *ngFor="let group of courseGroupList">{{group.title}}</button>
        </div>
      </div> -->

            <div *ngIf='!loadingCourse && (!courseList || courseList.length < 1)'>
                <div class="col-md-12">
                    <div class="text-center">
                        <!-- <div><img src="assets/images/nodata.svg" width="80"></div> -->
                        <span>No Course Found!</span>
                    </div>
                </div>
            </div>

            <div class="col-md-12 pt30" *ngIf='loadingCourse'>
                <div class="row">
                    <div class="col-md-3 mb15" *ngFor="let itm of [1,2,3,4,5,6,7,8,9]">
                        <ngx-skeleton-loader [theme]="{ height: '220px',margin: '10px'}"></ngx-skeleton-loader>
                    </div>
                </div>
            </div>

            <div class="col-md-12 pt30">
                <div class="row">
                    <div class="col-md-3 mb15" *ngFor="let course of courseList" (click)='onSelectCourse(course)'>
                        <div class="course_select_box">
                            <div class="csb_inner_div">
                                <img [src]="course.image_url" onError="this.src='assets/img/home_slider1.png'">
                                <ng-container *ngIf="i==2">
                                    <button class="selected_crs_button"> <img src="assets/img/circle_check.svg"> <span>Selected
                      Course</span> </button>
                                </ng-container>
                            </div>
                            <div class="csb_inner_text">
                                <span class="medium">{{course.title}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <app-subscription-box></app-subscription-box> -->
<app-footer></app-footer>