import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public api: Api) { }
  getNotifications(page,per_page) {

    let endpoint = 'notifications?';
    if(page > 0) endpoint += 'page=' + page + '&'
    if(per_page > 0) endpoint += 'per_page=' + per_page 
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }

}
