<footer class="footer_bottom">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>Contact Us</h2>
            </div>
            <div class="col-md-4">
                <ul class="mb20_mobile">
                    <li class="medium">Email: ocomacc@gmail.com</li>
                    <li class="medium">
                        <a class="flex_a" target="_blank" href="tel:+917012308427">
                            <span>
                <img src="assets/img/call.svg">
              </span> <span>+91 70123 08427</span>
                        </a>
                    </li>
                    <li class="medium">
                        <a class="flex_a" target="_blank" href="https://wa.me/+97466330448?text=Hello">
                            <span>
                <img src="assets/img/wa.svg">
              </span> <span>+974 663 30448</span>
                        </a>
                    </li>

                    <li>
                        <a class="navbar_footer_brnad" routerLink="/">
                            <img class="footer_brnad mt20" src="assets/img/logo.svg">
                            <img class="footer_brnad mt20" src="assets/img/iso.gif">
                        </a>
                    </li>
                    <!-- <li class="pb20 medium">www.ocomaccounts.com</li> -->
                </ul>
                <!-- <ul class="social_icons_footer mt20">
          <li>
            <a target="_blank" href="https://www.facebook.com/">
              <img src="assets/img/fb.svg">
            </a>
            <a target="_blank" href="https://youtube.com/">
              <img src="assets/img/skyp.svg">
            </a>
            <a target="_blank" href="#">
              <img src="assets/img/in.svg">
            </a>
            <a target="_blank" href="https://www.instagram.com/">
              <img src="assets/img/insta.svg">
            </a>
          </li>
        </ul> -->
            </div>
            <div class="col-md-8">
                <form [formGroup]="addContactForm">
                    <div class="footer_form">
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" name="name" id="name" value="" placeholder="Name*" formControlName="name" class="input_wrap">
                                <div *ngIf="submitted && acf.name.errors" class="form-error-msg">
                                    <div class="form-error-msg ion-text-left" *ngIf="acf.name.errors.required">Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="number" name="phone" id="phone" value="" formControlName="mobile" placeholder="Phone*" class="input_wrap">
                                <div *ngIf="submitted && acf.mobile.errors" class="form-error-msg">
                                    <div class="form-error-msg ion-text-left" *ngIf="acf.mobile.errors.required">Mobile Number is required
                                    </div>
                                    <div class="form-error-msg ion-text-left" *ngIf="acf.mobile.errors.pattern">Valid Mobile Number is required
                                    </div>
                                    <div class="form-error-msg ion-text-left" *ngIf="acf.mobile.errors.minlength ">
                                        Mobile Number must be in 10 digit</div>
                                    <div class="form-error-msg ion-text-left" *ngIf="acf.mobile.errors.maxlength">
                                        Mobile Number less than 12 digit</div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="email*" name="email" id="email" value="" formControlName="email" class="input_wrap" placeholder="E-mail">
                                <div *ngIf="submitted && acf.email.errors" class="form-error-msg">
                                    <div class="form-error-msg ion-text-left" *ngIf="acf.email.errors.pattern">Valid Email is required</div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <textarea placeholder="Message*" rows="4" cols="50" name="message" id="message" class="input_wrap" value="" formControlName="message"></textarea>
                                <div *ngIf="submitted && acf.message.errors" class="form-error-msg">
                                    <div class="form-error-msg" *ngIf="acf.message.errors.required">Message is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 tr m_tc">
                                <button type="submit" id="contact_form" value="Submit" (click)="onSubmit()" class="button_s1">Submit
                  <svg *ngIf="isLoading" class="spinner" viewBox="0 0 50 50">
                    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                  </svg>
                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-md-12 mt20">
                <div class="row">
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-10 tr">
                        <div class="mt10 m_text_center">
                            <a class="black pointer" [routerLink]="['/about-us']">About Us</a> |
                            <a class="black pointer" [routerLink]="['/placement-cell']">Placement</a> | <a class="black pointer" [routerLink]="['/privacy']">Privacy Policy</a> | <a class="pointer" [routerLink]="['/terms']">Terms &
                Conditions</a> | <a class="black pointer" [routerLink]="['/refund-policy']">Refund Policy</a> | Copy right © 2021 OCOM | All Rights reserved
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- <div class="wh_fixed_icon">
  <a target="_blank" href="https://wa.me/+97466330448?text=Hello">
    <img src="assets/img/wa.svg">
  </a>
</div> -->

<div class="water_mark" *ngIf="user.hasLoggedIn && common.show_marquee">
    <div class="w_m_vertical">
        <marquee direction="left">
            <span>OCOM User: {{userProfile.customer_name }}| Phone: {{userProfile.customer_mobile}}</span>
        </marquee>
    </div>
</div>

<!-- <div class="water_m_top" *ngIf="user.hasLoggedIn">
  <div class="wmt_f">
    <marquee direction="left">
      <span>OCOM User: {{userProfile.customer_name }}| Phone: {{userProfile.customer_mobile}}</span>
    </marquee>
  </div>
</div> -->

<!-- <div class="water_mark_2">
  <div class="w_m_vertical">
    <marquee direction="left">
      <span>OCOM User: Asharaf | Phone: 9746761790</span>
    </marquee>
  </div>
</div> -->