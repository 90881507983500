import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/providers/common.service';
import { CourseService } from '../../providers/course.service';
import { ItemService } from '../../providers/item.service';

@Component({
  selector: 'app-success-package',
  templateUrl: './success-package.component.html',
  styleUrls: ['./success-package.component.scss']
})
export class SuccessPackageComponent implements OnInit {

  SubscriptionData:any;
  courseData:any;

  constructor(public dialogRef: MatDialogRef<SuccessPackageComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  public item:ItemService,public ngZone:NgZone,public common:CommonService) { 
    console.log(data.data)
    this.SubscriptionData=data.data
    console.log(this.SubscriptionData)
  }

  ngOnInit(): void {
  this.getCourse();
  }


  close(){
    console.log("closing")
    this.ngZone.run(() => {
      this.dialogRef.close();
    });

  }

getCourse(){
  this.item.getTimeLineItems(this.SubscriptionData.course_id).subscribe(resp=>{
    if(resp.success){
    this.courseData=resp.data;
    console.log(this.courseData)
    } 
  },error=>{
    this.common.processError(error)
  })
}
}
