import { Component, OnInit, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonService } from '../../providers/common.service';
import { ExamService } from 'src/app/providers/exam.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as $ from 'jquery';

@Component({
  selector: 'app-question-pool',
  templateUrl: './question-pool.component.html',
  styleUrls: ['./question-pool.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionPoolComponent implements OnInit {

  questionType:any
  examName:any
  colSize:number=12
  id:any
  isLoading:any=false
  buttonValue: number = 0
  isSubmit: boolean = false
  liveQuestion: any = []
  currentQuestionIndex: number = 0
  isPrevious:boolean=false
  timeTake: any
  leftTime: boolean = true;
  answers: any = []
  timer:any;;
  reviewAnswerFlag:any='all'
  answerKeyFlag:any='all'
  constructor(public common:CommonService,public exam: ExamService,
    public route: ActivatedRoute,public ngZone: NgZone,
    public router: Router,public dialog: MatDialog,) { 
    this.route.params.subscribe(params => {
      this.id = params['id']
    })
  }

  ngOnInit(): void {
    this.timer=localStorage.getItem('timer')
    // var remSec = $("#count"),
    // countSec = 3,
    // timer = setInterval(() => {
    // countSec >= 0 ? remSec.text(countSec--) : clearInterval(timer);
    // // countSec == 0 ? $(".starting_counter").addClass("hide");
    // }, 1000);
    this.questionType = localStorage.getItem(this.common.QUESTION_FLAG)
    if(this.questionType == "question"){
      var dro = 5
    }else{
      var dro = 0
    }
    function setup() {
      var es = $("#count");
      if (dro > 0) {
        dro--;
        es.html(dro);
        let t = setTimeout(function () {
          setup();
        }, 1000);
      }
      else {
        es.text('');
        $(".starting_counter").addClass("hide");
      }
    }
    setup();
   
    this.examName = localStorage.getItem(this.common.EXAM_NAME)
    if (this.questionType == 'question'){
      this.isLoading = true
      if(localStorage.getItem(this.common.IS_EXAM_EXIST)){
        this.exam.isExamComplete = localStorage.getItem(this.common.IS_EXAM_EXIST)
      }else{
        this.exam.isExamComplete = false
      }
      if (this.questionType == 'question') {
        this.colSize = 8;
        if (!this.exam.isCalculate) {
          console.log(this.exam.isCalculate)
          this.exam.calculateLeftTime()
        }
      }
    
      this.viewQuestion(this.buttonValue)
      console.log(this.exam.viewQuestion)
      this.buttonValue = 0
      if (this.exam.questions.length == this.buttonValue + 1) {
        this.isSubmit = true
      } else {
        this.isSubmit = false
      }
  
      if (this.exam.viewQuestion) {
        this.liveQuestion = []
        this.ngZone.run(() => {
          setTimeout(()=>{
            this.currentQuestionIndex = this.buttonValue = this.exam.viewQuestion.id;
            // this.superTabs.selectTab(this.currentQuestionIndex,true);
          },500)
          
        })
        this.liveQuestion.push(this.exam.viewQuestion.question)
        if (this.exam.questions.length == this.buttonValue + 1) {
          this.isSubmit = true
        } else {
          this.isSubmit = false
        }
        if (this.buttonValue > 0) {
          this.isPrevious = true
        } else {
          this.isPrevious = false
        }
      }
    }
    if (this.questionType == 'result') {
      this.loadReviewQuestions()
    }
      
    }

    viewQuestion(buttonValue) {
      this.liveQuestion = []
      this.buttonValue = buttonValue
      this.exam.questions = JSON.parse(localStorage.getItem(this.common.EXAM_QUESTIONS))
      console.log(this.exam.questions)
      this.exam.questions.forEach((element, index) => {
        if (index == buttonValue) {
          this.liveQuestion.push(element)
          this.isLoading = false
        }
      });
      if (this.exam.questions.length == this.buttonValue + 1) {
        this.isSubmit = true
      } else {
        this.isSubmit = false
      }
      if (this.buttonValue > 0) {
        this.isPrevious = true
      } else {
        this.isPrevious = false
      }
    }
  
    nextAction() {
      this.buttonValue = this.buttonValue + 1;
      this.ngZone.run(() => {
        this.currentQuestionIndex = this.buttonValue;
      })
      if (this.exam.questions.length == this.buttonValue + 1) {
        this.isSubmit = true
      } else {
        this.isSubmit = false
      }
      if (this.buttonValue > 0) {
        this.isPrevious = true
      } else {
        this.isPrevious = false
      }
      this.viewQuestion(this.buttonValue)
      const el: HTMLElement = document.getElementById('ques_button_' + this.buttonValue) as HTMLElement;
      el.click();
    }
    previousAction() {
      this.buttonValue = this.buttonValue - 1
      this.currentQuestionIndex = this.buttonValue
      if (this.exam.questions.length == this.buttonValue + 1) {
        this.isSubmit = true
      } else {
        this.isSubmit = false
      }
      if (this.buttonValue > 0) {
        this.isPrevious = true
      } else {
        this.isPrevious = false
      }
      this.viewQuestion(this.buttonValue)
      // const el: HTMLElement = document.getElementById('ques_button_' + this.buttonValue) as HTMLElement;
      // el.click();
    }
  
    questionChanged(event) {
      this.ngZone.run(() => {
        this.currentQuestionIndex = this.buttonValue = event.detail.index
      })
      
      if (this.exam.questions.length == this.buttonValue + 1) {
        this.isSubmit = true
      } else {
        this.isSubmit = false
      }
      if (this.buttonValue > 0) {
        this.isPrevious = true
      } else {
        this.isPrevious = false
      }
      this.viewQuestion(this.buttonValue)
    }
  
    changeQuestion(i) {
      this.ngZone.run(() => {
        this.currentQuestionIndex = this.buttonValue = i
      })
      if (this.exam.questions.length == this.buttonValue + 1) {
        this.isSubmit = true
      } else {
        this.isSubmit = false
      }
      if (this.buttonValue > 0) {
        this.isPrevious = true
      } else {
        this.isPrevious = false
      }
      this.viewQuestion(this.buttonValue)
    }
  
    questionAnswer(event) {
      if(localStorage.getItem(this.common.EXAM_QUESTIONS)){
        let temp = JSON.parse(localStorage.getItem(this.common.EXAM_QUESTIONS))
        temp.forEach(element => {
          if(element._id == this.liveQuestion[0]._id){
             element.user_answer = this.liveQuestion[0].user_answer = event.value
          }
        });
        localStorage.setItem(this.common.EXAM_QUESTIONS,JSON.stringify(temp))
      }else{
        localStorage.setItem(this.common.EXAM_QUESTIONS, JSON.stringify(this.exam.questions))
      }
      console.log(this.liveQuestion)
    }
  
    clearAnswer(question){
      question.user_answer = null
      let temp = JSON.parse(localStorage.getItem(this.common.EXAM_QUESTIONS))
      temp.forEach(element => {
        if(element._id == this.liveQuestion[0]._id){
           element.user_answer = this.liveQuestion[0].user_answer = null
           localStorage.setItem(this.common.EXAM_QUESTIONS,JSON.stringify(temp))
        }
      });
    }
   
    submitEvent() {
      this.router.navigate(['/submit-exam'])
    }
  
    alphabetIndex(i){
      return String.fromCharCode(65+i)
    }

    loadReviewQuestions() {
        this.exam.myResultView(this.id).subscribe(res => {
          if (res.success) {
            this.exam.questions = []
            console.log(res.data)
            localStorage.removeItem(this.common.EXAM_QUESTIONS)
            localStorage.setItem(this.common.EXAM_QUESTIONS, JSON.stringify(res.data.questions));
            localStorage.setItem(this.common.EXAM_NAME, JSON.stringify(res.data.exam.name));
            this.exam.questions = JSON.parse(localStorage.getItem(this.common.EXAM_QUESTIONS))
            this.liveQuestion.push(this.exam.questions[0])
            console.log(this.exam.questions,'this.exam.questions')
          }
        })
      }
    
  
    // allQuestion() {
    //   if (this.exam.isExamComplete == 'false' ) {
    //     const dialogRef = this.dialog.open(AllQuestionComponent, {
    //       data : {
    //         flag: 'answerKey',
    //         examId: this.id,
    //       }
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //       console.log(result)
    //       if (result) {
    //         this.buttonValue=result.id
    //         this.viewQuestion(result.id)
    //       }
    //     });
       
    //   } else {
    //     const dialogRef = this.dialog.open(AllQuestionComponent, {
    //       data : {
    //         flag: 'reviewAnswer',
    //         examId: localStorage.getItem(this.common.EXAM_ID),
    //       }
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //       console.log(result)
    //       if (result) {
    //         this.buttonValue=result.id
    //         this.viewQuestion(result.id)
    //       }
    //     });
    //   }
    // }

}
