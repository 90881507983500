<app-side-nav></app-side-nav>
<div class="starting_counter" *ngIf="!timer">
  <div class="st_cout_text" id="count"></div>
</div>
<section class="question_pool_section">
  <div class="container">
    <div class="row">
      <div class="col-md-12  pool_heading">
        <div class="row">
          <div class="col-md-6 tl">
            <h2 class="p0 mt0">{{examName}}</h2>
          </div>
          <div class="col-md-6 tr" *ngIf="questionType == 'question'">
            <div class="exam_timer_div">
              <div class="exam_timer_child animation_border blink_me">
                <img src="assets/img/timer.svg">
                <h3>{{ exam.leftTimeSeconds * 1000 | date:'HH:mm:ss':'UTC' }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">

          <div class="col-md-9 col-lg-8">
            <div class="exam_qus_box">
              <div class="exam_q_box" *ngIf="questionType == 'question'">
                <ng-container *ngFor='let question of liveQuestion'>
                  <div class="eb_exam_title">
                    <table class="exam_title_table width100">
                      <tr>
                        <td class="q_t_count"> <button class="exam_qus_main_count">{{buttonValue + 1}}</button> </td>
                        <td class="q_text_td" *ngIf='question.title'> <span class="medium q_text">
                            <p [innerHTML]="question.title"></p>
                          </span> </td>
                      </tr>
                    </table>
                  </div>
                  <div class="eb_exam_options mt20">
                    <table class="eb_exam_options_table ">
                      <tr *ngFor='let quest of question.options;let i=index' id="ques_button_{{i}}">
                        <td>
                          <span class="medium f18">{{alphabetIndex(i)}}</span>
                        </td>
                        <td>
                          <mat-radio-group aria-labelledby="example-radio-group-label" (change)=questionAnswer($event)
                            class="example-radio-group" [(ngModel)]="question.user_answer">
                            <mat-radio-button class="mata-radio-button" [value]="quest.id">
                              {{ quest.value }}
                            </mat-radio-button>
                          </mat-radio-group>
                        </td>
                      </tr>
                    </table>
                  </div>


                  <div class="exam_hint_anser mt20">
                    <table class="exam_hint_and_ans  width100">
                      <tr>
                        <td></td>
                        <td class="tr">
                          <button class="button_s6" (click)="clearAnswer(question)">Clear Selection</button>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div class="exam_hint_anser mt20">
                    <table class="exam_hint_and_ans width100">
                      <tr>
                        <td class="width20p"><span class="medium">HINT: </span></td>
                        <td class="pl10">{{question.answer_hint}}</td>
                      </tr>
                    </table>
                  </div>
                </ng-container>
              </div>


              <ng-container *ngIf="questionType == 'result'">
                <div class="exam_q_box">
                  <ng-container *ngFor='let question of liveQuestion'>
                    <div class="eb_exam_title">
                      <table class="exam_title_table">
                        <tr>
                          <td class="q_t_count"> <button class="exam_qus_main_count">{{buttonValue + 1}}</button> </td>
                          <td class="q_text_td" *ngIf='question.title'>
                            <span class="medium q_text">
                              <p [innerHTML]="question.title"></p>
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="eb_exam_options mt20">
                      <table class="eb_exam_options_table answer_table">
                        <tr class="wrong" *ngFor='let quest of question.options;let i=index' id="ques_button_{{i}}">
                          <td *ngIf="question.answer == quest.id">
                            <img src="assets/img/circle_check.svg">
                          </td>
                          <td *ngIf="question.answer != quest.id">

                          </td>
                          <td>
                            <span class="medium f18">{{alphabetIndex(i)}}</span>
                          </td>
                          <td>
                            <span class="correct" *ngIf="question.user_answer == quest.id"> {{ quest.value }}</span>
                            <span *ngIf="question.user_answer != quest.id"> {{ quest.value }}</span>
                          </td>
                        </tr>

                      </table>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>

            <div class="exam_box_actions mt20 mb50">
              <div class="row">
                <div class="col-md-6 col-xs-6  tl">
                  <button class="badge_2" *ngIf='isPrevious' (click)="previousAction()">Previous</button>
                </div>
                <div class="col-md-6 col-xs-6  tr">
                  <button class="badge_2" *ngIf='!isSubmit' (click)="nextAction()">Next</button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-lg-4">
            <div class="exam_left_q_numbers">
              <div class="eleft_tabs" *ngIf="questionType == 'question'">
                <div class="e_left_table">
                  <button class="priamry all active" (click)="reviewAnswerFlag='all'">All</button>
                  <button class="priamry correct" (click)="reviewAnswerFlag='attended'">Attended</button>
                  <button class="priamry skipped" (click)="reviewAnswerFlag='skipped'">Skipped</button>
                </div>
              </div>
              <div class="eleft_tabs" *ngIf="questionType == 'result'">
                <div class="e_left_table">
                  <button class="priamry all active" (click)="answerKeyFlag='all'">All</button>
                  <button class="priamry correct" (click)="answerKeyFlag='correct'">Correct</button>
                  <button class="priamry wrong" (click)="answerKeyFlag='wrong'">Wrong</button>
                  <button class="priamry skipped" (click)="answerKeyFlag='skipped'">Skipped</button>
                  <button class="priamry correct" (click)="answerKeyFlag='answered'">Answered</button>
                </div>
              </div>
              <div class="eleft_tab_result mt10" *ngIf="questionType == 'question'">
                <ng-container *ngFor="let quest of exam.questions; let i = index">
                  <button class="ans_key_count all"
                    *ngIf="reviewAnswerFlag == 'all' && (quest.user_answer || quest.user_answer != null)"
                    (click)='changeQuestion(i)'>
                    {{ i+1 }} </button>
                  <button class="ans_key_count skipped"
                    *ngIf="reviewAnswerFlag == 'all' && (!quest.user_answer || quest.user_answer == null)"
                    (click)='changeQuestion(i)'>
                    {{ i+1 }} </button>
                  <button class="ans_key_count correct" (click)='changeQuestion(i)'
                    *ngIf="reviewAnswerFlag == 'attended' && quest.user_answer"> {{ i+1 }} </button>
                  <button class="ans_key_count skipped" (click)='changeQuestion(i)'
                    *ngIf="reviewAnswerFlag == 'skipped' && (!quest.user_answer || quest.user_answer == null)">
                    {{ i+1 }} </button>
                </ng-container>
              </div>

              <div class="eleft_tab_result mt10" *ngIf="questionType == 'result'">
                <ng-container *ngFor="let quest of exam.questions; let i = index">
                  <button class="ans_key_count all"
                    *ngIf="answerKeyFlag == 'all' && (quest.user_answer || quest.user_answer != null)"
                    (click)='changeQuestion(i)'>{{ i+1 }}
                  </button>
                  <button class="ans_key_count skipped"
                    *ngIf="reviewAnswerFlag == 'all' && (!quest.user_answer || quest.user_answer == null)"
                    (click)='changeQuestion(i)'>
                    {{ i+1 }} </button>
                  <button class="ans_key_count correct" (click)='changeQuestion(i)'
                    *ngIf="answerKeyFlag == 'answered' && quest.user_answer && quest.user_answer !='NA'"> {{ i+1 }}
                  </button>
                  <button class="ans_key_count wrong" (click)='changeQuestion(i)'
                    *ngIf="answerKeyFlag == 'skipped' && (!quest.user_answer || quest.user_answer==0 || quest.user_answer == 'NA')">
                    {{ i+1 }} </button>
                  <button class="ans_key_count skipped" (click)='changeQuestion(i)'
                    *ngIf="answerKeyFlag == 'correct' && quest.user_answer && quest.answer==quest.user_answer">
                    {{ i+1 }} </button>
                  <button class="ans_key_count correct" (click)='changeQuestion(i)'
                    *ngIf="answerKeyFlag == 'wrong' && (quest.user_answer && quest.user_answer !='NA' && quest.answer != quest.user_answer)">
                    {{ i+1 }} </button>
                </ng-container>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-7 col-sm-12 col-xs-12 col-lg-12 mt50 tl">
        <button class="button_s1" *ngIf='exam.leftTimeSeconds && questionType== "question"'
          (click)="submitEvent()">Submit Exam</button>
        <button class="button_s1" *ngIf='!exam.leftTimeSeconds && questionType== "result"'
          [routerLink]="['/exam-result',id]">View Result</button>
      </div>
    </div>

    <div class="row mt50">
      <div class="col-md-12 tl">
        <h2 class="mb20 mt0">Related Exams</h2>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4 mb30" *ngFor="let item of [].constructor(3); let i = index">
            <div class="exam_list_selection">
              <div class="exam_list_heading mb5">
                <h3 class="medium mt0 mb0">Exam Linear Equations & Algebra</h3>
              </div>
              <div class="exam_list_duration">
                <span>Duration: <span class="primary">2 hrs</span> </span>
                <table class="mt20">
                  <tr>
                    <td class="pr20 tl"><span class="medium">50 Min | 30 Que | 60 Marks</span></td>
                    <td class="tr"><button class="button_s6">Start Exam</button></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-subscription-box></app-subscription-box>
<app-footer></app-footer>