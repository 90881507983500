import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(public api: Api) { }
  getMySubscription() {
    let endpoint = 'customer/packages/my_subscription';
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  getAllPackages(course_id:any=null) {
    let endpoint = 'packages';
    if(course_id != null) endpoint += '?course_id='+course_id
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  getAllPlanDetails(_plan_id) {
    let endpoint = 'packages/view/' + _plan_id;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  placePlanOrder(_data) {
    let endpoint = 'customer/packages/subscription';
    return this
      .api
      .post(endpoint, _data)
      .pipe(map(resp => resp.json()));
  }
  applyPromoCode(_data) {
    let endpoint = 'couponcode/validate';
    return this
      .api
      .post(endpoint, _data)
      .pipe(map(resp => resp.json()));
  }
  submitPurchase(_data) {
    let endpoint = 'packages/purchase';
    return this
      .api
      .post(endpoint, _data)
      .pipe(map(resp => resp.json()));
  }
  verifyPayment(_data) {
    let endpoint = 'packages/verify-payment';
    return this
      .api
      .post(endpoint, _data)
      .pipe(map(resp => resp.json()));
  }

}
