<app-side-nav></app-side-nav>
<section>
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="detail_page_date_section tl mb20">
          <span>03 Feb 2020 07 : 30PM</span>
        </div>
        <div class="div_left_video_box">
          <div class="canvas_video_div">
            <div class="div_video_canvas">
              <!-- mat box -->
              <div class="mat_big_box">
                <div class="matbox_inner_child">
                  <div class="mat_big_box_file cell">
                    <img src="assets/img/pdf.svg">
                  </div>
                  <div class="mat_big_box_right_text tl cell">
                    <h3 class="mt0 mb10">Linear Equations & Algebra</h3>
                    <p class="m0 mb20">Quiere la boca exhausta vid, kiwi, piña y fugaz jamón. Fabio me exige, sin tapujos, que añada cerveza al whisky. Jovencillo emponzoñado de whisky, ¡qué figurota exhibes! La cigüeña tocaba cada vez mejor el saxofón y el búho pedía kiwi y queso. El jefe buscó el éxtasis en un imprevisto baño de whisky y gozó como un duque. Read More ….
                      </p>
                    <button class="button_s6">View</button>
                  </div>
                </div>
              </div>
              <!-- mat box -->
            </div>
          </div>
          <div class="mat_text_heading mt50">
            <h2 class="mb20 mt0">Basic Materials</h2>
          </div>
          <div class="related_video_detail mt20">
            <!-- looooooooooooop -->
            <div class="rel_video_boxes">
              <div class="row">
                <div class="col-md-6 mb10" *ngFor="let item of [].constructor(2); let i = index">
                  <div class="rel_mat_box_main">
                    <div class="rel_mat_box_child">
                      <div class="rel_mat_box_text_box">
                        <h3 class="medium block mt0 mb10">Logic Techniques </h3>
                        <!-- <p class="m0 f12 mb20">But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was.</p> -->
                      </div>
                      <div class="rel_mat_box_actions">
                        <div class="rel_mat_file_icon cell tl">
                          <img src="assets/img/pdf.svg">
                        </div>
                        <div class="rel_mat_date_inner cell tl">
                          <span class="f12">03 Feb 2020</span>
                        </div>
                        <div class="rel_mat_button_inner cell tr">
                          <button class="button_s6">Free</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-5">
        <div class="div_right_video_box">
          <div class="rel_video_right">
            <div class="rel_video_title">
              <h2 class="mb20 mt0">Linear Equations & Algebra</h2>
            </div>
            <div class="rel_video_boxes">
              <div class="rel_vide_box mb20" *ngFor="let item of [].constructor(5); let i = index">
                <div class="rel_v_img cell">
                  <img src="assets/img/video_small_thumb.svg">
                </div>
                <div class="rel_v_text cell">
                  <h3 class="block m0 medium">Basic Fundamentals </h3>
                  <span class="f12 block">Application of different equation and easy methods finding solutions </span>
                </div>
                <div class="rel_v_play cell">
                  <img src="assets/img/rel_play.svg">
                </div>
                <!-- <div class="rel_v_save cell">
                  <img src="assets/img/bookmark.svg">
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt50">
      <div class="col-md-12 tl">
        <h2 class="mb20 mt0">Basic Materials</h2>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4" *ngFor="let item of [].constructor(3); let i = index" routerLink="/video-detail">
            <div class="video_box" routerLink="/video-list">
              <div class="play_thumb">
                <img src="assets/img/play_icon.svg">
              </div>
              <div class="crs_item_img">
                <img src="assets/img/video_thumb.svg">
              </div>
              <div class="video_text">
                <div class="row">
                  <div class="col-md-7 tl">
                    <h3 class="block m0">Accounting</h3>
                    <span class="medium block f12 mt5">Basic Fundamentals</span>
                  </div>
                  <div class="col-md-5 tr">
                    <button class="button_s5">Free</button>
                    <span class="block f12 white mt5">Module</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-subscription-box></app-subscription-box>
<app-footer></app-footer>