import { NgModule } from '@angular/core';
import { MyDurationPipe } from './myDuration.pipe';
// import { IonicModule } from '@ionic/angular';
import { TimeagoPipe } from './timeagoPipe.pipe';
import { SafePipe } from './safeUrl.pipe';

@NgModule({
    declarations: [MyDurationPipe,TimeagoPipe,SafePipe],
    imports: [],
    exports: [MyDurationPipe,TimeagoPipe,SafePipe]
})
export class PipesModule { }