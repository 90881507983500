import { Component, OnInit, ElementRef } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(myElement: ElementRef) {
    
   }

  ngOnInit(): void {
    this.gotoTop();
  }
  gotoTop() {
    document.body.scrollTop = 0

    setTimeout(function(){
      if (window.location.href.indexOf("privacy") > -1) {
        $("html, body").animate({ scrollTop: $("#privacy").offset().top }, 1500);
        
      }
      if (window.location.href.indexOf("refund-policy") > -1) {
        $("html, body").animate({ scrollTop: $("#refund-policy").offset().top }, 1500);
        
      }
     }, 300);

    
  }

}
