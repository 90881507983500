<div class="fixed_bottom_nav">
  <div class="fxd_ul">
    <a  *ngIf="hasLOggedIn=='false'" routerLink="/home" (click)="common.sideActive='home';changeClass()"
      [ngClass]="{'active':common.sideActive=='home'}">
      <a  *ngIf="hasLOggedIn=='true'" routerLink="/select-course" (click)="common.sideActive='home';changeClass()"
      [ngClass]="{'active':common.sideActive=='home'}">
      <img src="assets/img/home_icon.svg" title="Home">
      <span class="block">Home</span>
      </a>
    </a>
    <a [routerLink]="['/video-list','video']" title="Videos" [ngClass]="{'active':common.sideActive=='video'}"
      (click)="common.sideActive='video';changeClass()">
      <img src="assets/img/video_icon.svg">
      <span class="block">Videos</span>
    </a>
    <a [routerLink]="['/video-list','pdf']" title="Materials" [ngClass]="{'active':common.sideActive=='pdf'}"
      (click)="common.sideActive='pdf';changeClass()">
      <img src="assets/img/book.svg">
      <span class="block">Materials</span>
    </a>
    <a [routerLink]="['/video-list','mock']" title="Exams" [ngClass]="{'active':common.sideActive=='exam'}"
      (click)="common.sideActive='exam';changeClass()">
      <img src="assets/img/graduation.svg">
      <span class="block">Exams</span>
    </a>
    <a class="user_imp" routerLink="/profile" title="Profile">
      <img src="assets/img/user_s.svg" title="User">
      <span class="block">Profile</span>
    </a>
  </div>
</div>