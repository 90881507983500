<app-header></app-header>
<section class="home_course_tab mt50">
    <div class="container">
        <div class="row mb20">
            <div class="col-md-12 tc mt20">
                <h1 class="border_heading_bottom">Placement Cell</h1>
            </div>
            <div class="col-md-12 tc mt20">
                <div class="row">

                    <ng-container *ngIf="loader">
                        <div class="col-md-6 col-12 mb20" *ngFor="let item of [].constructor(10); let i = index">
                            <div class="job_listing_card">
                                <div class="jlc_image">
                                    <div class="square" style="height: 100px;"></div>
                                </div>
                                <div class="jlc_info_div">
                                    <div class="medium block item_ttle">
                                        <div class="square" style="margin-bottom: 10px;"></div>
                                    </div>
                                    <div class="block f12 item_tt_subject">
                                        <div class="square" style="margin-bottom: 10px;"></div>
                                    </div>
                                    <div class="jlc_location  mt10">
                                        <div class="square" style="margin-bottom: 10px;"></div>
                                    </div>
                                    <div class="block ">
                                        <div class="square" style="margin-bottom: 10px;"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>


                    <ng-container *ngIf="!loader">
                        <div class="col-md-6 col-12 mb20" (click)="showInfo()" *ngFor="let data of jobData; let i = index">
                            <div class="job_listing_card">
                                <div class="jlc_image" *ngIf="data?.image">
                                    <img [src]="data.image_url" onError="this.src='assets/img/home_banner.png'">
                                </div>
                                <div class="jlc_info_div">
                                    <div class="medium block item_ttle"> {{data.title}} </div>
                                    <div class="block f12 item_tt_subject">
                                        {{data.description}}
                                    </div>
                                    <div class="jlc_location f12  mt10">
                                        Location: {{data.location}}
                                    </div>
                                    <div class="block f12">Salary: {{data.salary}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
</section>

<div class="mdl_info" *ngIf="showModal" (click)="clossModal()">
    <div class="medium block item_ttle f14">
        <div class="tc m0a">
            <img src="assets/img/mail.svg">
        </div>
        Mail Your Resume To
        <div class="mailTOemp mt5">
            <a class="f18" href="mailto:ocomacc@gmail.com">ocomacc@gmail.com</a>
        </div>
    </div>
</div>

<app-subscription-box></app-subscription-box>
<app-footer></app-footer>
<app-mobile-nav></app-mobile-nav>