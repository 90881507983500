import { MatDialog } from '@angular/material/dialog';
import { SubscriptionService } from './../../providers/subscription.service';
import { Component, OnInit, ChangeDetectorRef, Inject, NgZone, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from 'src/app/providers/common.service';
import { UserService } from 'src/app/providers/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ExternalLibraryService } from '../../providers/util';
import { CategoryService } from 'src/app/providers/category.service';
import { EventPublishService } from 'src/app/providers/event-publish';
import { ProfileService } from 'src/app/providers/profile.service';
// import { LoginActivate } from '../../providers/loginActivate';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ItemService } from '../../providers/item.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { VideoDetailComponent } from '../video-detail/video-detail.component';
import { RegistrationFormComponent } from '../registration-form/registration-form.component';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { SuccessPackageComponent } from '../success-package/success-package.component';
import { ExamService } from 'src/app/providers/exam.service';

declare let Razorpay: any;
declare let RazorpayCheckout: any;
import * as $ from 'jquery'
import { CourseService } from '../../providers/course.service';
import { LoginActivate } from 'src/app/providers/loginActivate';



@Component({
  selector: 'app-course-week',
  templateUrl: './course-week.component.html',
  styleUrls: ['./course-week.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.5s ease-out',
              style({ height: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300, opacity: 1 }),
            animate('0.5s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class CourseWeekComponent implements OnInit {

  loadingTimelines: boolean = false
  loadingTimelinesView: boolean = false
  courseDetails: any = []
  timelineDetails: any = []
  course: any
  length: any;
  loadingExams: any;
  loadingVideos: boolean = false
  itemsList: any = []
  openmodal: boolean = false;
  public promoCodeForm: FormGroup;
  loadingPackage: boolean = false
  loadingCourses: boolean = false
  packageList: any = []
  buyPackage: boolean = false
  promoCode: any = null
  isSubmit: boolean = false
  response;
  razorpayResponse;
  showModal = false
  userDetails: any = []
  priceToPay: any
  paymentDetails: any = []
  courseGroupList: any = []
  isSelectCourse: boolean = false
  flag: boolean = false
  alreadySelectedCourse: any
  loadingCourseGroup: boolean = false
  courseList: any = []
  appliedPromoCode: any = []
  profileData: any = []
  isPromo: boolean = false
  isError: boolean = false
  is_subscribed: any = null
  isProcessing: boolean = false
  appliedCouponAmount = 0
  loadingCourse: boolean = false
  id: any
  page:number;
  type:any='video'
  per_page:number=12;
  rotate:boolean = false;
  isLoading:boolean=false;
  courseSubjects:any;

  constructor(public subscription: SubscriptionService, public common: CommonService
    , public user: UserService, public router: Router,
    public formBuilder: FormBuilder, public razorpayService: ExternalLibraryService,
    public category: CategoryService, public events: EventPublishService,
    public cd: ChangeDetectorRef, public profile: ProfileService,
    public itemService: ItemService, public datePipe: DatePipe, public dialog: MatDialog,
    public zone: NgZone, public dailog: MatDialog, public exam: ExamService,public CourseService:CourseService,public loginActivate:LoginActivate) {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    if (localStorage.getItem(this.common.COURSE)) {

      this.course = JSON.parse(localStorage.getItem(this.common.COURSE))
      this.loadTimelines(this.course._id)
      this.getCourseSubject();
      this.loadItems('video')
      this.loadCourse(this.common.selectedGroup)
    } else if (localStorage.getItem('course_id_notification')) {
      this.id = localStorage.getItem('course_id_notification')
      this.loadTimelines(this.id)
      this.loadItems('video')
      this.loadCourse(this.common.selectedGroup)

    }

    this.promoCodeForm = this.formBuilder.group({
      coupon_code: ['', Validators.required],
    });

    if (this.course) {
      this.isSelectCourse = true
      this.loadPackages(this.course._id)
    }

    this.events.getObservable().subscribe((data) => {
      if (data == 'uexUser:courseChanges') {
        this.zone.run(() => {
          this.loadTimelines(this.course._id)
          this.loadItems('video')
          this.loadCourse(this.common.selectedGroup)
          this.buyPackage = false
        })
      }
    })


  }

  package: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    autoWidth: false,
    autoHeight: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
  }

  ngOnInit(): void {
    this.getCourseSubject();
    this.razorpayService
      .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
      .subscribe();

  }

  RAZORPAY_OPTIONS = {
    "key": "",
    "amount": '',
    "name": "",
    "order_id": "",
    "description": "",
    "image": "",
    "prefill": {
      "name": '',
      "email": '',
      "contact": '',
      "method": ""
    },
    "modal": {},
    "theme": {
      "color": ""
    }
  };

  get epf() { return this.promoCodeForm.controls; }

  buyNow(data) {
    const dialogRef = this.dailog.open(RegistrationFormComponent, {
      panelClass: "full_modal",
      // data:{
      // id:data
      // }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result.data.success == true) {
        this.buyPackage = true;
        this.common.selectedPackage = data
        this.priceToPay = this.common.selectedPackage.price

      }

    });

  }

  loadPackages(id) {
    this.loadingPackage = true;
    this
      .subscription
      .getAllPackages(id)
      .subscribe(res => {

        this.packageList = res.data;

      }, err => {
        this.loadingPackage = false;

        this.common.processError(err);
        return false;
      });
  }

  submitPromoCode() {
    if (this.user.hasLoggedIn) {
      this.isSubmit = true
      let data = {
        package_id: this.common.selectedPackage._id,
        coupon_code: (this.epf.coupon_code.value).toUpperCase(),
      }
      this.subscription.applyPromoCode(data).subscribe(res => {

        if (res.success == true) {
          this.appliedPromoCode = res.data
          this.isPromo = true
          this.isError = false
          this.common.showToastSuccess("Coupon Applied")
        } else {

          this.isError = true
          // this.common.presentToast(res.message)
        }
      }, err => {
        this.isError = true
        // this.common.processError(err)

      });
    } else {
      // this.router.navigate(['/auth/login'])
    }
  }

  appliedValue(appliedPromoCode) {
    let value = 0
    value = appliedPromoCode.coupon_value / 100 * this.common.selectedPackage.price
    if (value <= appliedPromoCode.coupon_max_amount) {
      return this.common.selectedPackage.price - value
    } else {
      return this.common.selectedPackage.price - appliedPromoCode.coupon_max_amount
    }
  }

  couponCodeCancelled() {
    this.isPromo = false
  }

  appliedAmount(appliedPromoCode) {
    this.appliedCouponAmount = appliedPromoCode.coupon_value / 100 * this.common.selectedPackage.price
    return this.appliedCouponAmount
  }

  public proceed() {
    this.isLoading=true;
    if (this.user.hasLoggedIn) {
      this.isProcessing = true
      let data = {
        package_id: this.common.selectedPackage._id,
        coupon_code: (this.epf.coupon_code.value).toUpperCase()
      }
      this.subscription.submitPurchase(data).subscribe(res => {
        if (res.success == true) {
          this.isLoading=false;
          this.paymentDetails = res.data
          if (this.paymentDetails.payment_status != "success") {
            this.RAZORPAY_OPTIONS.amount = this.paymentDetails.razor_data.amount;
            this.RAZORPAY_OPTIONS.description = this.paymentDetails.razor_data.description;
            this.RAZORPAY_OPTIONS.key = this.paymentDetails.razor_data.key;
            this.RAZORPAY_OPTIONS.order_id = this.paymentDetails.razor_data.order_id;
            this.RAZORPAY_OPTIONS.name = this.paymentDetails.razor_data.name;
            this.RAZORPAY_OPTIONS.prefill.name = this.paymentDetails.razor_data.prefill.name;
            this.RAZORPAY_OPTIONS.prefill.email = this.paymentDetails.razor_data.prefill.email;
            this.RAZORPAY_OPTIONS.prefill.contact = this.paymentDetails.razor_data.prefill.contact;
            this.RAZORPAY_OPTIONS.theme.color = this.paymentDetails.razor_data.theme.color;
            this.RAZORPAY_OPTIONS.image = this.paymentDetails.razor_data.image;
            // binding this object to both success and dismiss handler
            this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);
            let razorpay = new Razorpay(this.RAZORPAY_OPTIONS)
            razorpay.open();
          } else {
            localStorage.setItem(this.common.SELECTED_COURSE, JSON.stringify(this.course))
            localStorage.removeItem(this.common.IS_TAB_METHOD)
            this.common.selectedCourse = this.course
            this.alreadySelectedCourse = this.course.title
            this.events.publishSomeData(this.common.COURSE_CHANGES);
            this.onSaveProfile(this.course._id)
            this.isProcessing = false
            // this.goToPurchaseComplete(this.paymentDetails.amount, this.course)
          }
        } else {
          this.isProcessing = false
          this.common.showToastError(res.message)
        }

      }, err => {
        this.isProcessing = false
        this.common.processError(err)
      });
    } else {
      // this.login.onLogin()
    }
  }

  public razorPaySuccessHandler(response) {
    this.razorpayResponse = `Razorpay Response`;
    let data = {
      payment_id: this.paymentDetails._id,
      razor_payment_id: response.razorpay_payment_id,
    }
    this.subscription.verifyPayment(data).subscribe(res => {
      if (res.success == true) {

   this.getCourse(res.data.course_id,res)
        // this.goToPurchaseComplete(this.paymentDetails.amount, this.course)
      } else {
        this.isProcessing = false
        this.common.showToastError(res.message)
      }
    }, err => {
      this.isProcessing = false
      this.common.processError(err)
    });
    this.isProcessing = false
    this.showModal = true;
    this.cd.detectChanges()
  }

  onSaveProfile(id) {
    var data = {
      customer_name: this.userDetails.customer_name,
      customer_mobile: this.userDetails.customer_mobile,
      customer_email: this.userDetails.customer_email,
      current_course_id: id
    };
    this
      .profile
      .updateUserDetails(data)
      .subscribe(res => {
        if (res.success == true) {
          this.events.publishSomeData(this.common.USER_PROFILE_UPDATED);
        }
      }, err => {

        this.common.processError(err);
        return false;
      });
  }



  closeModal() {
    this.isSubmit = false
    this.isError = false
    this.promoCodeForm.reset()
  }

  loadTimelines(id) {
    this.loadingTimelines = true;
    this
      .itemService
      .getTimeLineItems(id)
      .subscribe(res => {

        if (res.success) {
          this.courseDetails = res.data;
          console.log(this.courseDetails )
          localStorage.setItem(this.common.TIME_LINE, JSON.stringify(this.courseDetails.timelines))
          this.rotate = this.courseDetails.timelines[0]._id;
          this.getHeight();

        }
        this.loadingTimelines = false;
      }, err => {
        this.loadingTimelines = false;

        this.common.processError(err);
        return false;
      });
  }

  loadTimelinesView(id) {
    this.loadingTimelinesView = true;
    this
      .itemService
      .getTimeLineView(4, 4)
      .subscribe(res => {

        if (res.success) {
          this.timelineDetails = res.data[0];
        }
      }, err => {
        this.loadingTimelinesView = false;

        this.common.processError(err);
        return false;
      });
  }

  onClickCourseDetails() {
    localStorage.removeItem(this.common.IS_TAB_METHOD)
    if (!this.common.isTabActive) this.events.publishSomeData(this.common.TAB_CHANGES);
    this.common.isTabActive = false
    localStorage.setItem(this.common.SELECTED_COURSE, JSON.stringify(this.course))
    this.common.selectedCourse = this.course
    this.events.publishSomeData(this.common.COURSE_CHANGES);
    this.common.type = ''

    let data = {
      week_id: 0,
      day_id: 0,
      title: this.course.title,
      is_show_week: true
    }
    this.router.navigate(['/mat-box', data])
  }

  onClickWeekCourseDetails(day: any = null, week: any = null) {
    localStorage.removeItem(this.common.IS_TAB_METHOD)
    console.log(week.description)
    if(week.description){
      localStorage.setItem('description',week.description)
    }

    localStorage.setItem(this.common.SELECTED_COURSE, JSON.stringify(this.course))
    this.common.selectedCourse = this.course
    this.common.type = ''
    let data = {
      day_id: day ? day._id : 0,
      week_id: week.week_id,
      title: day != null ? day.title : this.timelineDetails.title,
      weekTitle: week.title,
      dayTitle: day.title,
      day: day.days,
      week: week.week,
      is_show_week: false,
    }
    this.router.navigate(['/video-list', data])
  }


  openVideoList(id){
    let data={
      sub_id:id
    }
    this.router.navigate(['/video-list', data])
  }
  // async subscribe() {
  //   const dialogRef = this.dialog.open(PackageListComponent, {
  //     data: {
  //       flag: true,
  //       course: this.course,
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     
  //     this.loadTimelines(this.course._id)
  //   });
  // }

  isDateActive(timeLine) {
    let startDate = moment(timeLine.start_date);
    let endDate = moment(timeLine.end_date);
    let toDate = moment(new Date())
    if (startDate <= toDate && toDate <= endDate) {
      return true
    } else {
      return false
    }
  }

  onClickItem(value) {
    this.itemsList = []
    this.page = 1
    this.type = value
    this.loadItems(value)
  }


  loadItems(type: any = null, sub_id: any = null, week_id: any = null, day_id: any = null) {

    this.loadingVideos = true;
    let course_id = null
    if (this.course && this.course != null) {
      course_id = this.course._id
    } else {
      course_id = this.id
    }
    if (type != 'mock') {
      this.itemService.getItems(3, 1, course_id, sub_id, week_id, day_id, type)
        .subscribe(res => {
          if (res.success == true) {

            res.data.data.forEach(element => {
              if (!this.itemsList.find(x => x._id == element._id)) {
                this.itemsList.push(element);

              }
            });
          }
          this.loadingVideos = false;
        }, err => {
          this.loadingVideos = false;

          this.common.processError(err);
          return false;
        });

    }
    if (type == 'mock') {
      this.loadingExams = true;
      this.exam.getExam(this.page, this.per_page, sub_id, course_id, week_id, day_id)
        .subscribe(res => {
          if (res.success == true) {
            this.length = res.data.total
            res.data.data.forEach(element => {
              if (!this.itemsList.find(x => x._id == element._id)) {
                this.itemsList.push(element);

              }
            });
          }
          this.loadingVideos = false;
        }, err => {
          this.loadingVideos = false;

          this.common.processError(err);
          return false;
        });


    }
  }

  onClickMaterialView(type, value) {
    var is_view :boolean=false

    if(!this.user.hasLoggedIn){
      if(this.common.played_video.video_count >= 3){
        console.log(this.common.played_video.ids.find(x=>x._id == value._id ) )
        if(this.common.played_video.ids.find(x=>x == value._id )){
          is_view=true
        }else{
          is_view=false
          this.loginActivate.onLogin()
        }
      }else{
        is_view=true
      }
    }else{
      is_view=true
    }

      if(is_view){
    this.common.type = type
    if ((!value.need_to_subscribe || value.need_to_subscribe) && value.type =='free') {
      this.common.type = type
      this.materialView(type, value)
    }else if(value.need_to_subscribe && value.type =='premium'){
      this.common.showToast("Please Subscribe to Continue")
    }else if(!value.need_to_subscribe && value.type =='premium'){
      this.common.type = type
      this.materialView(type, value)
     }
    }
    // }
    // else {
    //   if (value.need_to_subscribe) {
    //     this.common.showToast("Please Subscribe to Continue")
    //   } else {
    //     if(value.published_on !=null){
    //       this.common.showToast("Content not Available Now, Please Check On " + this.datePipe.transform(value.published_on, 'dd-MM-yyyy'))
    //     }
    //     if(!value.published_on ==null){
    //       this.common.showToast("Content not Available Now, Please Check On ")
    //     }
    //   }
    // }

  }
  materialView(type, value) {
    let tempType = type
    console.log(type)
    const dialogRef = this.dialog.open(VideoDetailComponent, {
      panelClass: "full_modal",
      // closeOnNavigation: false,
      data: {
        id: value._id,
        sub_id: value.subject_id ? value.subject_id : null,
        week_id: value.week_id ? value.week_id : null,
        day_id: value.day_id ? value.day_id : null,
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      this.itemsList = []

      this.loadItems(tempType)
    });
  }

  loadCourse(id) {
    this.loadingCourse = true
    this
      .category.getCourseGroupById(id, 0)
      .subscribe(res => {
        if (res.success == true) {
          this.courseList = res.data;
          
        }
        this.loadingCourse = false
      }, err => {

        this.loadingCourse = false
        return false;
      });
  }

  onSelectCourse(course) {
    localStorage.setItem(this.common.COURSE, JSON.stringify(course))
    this.events.publishSomeData(this.common.COURSE_CHANGES);
    if (localStorage.getItem(this.common.COURSE)) {
      this.course = JSON.parse(localStorage.getItem(this.common.COURSE))
      this.loadTimelines(this.course._id)
      this.loadItems('video')
      this.loadCourse(this.common.selectedGroup)
    }
    if (this.course) {
      this.isSelectCourse = true
      this.loadPackages(this.course._id)
    }

  }
  weekDays(id) {
    if (id == this.rotate) {
      this.rotate = null;
    } else {
      this.rotate = id;

    }

  }

getCourse(id,data){
  this.itemService.getTimeLineItems(id).subscribe(resp=>{
    if(resp.success==true){
      this.course=resp.data
      localStorage.setItem(this.common.SELECTED_COURSE, JSON.stringify(this.course))
      localStorage.removeItem(this.common.IS_TAB_METHOD)
      this.common.selectedCourse = this.course
      this.alreadySelectedCourse = this.course.title
      this.events.publishSomeData(this.common.COURSE_CHANGES);
      this.isProcessing = false
      const dialogRef = this.dialog.open(SuccessPackageComponent, {
        panelClass: "sign_in_box",
        // closeOnNavigation: true,
        data:data,
      })
    }
  })
}

  onClickView(value) {
    if (value.completed_on && value.completed_on != null) {
      this.router.navigate(['/exam-result', value.result_id])
    } else {
      this.router.navigate(['/exam-view', value._id])
    }
  }


  ngAfterViewInit() {

  }

  getHeight() {
    setTimeout(function () {
      var h = $(".trim_data").height();
      if (h >= 200) {
        $(".trim_data").height(200);
        $(".read_more").css("display", "block");
      }
    }, 100);
  }
  shoMore(){
    $(".trim_data").css("height", "auto");
    $(".read_more").css("display", "none");
  }


  getCourseSubject(){
    this.CourseService.getSubjectdetails(this.course._id).subscribe(resp=>{
      if(resp.success){
       this.courseSubjects=resp.data;
      }
    })
  }
}
