<header>
  <nav class="navbar _uex_nav">
    <div class="container">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar"
          (click)="showNav=!showNav">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" routerLink="/home">
          <img class="main_logo_header" src="assets/img/logo_full.svg">
        </a>
      </div>
      <div class="collapse navbar-collapse" id="myNavbar" *ngIf="showNav">
        <ul class="nav navbar-nav">
          <li class="active">
            <a>
              <button class="btn button_s2" routerLink="/select-course">
                {{common.selectedCourse?common.selectedCourse.title:'Choose Course'}}
              </button>
            </a>
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li class="hide"><a href="#">
              <img class="main_logo_header_download_app" src="assets/img/ios_icon.png">
            </a></li>
          <li class="hide"><a href="#">
              <img class="main_logo_header_download_app" src="assets/img/gplay_icon.png">
            </a></li>
          <li>
            <a>
              <a (click)="onLogin()" *ngIf="!user.hasLoggedIn">
                <button class="btn button_s1">Signin</button>
              </a>
              <a (click)="onLogout()" *ngIf="user.hasLoggedIn">
                <img class="main_logo_header_download_app" src="assets/img/user_color.svg">
              </a>
            </a>
          </li>
        </ul>
      </div>
      <div class="collapse navbar-collapse hide_mob" id="myNavbar">
        <ul class="nav navbar-nav">
          <li class="active">
            <a>
              <button class="btn button_s2" routerLink="/select-course">
                {{common.selectedCourse?common.selectedCourse.title:'Choose Course'}}</button>
            </a>
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li class="hide"><a href="#">
              <img class="main_logo_header_download_app" src="assets/img/ios_icon.png">
            </a></li>
          <li class="hide"><a href="#">
              <img class="main_logo_header_download_app" src="assets/img/gplay_icon.png">
            </a></li>
          <li>
            <a (click)="onLogin()" *ngIf="!user.hasLoggedIn">
              <button class="btn button_s1">Signin</button>
            </a>
            <a routerLink="/profile" *ngIf="user.hasLoggedIn && user_Details && !user_Details.customer_name ">
              <img class="main_logo_header_download_app" src="assets/img/user_color.svg">
            </a>
            <a routerLink="/profile" class="c_l_tag" [ngClass]="{'isPrint': isprint === true}" (mouseenter) ="mouseEnter(user_Details.customer_name) "  (mouseleave) ="mouseLeave(user_Details.customer_name)" [attr.data-id]="user_Details.customer_name" *ngIf="user.hasLoggedIn && user_Details && user_Details.customer_name ">
              <!-- <img class="main_logo_header_download_app" *ngIf="user_Details.image_url" src="{{user_Details.image_url}}"> -->
              <h2 class="customer_letter" *ngIf="!isprint">{{getCustomerName(user_Details.customer_name)}}</h2>
              <h2 class="customer_letter isprint_h" *ngIf="isprint" >{{user_Details.customer_name}}</h2>
            </a>

            <!-- <a routerLink="/profile" class="c_l_tag" [ngClass]="{'isPrint': isprint === true}" (mouseenter) ="mouseEnter(user_Details.customer_name) "  (mouseleave) ="mouseLeave(user_Details.customer_name)" [attr.data-id]="user_Details.customer_name" *ngIf="user.hasLoggedIn && user_Details && user_Details.customer_name && user_Details.image_url">
              <img class="main_logo_header_download_app" *ngIf="user_Details.image_url" src="{{user_Details.image_url}}">
            </a> -->
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>