import { SubscriptionService } from './../../providers/subscription.service';
import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { CommonService } from 'src/app/providers/common.service';
import { UserService } from 'src/app/providers/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ExternalLibraryService } from '../../providers/util';
import { CategoryService } from 'src/app/providers/category.service';
import { EventPublishService } from 'src/app/providers/event-publish';
import { ProfileService } from 'src/app/providers/profile.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
// import { LoginActivate } from '../../providers/loginActivate';
import { OwlOptions } from 'ngx-owl-carousel-o';
declare let Razorpay: any;
declare let RazorpayCheckout: any;
@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.scss']
})
export class PackageListComponent implements OnInit {
  openmodal: boolean = false;
  public promoCodeForm: FormGroup;
  loadingPackage: boolean = false
  loadingCourses: boolean = false
  packageList: any = []
  buyPackage: boolean = false
  promoCode: any = null
  isSubmit: boolean = false
  response;
  razorpayResponse;
  showModal = false
  userDetails: any = []
  priceToPay: any
  paymentDetails: any = []
  courseGroupList: any = []
  isSelectCourse: boolean = false
  flag: boolean = false
  alreadySelectedCourse: any
  loadingCourseGroup: boolean = false
  course: any
  courseList: any = []
  appliedPromoCode: any = []
  profileData: any = []
  isPromo: boolean = false
  isError: boolean = false
  is_subscribed: any = null
  isProcessing: boolean = false
  appliedCouponAmount = 0

  constructor(public subscription: SubscriptionService, public common: CommonService
    , public user: UserService, public router: Router,
    public formBuilder: FormBuilder, public razorpayService: ExternalLibraryService,
    public category: CategoryService, public events: EventPublishService,
    public cd: ChangeDetectorRef, public profile: ProfileService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PackageListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {

    this.router.events.subscribe((evt) => {
      console.log(evt)
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    this.promoCodeForm = this.formBuilder.group({
      coupon_code: ['', Validators.required],
    });

    if (data.flag) {
      this.flag = data.flag
    }
    if (data.course) {
      this.course = data.course
    }
    if (this.flag && this.course) {
      this.isSelectCourse = true
      this.loadPackages(this.course._id)
    }
    if (data.course_id && data.course_id != null) {
      this.isSelectCourse = true
      this.loadPackages(data.course_id)
      this.courseView(data.course_id)
    }
    this.loadCourseGroup()
    this.getProfile()
  }


  package: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
  }

  ngOnInit() {
    this.razorpayService
      .lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js')
      .subscribe();
  }
  RAZORPAY_OPTIONS = {
    "key": "",
    "amount": '',
    "name": "",
    "order_id": "",
    "description": "",
    "image": "",
    "prefill": {
      "name": '',
      "email": '',
      "contact": '',
      "method": ""
    },
    "modal": {},
    "theme": {
      "color": ""
    }
  };

  get epf() { return this.promoCodeForm.controls; }

  buyNow(data) {

    this.common.selectedPackage = data
    this.priceToPay = this.common.selectedPackage.price
    console.log(this.priceToPay)
  }

  loadCourseGroup() {
    this.loadingCourseGroup = true;
    this
      .category.getAllCourseGroups()
      .subscribe(res => {
        if (res.success == true) {
          this.courseGroupList = res.data;
          this.onClickView(this.courseGroupList[0]._id)
        }
        this.loadingCourseGroup = false;
      }, err => {
        this.loadingCourseGroup = false;
        this.common.processError(err)
        console.error('ERROR', err);
        return false;
      });
  }

  loadPackages(id) {
    this.loadingPackage = true;
    this
      .subscription
      .getAllPackages(id)
      .subscribe(res => {
        console.log(res);
        this.packageList = res.data;
      }, err => {
        this.loadingPackage = false;
        console.error('ERROR', err);
        this.common.processError(err);
        return false;
      });
  }

  submitPromoCode() {
    if (this.user.hasLoggedIn) {
      this.isSubmit = true
      let data = {
        package_id: this.common.selectedPackage._id,
        coupon_code: (this.epf.coupon_code.value).toUpperCase(),
      }
      this.subscription.applyPromoCode(data).subscribe(res => {
        console.log(res)
        if (res.success == true) {
          this.appliedPromoCode = res.data
          this.isPromo = true
          this.isError = false
          this.common.showToastSuccess("Coupon Applied")
        } else {
          console.log(res.message)
          this.isError = true
          // this.common.presentToast(res.message)
        }
      }, err => {
        this.isError = true
        this.common.processError(err)
        console.error('ERROR', err);
      });
    } else {
      this.router.navigate(['/auth/login'])
    }
  }

  couponCodeCancelled() {
    this.isPromo = false

  }

  selectCourse(course) {
    this.course = course
    this.isSelectCourse = true
    this.loadPackages(this.course._id)
  }

  appliedValue(appliedPromoCode) {
    let value = 0
    value = appliedPromoCode.coupon_value / 100 * this.common.selectedPackage.price
    if (value <= appliedPromoCode.coupon_max_amount) {
      return this.common.selectedPackage.price - value
    } else {
      return this.common.selectedPackage.price - appliedPromoCode.coupon_max_amount
    }
  }

  appliedAmount(appliedPromoCode) {
    this.appliedCouponAmount = appliedPromoCode.coupon_value / 100 * this.common.selectedPackage.price
    return this.appliedCouponAmount
  }

  onClickView(id) {
    this.loadingCourses = true
    this
      .category.getCourseGroupById(id, this.is_subscribed = 0)
      .subscribe(res => {
        if (res.success == true) {
          this.courseList = res.data;
        }
        this.loadingCourses = false
      }, err => {
        this.loadingCourses = false
        console.error('ERROR', err);
        return false;
      });
  }

  courseView(id) {
    this
      .category.coursesView(id)
      .subscribe(res => {
        if (res.success == true) {
          this.course = res.data;
        }
      }, err => {
        console.error('ERROR', err);
        return false;
      });
  }

  getProfile() {
    this.profile.getUserDetails()
      .subscribe(res => {
        console.log(res)
        if (res.success == true) {
          this.userDetails = res.data;
        }
      }, err => {
        this.common.processError(err)
        return false;
      });
  }

  public proceed() {
    if (this.user.hasLoggedIn) {
      this.isProcessing = true
      let data = {
        package_id: this.common.selectedPackage._id,
        coupon_code: (this.epf.coupon_code.value).toUpperCase()
      }
      this.subscription.submitPurchase(data).subscribe(res => {
        if (res.success == true) {
          this.paymentDetails = res.data
          if (this.paymentDetails.payment_status != "success") {
            this.RAZORPAY_OPTIONS.amount = this.paymentDetails.razor_data.amount;
            this.RAZORPAY_OPTIONS.description = this.paymentDetails.razor_data.description;
            this.RAZORPAY_OPTIONS.key = this.paymentDetails.razor_data.key;
            this.RAZORPAY_OPTIONS.order_id = this.paymentDetails.razor_data.order_id;
            this.RAZORPAY_OPTIONS.name = this.paymentDetails.razor_data.name;
            this.RAZORPAY_OPTIONS.prefill.name = this.paymentDetails.razor_data.prefill.name;
            this.RAZORPAY_OPTIONS.prefill.email = this.paymentDetails.razor_data.prefill.email;
            this.RAZORPAY_OPTIONS.prefill.contact = this.paymentDetails.razor_data.prefill.contact;
            this.RAZORPAY_OPTIONS.theme.color = this.paymentDetails.razor_data.theme.color;
            this.RAZORPAY_OPTIONS.image = this.paymentDetails.razor_data.image;
            // binding this object to both success and dismiss handler
            this.RAZORPAY_OPTIONS['handler'] = this.razorPaySuccessHandler.bind(this);
            let razorpay = new Razorpay(this.RAZORPAY_OPTIONS)
            razorpay.open();
          } else {
            localStorage.setItem(this.common.SELECTED_COURSE, JSON.stringify(this.course))
            localStorage.removeItem(this.common.IS_TAB_METHOD)
            this.common.selectedCourse = this.course
            this.alreadySelectedCourse = this.course.title
            this.events.publishSomeData(this.common.COURSE_CHANGES);
            this.onSaveProfile(this.course._id)
            this.isProcessing = false
            this.dialogRef.close()
            // this.goToPurchaseComplete(this.paymentDetails.amount, this.course)
          }
        } else {
          this.isProcessing = false
          this.common.showToastError(res.message)
        }

      }, err => {
        this.isProcessing = false
        this.common.processError(err)
      });
    } else {
      // this.login.onLogin()
    }
  }

  public razorPaySuccessHandler(response) {
    this.razorpayResponse = `Razorpay Response`;
    let data = {
      payment_id: this.paymentDetails._id,
      razor_payment_id: response.razorpay_payment_id,
    }
    this.subscription.verifyPayment(data).subscribe(res => {
      if (res.success == true) {
        localStorage.setItem(this.common.SELECTED_COURSE, JSON.stringify(this.course))
        localStorage.removeItem(this.common.IS_TAB_METHOD)
        this.common.selectedCourse = this.course
        this.alreadySelectedCourse = this.course.title
        this.events.publishSomeData(this.common.COURSE_CHANGES);
        this.isProcessing = false
        this.dialogRef.close()
        // this.goToPurchaseComplete(this.paymentDetails.amount, this.course)
      } else {
        this.isProcessing = false
        this.common.showToastError(res.message)
      }
    }, err => {
      this.isProcessing = false
      this.common.processError(err)
    });
    this.isProcessing = false
    this.showModal = true;
    this.cd.detectChanges()
  }



  closeModal() {
    this.isSubmit = false
    this.isError = false
    this.promoCodeForm.reset()
  }

  onSaveProfile(id) {
    var data = {
      customer_name: this.userDetails.customer_name,
      customer_mobile: this.userDetails.customer_mobile,
      customer_email: this.userDetails.customer_email,
      current_course_id: id
    };
    this
      .profile
      .updateUserDetails(data)
      .subscribe(res => {
        if (res.success == true) {
          this.events.publishSomeData(this.common.USER_PROFILE_UPDATED);
          this.dialogRef.close()
        }
      }, err => {
        console.error('ERROR', err);
        this.common.processError(err);
        return false;
      });
  }

  close() {
    this.dialogRef.close()
  }

  // goToPurchaseComplete(payment, course) {
  //   const dialogRef = this.dialog.open(PurchaseSuccessfullComponent, {
  //     data: { payment: payment,course: course,},
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result && result.success && result.success == true) {
  //       // this.loadCategories();
  //     }
  //   });

  // }



}
