import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(public api: Api) { }
  getAllCourseGroups() {
    let endpoint = 'course_groups';
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  getCourseGroupById(_course_group_id,subscribed=null) {
    let endpoint = 'courses?course_group_id=' + _course_group_id;
    if(subscribed != null)
    endpoint += '&is_subscribed='+subscribed;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  getAllCourses(subscribed:any=null) {
    let endpoint = 'courses';
    if(subscribed != null)
    endpoint += '?is_subscribed='+subscribed;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  coursesView(id) {
    let endpoint = 'courses?course_id='+id;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
}
