import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../providers/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Validator } from '../../_helpers/validators';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from '../../providers/profile.service';
import { UserService } from '../../providers/user.service';
import { EventPublishService } from '../../providers/event-publish';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit {

  registrationForm:FormGroup
  submitted:boolean=false;
  is_agree:boolean=false;
  userDetails:any=[];
  is_Edit:boolean=false;
  isLoading:boolean=false;

  


  constructor(public common:CommonService,public formBuilder:FormBuilder,public validator: Validator,public dialogRef:MatDialogRef<RegistrationFormComponent>,
    public profile:ProfileService,public user:UserService,public events:EventPublishService,public datepipe:DatePipe) {
    this.common.sideActive = 'registration'
    this.profile.getUserDetails().subscribe(resp=>{
      if(resp.success ==true){
        console.log("kkkkkkkkkkkkkk")
       this.userDetails=resp.data;
      }
      if(this.userDetails){
        this.is_Edit=true;
      }
      console.log(this.is_Edit)
      if(this.is_Edit){
        console.log("lllllllllllll")
        this.registrationForm.get('first_name').patchValue(this.userDetails.customer_name);
        this.registrationForm.get('last_name').patchValue(this.userDetails.last_name);
        this.registrationForm.get('father_name').patchValue(this.userDetails.father_name);
        this.registrationForm.get('dob').patchValue(  this.datepipe.transform(this.userDetails.customer_dob, 'dd/MMM/yyyy'));
        this.registrationForm.get('email').patchValue(this.userDetails.customer_email);
        this.registrationForm.get('mobile').patchValue(this.userDetails.customer_mobile);
        this.registrationForm.get('building_name').patchValue(this.userDetails.building_name);
        this.registrationForm.get('street_address').patchValue(this.userDetails.street_address);
        this.registrationForm.get('city').patchValue(this.userDetails.city);
        this.registrationForm.get('pincode').patchValue(this.userDetails.pincode);
        this.registrationForm.get('doc').patchValue(this.userDetails.qualification);
        this.registrationForm.get('yoc').patchValue(this.userDetails.yoc);
        this.registrationForm.get('boe').patchValue(this.userDetails.board_of_exam);
      }
    })
   }

  ngOnInit(): void {
    this.registrationForm=this.formBuilder.group({
      first_name:['',Validators.required],
      last_name:['',],
      father_name:[''],
      dob:['',Validators.required],
      email:['',[Validators.required, Validators.pattern(this.validator.emailPattern())]],
      mobile:['',[Validators.required,Validators.pattern(this.validator.numberPattern()), Validators.minLength(10)]],
      building_name:[''],
      street_address:['',],
      city:['',],
      pincode:['',],
      doc:[''],
      yoc:[''],
      boe:['']
    })
    console.log(this.is_Edit)
   
  }


  get acf() { return this.registrationForm.controls; }

  saveAddress(){
    this.isLoading=true;
    this.submitted=true;
    if(this.registrationForm.invalid){
      this.isLoading=false;
    }
    if(this.registrationForm.valid){
      if(this.is_agree){

      var data={
        customer_name:this.acf.first_name.value,
        last_name:this.acf.last_name.value,
        father_name:this.acf.father_name.value,
        customer_dob:this.acf.dob.value,
        customer_email:this.acf.email.value,
        customer_mobile:this.acf.mobile.value,
        building_name:this.acf.building_name.value,
        street_address:this.acf.street_address.value,
        city:this.acf.city.value,
        pincode:this.acf.pincode.value,
        qualification:this.acf.doc.value,
        yoc:this.acf.yoc.value,
        board_of_exam:this.acf.boe.value
      }

     this.profile.updateUserDetails(data).subscribe(resp=>{
       if(resp.success ==true){
         this.isLoading=false;
        this.user.updateUserData(resp.data);
        this.events.publishSomeData(this.common.USER_PROFILE_UPDATED);
        this.common.showToastSuccess("You are successfully registerd")
        this.dialogRef.close({
          data:resp
        })
       }
     })
    }else{
      this.isLoading=false;
      this.common.showToastError("Please agree the terms")
    }
      console.log(data)
    }
  }

  checkBox(event){
    if(event.target.checked){
     this.is_agree=true;
    }
    if(!event.target.checked){
      this.is_agree=false;
    }
    console.log(this.is_agree)
  }
}
