<app-side-nav></app-side-nav>
<section class="profile_Card">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="profile_box">
                    <div class="row">
                        <div class="col-md-1">
                            <img [src]="profileData.image_url" onError="this.src='assets/img/user.svg'" class="width100 img pr_img_ind">
                            <img src="assets/img/edit.svg" class="pr_edit_image">
                        </div>
                        <div class="col-md-11">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="table">
                                        <div class="t_cell mt_name">
                                            <h2 class="block medium">{{profileData.customer_name}} </h2>
                                        </div>
                                        <div class="t_cell ct_inner" *ngIf="subscribedCources.length > 0">
                                            <select class="slect_input_1" (change)="selectCource($event)">
                        <option *ngFor="let option of subscribedCources" value="{{option.course_id}}">{{option.course.title}}</option>
                      </select>
                                        </div>
                                        <div class="t_cell ct_inner" *ngIf="is_Certificate">
                                            <span class="badge_3">Completed</span>
                                        </div>
                                        <div class="t_cell ct_inner" *ngIf="!is_Certificate">
                                            <span class="badge_3">Pursuing</span>
                                        </div>
                                        <div class="t_cell ct_inner" *ngIf="is_Certificate">

                                            <button class="dwnld_btn" (click)="onSave()">Download Certificate</button>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2 pr_date tr">
                                    <!-- <span class="block">{{profileData.customer_dob | date:'dd MMM yyyy'}}</span> -->
                                    <button class="button_s3 mt10" (click)="onLogout()">Signout</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <form [formGroup]="editProfileForm">
                <div class="col-md-12 mt50">
                    <div class="pr_info_title mb10">
                        <div class="row">
                            <div class="col-md-6 col-xs-6 tl">
                                <h3 class="mt0 block">Personal Details</h3>
                            </div>
                            <div class="col-md-6 col-xs-6 tr" (click)="onEdit()">
                                <img src="assets/img/edit.svg" class="edit_icon">
                            </div>
                        </div>
                    </div>
                    <div class="profile_form">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" [readonly]="!isEdit" formControlName="customer_name" class="input_control_1" placeholder="First Name">
                                <div *ngIf="saveProfileSubmitted && epf.customer_name.errors" class="form-error-msg">
                                    <div class="form-error-msg" *ngIf="epf.customer_name.errors.required">
                                        Name is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="input_control_1" readonly formControlName="customer_mobile" placeholder="Mobile Number">
                                <div *ngIf="saveProfileSubmitted && epf.customer_mobile.errors" class="form-error-msg">
                                    <div class="form-error-msg" *ngIf="epf.customer_mobile.errors.required">
                                        Phone is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="text" [readonly]="!isEdit" class="input_control_1" formControlName="customer_email" placeholder="Email Id">
                                <div *ngIf="saveProfileSubmitted && epf.customer_email.errors" class="form-error-msg">
                                    <div class="form-error-msg" *ngIf="epf.customer_email.errors.required">
                                        Email is required
                                    </div>
                                    <div class="form-error-msg" *ngIf="epf.customer_email.errors.pattern">
                                        Email is not valid</div>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                <input type="text" readonly *ngIf="!isEdit" class="input_control_1" [(ngModel)]="d_o_b"
                  [ngModelOptions]="{standalone: true}" placeholder="Date Of Birth">

                <mat-form-field *ngIf="isEdit">
                  <input class="input_control_1" matInput [matDatepicker]="pickerFrom" readonly formControlName="customer_dob"
                    placeholder="Choose a date">
                  <mat-datepicker-toggle matSuffix [for]="pickerFrom">
                  </mat-datepicker-toggle>
                  <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
              </div> -->
                            <div class="col-md-6">
                                <div class="input_control_1">
                                    <mat-radio-group formControlName="customer_gender" class="customer_gender">
                                        <mat-radio-button [disabled]="!isEdit" class="mr10" value="f">Female</mat-radio-button>
                                        <mat-radio-button [disabled]="!isEdit" class="mr10" value="m">Male</mat-radio-button>
                                        <mat-radio-button [disabled]="!isEdit" class="mr10" value="o">Other</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt20">
                    <div class="pr_info_title mb10">
                        <div class="row">
                            <div class="col-md-6 tl">
                                <h3 class="mt0 block">Address Details</h3>
                            </div>
                            <!-- <div class="col-md-6 tr">
              <img src="assets/img/edit.svg" class="edit_icon">
            </div> -->
                        </div>
                    </div>
                    <div class="profile_form">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="text" [readonly]="!isEdit" class="input_control_1" formControlName="customer_address" placeholder="Permanent Address">
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <select formControlName="customer_country" (change)="getState($event.target.value)" class="input_control_1" placeholder="Choose Country">
                    <option [disabled]="!isEdit" value="" selected>Choose Country</option>
                    <option [disabled]="!isEdit" [value]="country._id" *ngFor='let country of countryList'>
                      {{country.title}}</option>
                  </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="text" readonly *ngIf="!isEdit" class="input_control_1" formControlName="customer_state" placeholder="Choose State">
                                <div class="form-group" *ngIf="isEdit">
                                    <select formControlName="customer_state" (change)="getDistrict($event.target.value)" class="input_control_1">
                    <option value="" selected>Choose</option>
                    <option [value]="state._id" *ngFor='let state of stateList'>
                      {{state.title}}
                    </option>
                  </select>
                                    <div *ngIf="saveProfileSubmitted && this.epf.customer_country.value && this.epf.customer_country.value != null" class="form-error-msg">
                                        <div class="form-error-msg" *ngIf="!this.epf.customer_state.value">State is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="text" readonly *ngIf="!isEdit" [disabled]="" class="input_control_1" formControlName="customer_district" placeholder="Choose District">
                                <div class="form-group" *ngIf="isEdit">
                                    <select formControlName="customer_district" class="input_control_1">
                    <option value="" selected>Choose</option>
                    <option [value]="district._id" *ngFor='let district of districtList'>
                      {{district.title}}</option>
                  </select>
                                    <div *ngIf="saveProfileSubmitted && this.epf.customer_country.value && this.epf.customer_country.value != null" class="form-error-msg">
                                        <div class="form-error-msg" *ngIf="!this.epf.customer_district.value">
                                            District is required</div>
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="col-md-6">
              <input type="text" class="input_control_1" placeholder="Pincode ">
            </div> -->
                        </div>
                    </div>
                </div>
            </form>
            <!-- <div class="col-md-12 mt20">
        <div class="pr_info_title mb10">
          <div class="row">
            <div class="col-md-6 tl">
              <h3 class="mt0 block">Education Qualification</h3>
            </div>
            <div class="col-md-6 tr">
              <img src="assets/img/edit.svg" class="edit_icon">
            </div>
          </div>
        </div>
        <div class="profile_form">
          <div class="row">
            <div class="col-md-6">
              <input type="text" class="input_control_1" placeholder="Degree Of Study">
            </div>
            <div class="col-md-6">
              <input type="text" class="input_control_1" placeholder="Year of Completion">
            </div>
            <div class="col-md-6">
              <input type="text" class="input_control_1" placeholder="Board Of Exam">
            </div>
          </div>
        </div>
      </div> -->

            <!-- <div class="col-md-12 mt20">
        <div class="pr_info_title mb10">
          <div class="row">
            <div class="col-md-6 tl">
              <h3 class="mt0 block">Account Settings </h3>
            </div>
            <div class="col-md-6 tr">
              <img src="assets/img/edit.svg" class="edit_icon">
            </div>
          </div>
        </div>
        <div class="profile_form">
          <div class="row">
            <div class="col-md-6">
              <input type="text" class="input_control_1" placeholder="Email Id">
            </div>
            <div class="col-md-6">
              <input type="text" class="input_control_1" placeholder="Mobile Number">
            </div>
            <div class="col-md-6">
              <input type="text" class="input_control_1" placeholder="Password">
            </div>
          </div>
        </div>
      </div> -->

            <div class="col-md-12 mt20 tr">
                <button class="button_s1" (click)="onSaveProfile()">Save Change</button>
            </div>
        </div>
    </div>
</section>
<app-subscription-box></app-subscription-box>
<app-footer></app-footer>