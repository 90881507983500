<app-side-nav></app-side-nav>



<section class="exam_lsit_section">
  <div class="container">
    <div class="row mb20">
      <div class="col-md-12 tc">
        <h1 class="border_heading_bottom">Exam Result</h1>
      </div>
      <div class="col-md-6 col-md-offset-3">
        <div class="df_exam_result tc">
          <div class="div_for_exam_result_main mb5">
            <!-- <h2 class="mb0">Test Result 1</h2> -->
            <h3 class="mt5" *ngIf='exam.resultData && exam.resultData.exam'>
              {{exam.resultData && exam.resultData.exam.name}}</h3>
          </div>
          <div class="div_for_reult">
            <!-- <span *ngIf='exam.resultData && exam.resultData.duration'>
              Duration: <span class="primary">{{exam.resultData.duration}} Min</span> </span> -->
            <table class="mt0 width100">
              <div class='row' *ngIf="loadingVideos">
                <div class="col-md-12" *ngFor="let itm of [1,2]">
                  <ngx-skeleton-loader [theme]="{ height:'400px',width:'400px'}"></ngx-skeleton-loader>
                </div>
              </div>
              <tr>
                <td class="tc"><span>
                  <ng-container *ngIf='exam.resultData && exam.resultData.exam'>
                    {{exam.resultData.exam.duration}}
                  </ng-container> Min | 
                  <ng-container *ngIf='exam.resultData'>
                    {{exam.resultData.total_questions}}
                  </ng-container> Que | 
                  <ng-container *ngIf='exam.resultData'>
                    {{exam.resultData.maximum_mark}}
                  </ng-container> Marks</span></td>
              </tr>
            </table>
          </div>
          <div class="div_for_exam_result mt20">
            <div class="exam_result_chart_div tc">
              <div class="result_tt_div margin0auto">
                <h3 class="graph_c_inner">You Got <span class="block">
                  {{exam.resultData.total_mark}}/<ng-container *ngIf='exam.resultData && exam.resultData'>{{exam.resultData.maximum_mark}}</ng-container></span> </h3>
                <google-chart class="margin0auto" [title]="title" [type]="type" [data]="data" [options]="optionsCorrect">
                </google-chart>
              </div>
            </div>
          </div>
        </div>
        <div class="df_exam_result mt20">
          <div class="inner_graph mt10">
            <div class="row">
              <div class="col-md-4">
                <div class="exam_result_chart_div tc">
                  <div class="result_tt_div margin0auto">
                    <google-chart class="margin0auto" [title]="title" [type]="type" [data]="dataCorrect" [options]="optionsCorrect">
                    </google-chart>
                    <span class="medium block mt10">{{exam.resultData.total_correct_answers}} Answers are <br> correct </span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="exam_result_chart_div tc">
                  <div class="result_tt_div margin0auto">
                    <google-chart class="margin0auto" [title]="title" [type]="type" [data]="dataWrong" [options]="optionsWrong">
                    </google-chart>
                    <span class="medium block mt10">{{exam.resultData.total_wrong_answers}} Answers are <br> Wrong  </span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="exam_result_chart_div tc">
                  <div class="result_tt_div margin0auto">
                    <google-chart class="margin0auto" [title]="title" [type]="type" [data]="dataSkip" [options]="optionsSkipped">
                    </google-chart>
                    <span class="medium block mt10">{{exam.resultData.total_unattended}} Answers are <br> Skipped  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="for_text_unaswer tc mt20">
            <span class="medium primary">You have 10 unanswered questions out of 11</span>
          </div> -->

          <div class="div_for_submit_exam mt20">
            <table class="mt20 width100">
              <tr>
                <td class="pr20 tc">
                  <button class="button_s3" (click)="allQuestion()" [routerLink]="['/question-pool',examId]">Answer Key</button>
                </td>
                <td class="tc">
                  <button class="button_s1" routerLink="/home">Back to Home</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-subscription-box></app-subscription-box>
<app-footer></app-footer>