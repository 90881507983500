import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/providers/common.service';
import { EventPublishService } from 'src/app/providers/event-publish';
import { ItemService } from 'src/app/providers/item.service';
import { ProfileService } from 'src/app/providers/profile.service';
import { UserService } from 'src/app/providers/user.service';
import { Validator } from '../../../_helpers/validators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public addContactForm: FormGroup;
  submitted: boolean = false
  validator = new Validator;
  isLoading:boolean = false;
  userDetails:any=[]
  is_marquee:boolean=false;
  userProfile:any=[];
  
  constructor(public common: CommonService, public user: UserService,
    public router: Router, public events: EventPublishService,
    public formBuilder: FormBuilder, public profile: ProfileService,
    public item: ItemService) {

    this.addContactForm = this.formBuilder.group({
      mobile: ['', [Validators.required, Validators.maxLength(12), Validators.minLength(10),
      Validators.pattern(this.validator.numberPattern())]],
      name: ['', [Validators.required]],
      email: ['', [Validators.pattern(this.validator.emailPattern())]],
      message: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
   


    if (this.user.hasLoggedIn) {
      this.getProfile()
      this.is_marquee=true;
    }
    if(localStorage.getItem(this.user.HAS_LOGGED_IN)){
     this.userProfile=JSON.parse(localStorage.getItem(this.user.PROFILE))
     console.log(this.userProfile)
    }
  }





  get acf() { return this.addContactForm.controls; }


  getProfile() {
    this.profile.getUserDetails()
      .subscribe(res => {
        if (res.success == true) {
          this.userDetails = res.data;
          this.addContactForm.get('mobile').patchValue(this.userDetails.customer_mobile)
          this.addContactForm.get('email').patchValue(this.userDetails.customer_email)
        }
      }, err => {
        this.common.processError(err);
        return false;
      });
  }

  onSubmit() {
    this.submitted = true;
    this.isLoading = true;
    if (this.addContactForm.invalid) {
      this.isLoading = false;
     console.log(this.isLoading)
      return;
    }
    if (this.addContactForm.valid) {
      var data = {
        mobile: this.acf.mobile.value,
        name: this.acf.name.value,
        email: this.acf.email.value,
        message: this.acf.message.value,
      };
      console.log(data)
      this.item.addContactUs(data).subscribe(res => {
        if (res.success == true) {
          this.addContactForm.reset();
          this.submitted = false;
          this.isLoading = false;
          this.common.showToastSuccess(res.message)
        } else {
          this.common.showToastError(res.message)
        }
      }, err => {
        this.isLoading = false;
        console.error('ERROR', err);
        this.common.processError(err);
        return false;
      });
    }
    else{
      this.isLoading = false;
    }
  }
}
