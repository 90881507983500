<section class="auth_section">
  <div class="auth_mobile_image_div">
    <img src="assets/img/login_bg.svg" class="auth_mobile_image">
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="auth_left">
          <div class="auth_left_child">
            <div class="auth_heading">
              <h3>Hello</h3>
              <h1 class="bold h1">Sign <span class="white h1">Up!</span></h1>
            </div>
            <div class="auth_form">
              <div class="auth_form_text">
                <span class="f16">Please Enter Your Details </span>
              </div>
              <div class="auth_form_box">
                <div class="afb_input_control">
                  <input type="text" class="input_control_1" placeholder="Enter Full Name">
                </div>
                <div class="afb_input_control">
                  <input type="text" class="input_control_1"  placeholder="Enter Your Mobile Number">
                </div>
                <div class="afb_input_control">
                  <input type="text" class="input_control_1" placeholder="Enter Your Email Id">
                </div>
              </div>
              <div class="auth_actions_box">
                <div class="row">
                  <div class="col-md-6 col-xs-7 tl">
                    <span>Forgot Mobile Number ?</span>
                  </div>
                  <div class="col-md-6 col-xs-5 tr">
                    <span>Old User ? <span class="primary pointer" routerLink="/signin">Sign In</span> </span>
                  </div>
                  <div class="col-md-12 col-sm-12 col-xs-12 tl">
                    <div class="action_auth_btn mt20">
                      <button class="button_s1 f12" routerLink="/verify">Sign Up</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="auth_right">
          <div class="auth_right_child">
            <div class="auth_heading_right tc mb20">
              <h2>Expert Mentors</h2>
            </div>
            <div class="auth_right_image tc">
              <img src="assets/img/login_right.svg">
            </div>
            <div class="auth_right_text tc mt20">
              <p class="medium">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's standard dummy text ever since the 1500s.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>