<app-side-nav></app-side-nav>
<div class="row">
  <div class="col-md-12">
    <button type="button" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
</div>
<section class="package_list_tab">
  <div class="container">
    <div class="row mb20">
      <div class="col-md-12 tc">
        <h1 class="border_heading_bottom">Packages</h1>
      </div>
      <div class="col-md-offset-3 col-md-6 col-xs-12 tc">
        <p class="p0 m0">Make the most of our competitive courses, to gain in-depth access to the righteous accounting practise that you have been seeking.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 pt30">
        <owl-carousel-o [options]="package">
          <ng-template carouselSlide *ngFor="let pack of packageList">
            <div class="uex_package_div">
              <div class="item_for_package_caro">
                <!-- <div class="item_package_image">
                  <img src="assets/img/package.svg">
                </div> -->
                <div class="item_package_text_box">
                  <h2>{{ pack.name }}</h2>
                </div>
                <div class="i_package_price">
                  <span class="medium"><del *ngIf="pack.strike_price > pack.price">₹
                    {{pack.strike_price}}</del></span>
                  <h2>₹  {{  pack.price | number:'.2-2' }}</h2>
                </div>
                <div class="ip_valid">
                  <span class="medium">Validity: {{pack.validity}} Days</span>
                </div>
                <div class="package_ul">
                  <ul>
                    <li *ngFor='let ben of pack.benefites'><img src="assets/img/circle_check.svg">{{ ben }} </li>
                  </ul>
                </div>
                <div class="package_action">
                  <button class="button_s1" (click)='buyNow(pack);buyPackage=true'>Buy Now</button>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</section>

<div id="open-modal" class="modal-window" [ngClass]="openmodal ? 'show' : 'hide'">
  <div>
    <a title="Close" class="modal-close pointer" (click)="openmodal=!openmodal">
      <img src="assets/img/close.svg">
    </a>
    <h2>10th Level Preliminary - Plus</h2>
    <div class="md_i_package_price">
      <span class="medium">₹ 4975</span>
      <h2>₹ 3,975.00</h2>
    </div>
    <div><small>Get an Amazing Offer</small></div>
    <div class="promo_div">
      <input class="promo_input" placeholder="Enter Promo Code">
      <button class="white">Submit</button>
    </div>
    <div class="submit_promo_purchase mt20 tc">
      <button class="button_s1">Purchase</button>
    </div>
  </div>
</div>

<app-subscription-box></app-subscription-box>
<app-footer></app-footer>