import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { EventPublishService } from 'src/app/providers/event-publish';
import { ItemService } from 'src/app/providers/item.service';
import { CommonService } from '../../providers/common.service';
import { ExamService } from '../../providers/exam.service';

@Component({
  selector: 'app-exam-view',
  templateUrl: './exam-view.component.html',
  styleUrls: ['./exam-view.component.scss']
})
export class ExamViewComponent implements OnInit {

  loadingExams: boolean = false
  page: any = 1
  per_page: any = 10
  sub_id: any = null
  examList: any = []
  hasLoadMore: any = false
  examDetails: any = []
  id: any
  isError: boolean = false
  loadingExam: boolean = false
  loadingExamDetails: boolean = false
  loadingItems: boolean = false
  is_home = 1
  week_id: any = null
  day_id: any = null
  trendingList: any = []
  questionCount: any = 0
  course_id:any=null
  constructor(public common: CommonService, public exam: ExamService,
    public route: ActivatedRoute, public itemService: ItemService,
    public router: Router, public events: EventPublishService) {

      this.router.events.subscribe((evt) => {
        console.log(evt)
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0)
      });

    if (this.common.selectedCourse != null) {
      this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
    }
    this.route.params.subscribe(params => {
      this.examDetails = []
      this.id = params['id']
      this.loadExamView(this.id)
      this.loadExams(this.sub_id,this.course_id, this.week_id, this.day_id)
    })
  }

  ngOnInit(): void {
  }

  loadExams(sub_id:any=null,course_id:any=null,week_id:any=null, day_id:any=null) {
    this.loadingExams = true;
    this.exam.getExam(this.page, this.per_page, sub_id,course_id,week_id,day_id)
      .subscribe(res => {
        console.log(res);
        if (res.success == true) {
          res.data.data.forEach(element => {
            if (!this.examList.find(x => x._id == element._id)) {
              this.examList.push(element);
            }
          });
        }
        this.loadingExams = false;
      }, err => {
        this.loadingExams = false;
        console.error('ERROR', err);
        this.common.processError(err);
        return false;
      });
  }

  loadExamView(id) {
    this.isError = false
    this.loadingExam = true;
    this.exam.examView(id).subscribe(res => {
      console.log(res);
      if (res.success == true) {
        this.examDetails = res.data;
        this.exam.examDetail = res.data
      }
      this.loadingExam = false;
    }, err => {
      this.loadingExam = false;
      this.isError = true
      console.error('ERROR', err);
      return false;
    });
  }

  startExam() {
    if(this.examDetails.no_of_questions > 0){
      this.startExamDetais(this.id)
    }
    else{
      this.common.showToastError("no Questions Found")
    }
    
  }

  goToExam() {
    localStorage.setItem(this.common.QUESTION_FLAG, 'question')
    let exam_id = localStorage.getItem(this.common.EXAM_ID)
    if (exam_id == this.exam.examDetail._id) {
      if (this.exam.leftTimeSeconds > 0) {
        let id = localStorage.getItem(this.common.EXAM_ID)
        this.router.navigate(['/test', id])
        // this.navCtrl.navigateForward('mock-test', {
        //   queryParams: {
        //     examId: 
        //   }
        // })
      }
    } else {
      this.common.showToastError('Please Complete Your Exam')
    }
  }

  startExamDetais(id) {
    this.loadingExamDetails = true;
    this
      .exam
      .examStart(id)
      .subscribe(res => {
        if (res.success == true) {
          this.examDetails = res.data;
          this.exam.examDetail = res.data
          console.log(res.data)
          localStorage.removeItem(this.common.EXAM_QUESTIONS)
          localStorage.setItem(this.common.EXAM_QUESTIONS, JSON.stringify(res.data.questions));
          this.questionCount = this.examDetails.questions.length
          if (this.questionCount > 0) {
            this.isError = true
            this.exam.startTime = new Date()
            var examTime: any = (this.examDetails.duration) * 60
            localStorage.setItem(this.common.EXAM_TIME, examTime)
            localStorage.setItem(this.common.START_TIME, this.exam.startTime)
            localStorage.setItem(this.common.EXAM_ID, this.exam.examDetail._id)
            localStorage.setItem(this.common.QUESTION_FLAG, 'question')
            localStorage.setItem(this.common.EXAM_NAME, this.examDetails.name)
            localStorage.setItem(this.common.IS_EXAM_EXIST, 'true')
            this.exam.examTime = localStorage.getItem(this.common.EXAM_TIME)
            this.events.publishSomeData("exam_started")
            this.events.publishFcmData(this.common.EXAM_START)
            if (localStorage.getItem(this.common.IS_EXAM_EXIST)) {
              this.exam.isExamComplete = localStorage.getItem(this.common.IS_EXAM_EXIST)
            } else {
              this.exam.isExamComplete = false
            }
            this.exam.isCalculate = false
            this.router.navigate(['/question-pool', this.examDetails._id])
          } else {
            this.common.showToastError("No Questions Available. Please Choose Another Exam")
          }

        }
        this.loadingExamDetails = false;
      }, err => {
        this.loadingExamDetails = false;
        this.common.processError(err)
        console.error('ERROR', err);
        return false;
      });
  }

  onClickView(value) {
    console.log(value)
    if (value.completed_on && value.completed_on != null) {
      this.router.navigate(['/exam-result', value.result_id])
      
    } else {
      this.router.navigate(['/exam-view', value._id])
      
    }
  }

}
