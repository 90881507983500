import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../providers/user.service';

@Component({
  selector: 'app-subscription-box',
  templateUrl: './subscription-box.component.html',
  styleUrls: ['./subscription-box.component.scss']
})
export class SubscriptionBoxComponent implements OnInit {

  courseDetail:any;
  is_Subscribed:boolean;

  constructor(public user:UserService) { }

  ngOnInit(): void {
    if(this.user.hasLoggedIn){
      console.log("kkkkkkkkkkkkk")
    this.courseDetail=JSON.parse(localStorage.getItem('uexCourse'))
    if(this.courseDetail.is_subscribed==true){
      this.is_Subscribed=true
    }
    }
  }

}
