import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../../providers/notification.service';
import { CommonService } from '../../../providers/common.service';
import { Router } from '@angular/router';

import { MaterialViewComponent ,} from '../../material-view/material-view.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  notification:any=[];
  page:number=1;
  per_page:number=12;
  length:any;
  

  constructor(public note:NotificationService,public common:CommonService,public router:Router,
  public modalCtrl:MatDialog) { }


  ngOnInit(): void {
    this.getNotification();
    console.log("lllllllllllll")
  }


  getNotification(){
    this.note.getNotifications(this.page,this.per_page).subscribe(resp=>{
      // console.log(resp)
      if(resp.success ==true){
        this.length=resp.data.total;

        this.notification=this.notification.concat(resp.data.data)
        console.log(this.notification)
      }
    },error=>{
      this.common.processError(error)
    })

  }

 
  onScroll(e) {
    if (this.length <= this.notification.length) {
      return;
    }
    this.page = this.page + 1
    this.getNotification()
  }




  onClick(_notification) {
    if (_notification.item_type) {
      switch (_notification.item_type) {

        case 'video': {
          this.common.type = 'video'
          this.common.tab_type = 'video'
          if (_notification.item_id && _notification.item_id > 0) {
            this.materialView(_notification.item_id)
          } else {
            this.router.navigate(['/video'])
          }

          break;
        }
        case 'trending': {
          this.common.type = 'trending'
          this.common.tab_type = 'trending'
          if (_notification.item_id && _notification.item_id > 0) {
            this.materialView(_notification.item_id)
          } else {
            this.router.navigate(['/video'])
          }
          break;
        }
        case 'pdf': {
          this.common.type = 'pdf'
          this.common.tab_type = null
          if (_notification.item_id && _notification.item_id > 0)  {
            this.materialView(_notification.item_id)
          } else {
            this.router.navigate(['/video'])
          }
          break;
        }
        case 'audio': {
          this.common.type = 'audio'
          this.common.tab_type = 'audio'
          if (_notification.item_id && _notification.item_id > 0) {
            this.materialView(_notification.item_id)
          } else {
            this.router.navigate(['/video'])
          }
          break;
        }
        case 'profile': {
          this.router.navigate(['/profile']);
          break;
        }
        case 'external': {
          this.onOpenExternalLink(_notification.item_id)
          break;
        }
        case 'course': {
          this.router.navigate(['/course-selected'])
          break;
        }
        case 'publication': {
          this.router.navigate(['/publication'])
          break;
        }
        // case 'ticket': {
        //   if (_notification.item_id && _notification.item_id > 0) {
        //     this.router.navigate(['/ticket-view', _notification.item_id])
        //   } else {
        //     this.router.navigate(['/ticket-list', 'ticket'])
        //   }
        //   break;
        // }

        case 'exam': {
          if (_notification.item_id && _notification.item_id > 0) {
            // this.navCtrl.navigateForward('exam-view', {
            //   queryParams: {
            //     id: _notification.item_id
            //   }
            // })
            this.router.navigate(['/exam-view', _notification.item_id])
          } else {
            this.router.navigate(['/exam']);
          }
          break;
        }
        case 'result': {
          if (_notification.item_id && _notification.item_id > 0) {
            // this.navCtrl.navigateForward('exam-mark', {
            //   queryParams: {
            //     flag: 'exam',
            //     examId: _notification.item_id
            //   }
            // })
            this.router.navigate(['/exam-result', _notification.item_id])
          } else {
            this.router.navigate(['/my-result']);
          }
          break;
        }
        case 'student': {
          if (_notification.item_id && _notification.item_id > 0) {
            this.router.navigate(['/profile']);
          } else {
            this.router.navigate(['/profile']);
          }
          break;
        }
      }
    } else {
      this.router.navigate(['/tabs/home'])
    }
  }

  async materialView(value) {
    const dialogRef = this.modalCtrl.open(MaterialViewComponent, {
      panelClass: "full_modal",
      closeOnNavigation: false,
      data: {
        id: value,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onOpenExternalLink(external_link) {
    window.location.href=external_link;
  }
}
