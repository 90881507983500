import { Component, OnInit } from '@angular/core';
import { ExamService } from '../../providers/exam.service';
import { CommonService } from '../../providers/common.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  loadingResult: boolean = false
  resultList: any = []
  page: any = 1
  per_page: any = 10
  length:any
  constructor(public exam:ExamService,public common:CommonService,
    public router:Router) { 

      this.router.events.subscribe((evt) => {
        console.log(evt)
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0)
      });
    }

  ngOnInit(): void {
    this.loadResult()
  }

  onScroll(e) {
    if (this.length <= this.resultList.length) {
      return;
    }
    this.page = this.page + 1
    this.loadResult()
  }


  loadResult() {
    this.loadingResult = true;
    this.exam.myResult(this.page, this.per_page).subscribe(res => {
      if (res.success == true) {
        this.length = res.data.total
        res.data.data.forEach(element => {
          if (!this.resultList.find(x => x._id == element._id)) {
            this.resultList.push(element);
          }
        });
      }
      this.loadingResult = false;
    }, err => {
      this.loadingResult = false;
      console.error('ERROR', err);
      this.common.processError(err);
      return false;
    });
  }

  onClickResultView(value) {
    console.log(value)
    localStorage.setItem(this.common.EXAM_NAME, value.exam.name)
    // let data = {
    //   flag: 'result',
    //   id: value._id,
    // }
    this.router.navigate(['/exam-result', value._id])
  }
}
