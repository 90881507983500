import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SigninComponent } from './pages/signin/signin.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './pages/common/header/header.component';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './pages/common/footer/footer.component';
import { VideoListComponent } from './pages/video-list/video-list.component';
import { SideNavComponent } from './pages/common/side-nav/side-nav.component';
import { VideoDetailComponent } from './pages/video-detail/video-detail.component';
import { SubscriptionBoxComponent } from './pages/common/subscription-box/subscription-box.component';
import { MaterialDetailComponent } from './pages/material-detail/material-detail.component';
import { MaterialViewComponent } from './pages/material-view/material-view.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RegistrationFormComponent } from './pages/registration-form/registration-form.component';
import { SelectCourseComponent } from './pages/select-course/select-course.component';
import { PackageListComponent } from './pages/package-list/package-list.component';
import { ExamListComponent } from './pages/exam-list/exam-list.component';
import { ExamViewComponent } from './pages/exam-view/exam-view.component';
import { QuestionPoolComponent } from './pages/question-pool/question-pool.component';
import { SubmitExamComponent } from './pages/submit-exam/submit-exam.component';
import { ExamResultComponent } from './pages/exam-result/exam-result.component';
import { CourseWeekComponent } from './pages/course-week/course-week.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { NotificationComponent } from './pages/common/notification/notification.component';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonService } from './providers/common.service';
import { ProfileService } from './providers/profile.service';
import { UserService } from './providers/user.service';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { NgOtpInputModule } from 'ng-otp-input';
import {MatRadioModule} from '@angular/material/radio';
import { ResultComponent } from './pages/result/result.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { PipesModule } from './pipes/pipes.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import * as firebase from 'firebase/app';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { MessagingService } from './providers/messaging.service';
import { MobileNavComponent } from './pages/common/mobile-nav/mobile-nav.component';
import { SuccessPackageComponent } from './pages/success-package/success-package.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { PdfViewerComponent } from './pages/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxSpinnerModule } from 'ngx-spinner'; // <- import 
import { FileSaverModule } from 'ngx-filesaver';
import { TermsComponent } from './pages/terms/terms.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PlacementCellComponent } from './pages/placement-cell/placement-cell.component';


@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    SigninComponent,
    VerifyComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    VideoListComponent,
    SideNavComponent,
    VideoDetailComponent,
    SubscriptionBoxComponent,
    MaterialDetailComponent,
    MaterialViewComponent,
    ProfileComponent,
    RegistrationFormComponent,
    SelectCourseComponent,
    PackageListComponent,
    ExamListComponent,
    ExamViewComponent,
    QuestionPoolComponent,
    SubmitExamComponent,
    ExamResultComponent,
    CourseWeekComponent,
    NotificationComponent,
    ResultComponent,
    MobileNavComponent,
    SuccessPackageComponent,
    PdfViewerComponent,
    TermsComponent,
    AboutUsComponent,
    PlacementCellComponent,
      
  ],
  entryComponents:[RegistrationFormComponent,MaterialViewComponent,SuccessPackageComponent,NotificationComponent,
    PdfViewerComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    
    BrowserAnimationsModule,
    GoogleChartsModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({ preventDuplicates: true, positionClass: 'toast-bottom-right', }),
    MatDialogModule,
    NgOtpInputModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    PipesModule,
    PdfViewerModule ,
    NgxSkeletonLoaderModule,
    InfiniteScrollModule,
    AngularFireAuthModule,
    FileSaverModule,
    AngularFireMessagingModule,
    Ng2TelInputModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxSpinnerModule,
  ],
  providers: [
    CommonService,
    ProfileService,
    UserService,
    DatePipe,
    MessagingService,
    NotificationComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent], 
})
export class AppModule { }
