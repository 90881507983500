<app-side-nav></app-side-nav>

<section class="exam_lsit_section">
  <div class="container">
    <div class="row mb20">
      <div class="col-md-12 tc">
        <h1 class="border_heading_bottom">Exam List</h1>
      </div>
      <div class="col-md-offset-3 col-md-6 col-xs-12 tc">
        <p class="p0 m0">Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document
          without relying on meaningful content.</p>
      </div>
    </div>

    <div *ngIf='!loadingExams && (!examList || examList.length < 1)'>
      <div class="col-md-12">
        <div class="text-center">
          <!-- <div><img src="assets/images/nodata.svg" width="80"></div> -->
          <span>No Exam Found!</span>
        </div>
      </div>
    </div>

    <div class='row mt50' *ngIf='loadingExams'>
      <div class="col-md-4 mb30" *ngFor="let itm of [1,2,3,4,5,6,7,8]">
        <ngx-skeleton-loader [theme]="{ height: '150px'}"></ngx-skeleton-loader>
      </div>
    </div>


    <div class="row mt50" infiniteScroll [infiniteScrollDistance]="0.5" (scrolled)="onScroll($event)">
      <div class="col-md-4 mb30" *ngFor="let exam of examList ">
        <div class="exam_list_selection" *ngIf='exam.completed_on && exam.completed_on != null' (click)='onClickView(exam)'>
          <div class="exam_list_heading mb5">

            <ng-container *ngIf="exam.is_completed == true">
              <button class="selected_crs_button"> <img src="assets/img/circle_check.svg"> <span>Completed Exam</span>
              </button>
            </ng-container>
            <h3 class="medium mt0 mb0">{{exam.name}}</h3>
          </div>
          <div class="exam_list_duration" >
            <span>Duration: <span class="primary">{{exam.duration}} Min</span> </span>
            <table class="mt20">
              <tr>
                <td class="pr20 tl"><span class="medium">{{exam.no_of_questions}} Que | {{exam.total_mark}} Marks</span>
                </td>
                <td class="tr">
                  <button class="button_s6" *ngIf='exam.completed_on && exam.completed_on != null'
                    (click)='onClickView(exam)'>View Result</button>

                </td>
  
              </tr>
            </table>
          </div>
        </div>

        <div class="exam_list_selection" *ngIf='!exam.completed_on || exam.completed_on == null' (click)='onClickView(exam)'>
          <div class="exam_list_heading mb5">

            <ng-container *ngIf="exam.is_completed == true">
              <button class="selected_crs_button"> <img src="assets/img/circle_check.svg"> <span>Completed Exam</span>
              </button>
            </ng-container>
            <h3 class="medium mt0 mb0">{{exam.name}}</h3>
          </div>
          <div class="exam_list_duration" >
            <span>Duration: <span class="primary">{{exam.duration}} Min</span> </span>
            <table class="mt20">
              <tr>
                <td class="pr20 tl"><span class="medium">{{exam.no_of_questions}} Que | {{exam.total_mark}} Marks</span>
                </td>
                <td class="tr">
                  <button class="button_s6" *ngIf='exam.completed_on && exam.completed_on != null'
                    (click)='onClickView(exam)'>View Result</button>

                </td>
                <td class="tr">
                  <button class="button_s6" *ngIf='!exam.completed_on || exam.completed_on == null'
                    (click)='onClickView(exam)'>Start & View Exam</button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-subscription-box></app-subscription-box>
<app-footer></app-footer>