<button type="button" aria-label="Close" class="close_model_div" (click)="dialogRef.close()">
    <img src="assets/img/close.svg" class="close_model">
  </button>
  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>

</ngx-spinner>
  <pdf-viewer [src]="pdfSource"  (pages-initialized)="pageRendered($event)"
              [original-size]="false"
  ></pdf-viewer>

  