<section class="side_nav_section">
  <div class="container">
    <div class="row">
      <div class="col-md-10 col-xs-10 tl">
        <a class="navbar-brand-1" routerLink="/home">
          <img class="main_logo_header" src="assets/img/logo_full.svg">
        </a>
        <button class="btn cs_course" *ngIf="!common.selectedCourse" routerLink="/select-course">
          <h2> Choose Course <img  src="assets/img/rel_play.svg"></h2>
        </button>
        <button class="btn cs_course" *ngIf="common.selectedCourse" routerLink="/select-course">
          <h2> {{common?.selectedCourse?.title}} <img  src="assets/img/rel_play.svg"></h2>
        </button>
      </div>
      <div class="col-md-2 col-xs-2 tr">
        <div class="noti_div">
          <img src="assets/img/nav_menu.svg" class="pointer" (click)="showNoti=!showNoti">
          <div class="noti_div_inner" *ngIf="showNoti" [@inOutAnimation]>
            <app-notification></app-notification>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-notification></app-notification> -->
  </div>
</section>
<div class="fixed_side_nav">
  <div class="fxd_nav_inner">
    <ul>
      <li class="user_imp" routerLink="/profile" title="Profile">
        <img [src]="profileData.image_url" onError="this.src='assets/img/user.svg'">
      </li>
      <li *ngIf="hasLOggedIn =='false'" routerLink="/home" (click)="common.sideActive='home';changeClass()" [ngClass]="{'active':common.sideActive=='home'}">
        <img src="assets/img/home_icon.svg" title="Home">
      </li>
      <li  *ngIf="hasLOggedIn =='true'" routerLink="/select-course" (click)="common.sideActive='home';changeClass()" [ngClass]="{'active':common.sideActive=='home'}">
        <img src="assets/img/home_icon.svg" title="Home">
      </li>
      <li  [routerLink]="['/video-list','video']" title="Videos" [ngClass]="{'active':common.sideActive=='video'}"
       (click)="common.sideActive='video';changeClass()">
        <img src="assets/img/video_icon.svg">
      </li>
      <li [routerLink]="['/video-list','pdf']" title="Materials" [ngClass]="{'active':common.sideActive=='pdf'}"
       (click)="common.sideActive='pdf';changeClass()">
        <img src="assets/img/book.svg">
      </li>
      <li  [routerLink]="['/video-list','mock']" title="Exams" [ngClass]="{'active':common.sideActive=='exam'}"
      (click)="common.sideActive='exam';changeClass()">
        <img src="assets/img/graduation.svg">
      </li>
      <!-- <li routerLink="/registration-form" title="Registration" [ngClass]="{'active':common.sideActive=='registration'}"
       (click)="common.sideActive='registration';changeClass()">
        <img src="assets/img/chat_hash.svg">
      </li> -->
      <li>
      </li>
    </ul>
  </div>
</div>
<div class="mocktext_counter blink_me " *ngIf="exam.leftTimeSeconds > 0 && hasCounter && url !='/question-pool/'">
  <div class="mc_innner_div">
    <table class="mc_inner_table">
      <tr (click)="goToExam()">
        <td>
          <img src="assets/img/timer.svg" class="mc_timer_img">
        </td>
        <td>
          <span class="medium ">{{ examName }}</span>
          <span>Remaining time:  {{ exam.leftTimeSeconds * 1000 | date:'HH:mm:ss':'UTC' }}</span>
        </td>
      </tr>
    </table>
  </div>
</div>
<app-mobile-nav></app-mobile-nav>