<app-header></app-header>
<section class="home_course_tab mt50">
  <div class="container">
    <div class="row mb20">
      <div class="col-md-12 tc mt20">
        <h1 class="border_heading_bottom">About Us</h1>
      </div>
      <div class="col-md-12 tc">
        <p class="p0 m0">OCOM is an online platform designed to build and improve skills of individuals in Professional
          Accounts to help mold them as leaders and champions in the field, in today’s competitive world. Courses we
          offer are Professional Practical Business Accounts, Professional Practical Finance Accounts, Training for
          Finance Manager, Business Accounts and GST. Allow us to hand over to you the key of opportunities so that
          you can confidently enter the door you’ve been seeking.</p>
      </div>

      <div class="col-md-12 tc">
        <p class="block">OCOM specializes in giving students a realistic approach in Accounting, whereby they are given ample experience and training as if they were to work in a professional environment. By the time they complete the course they would have garnered enough knowledge to face any challenges thrown their way. The vouchers, invoices, bills and the likes issued in the courses are those of real companies to ensure with ease they can carry out their work once they venture into the professional world outside.   
        </p>
      </div>

    </div>
  </div>
</section>

<app-subscription-box></app-subscription-box>
<app-footer></app-footer>
<app-mobile-nav></app-mobile-nav>