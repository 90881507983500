import { Component, OnInit, ElementRef, NgZone } from '@angular/core';
import * as $ from 'jquery';
import { CommonService } from '../../../providers/common.service';
import { ProfileService } from '../../../providers/profile.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NotificationComponent } from '../notification/notification.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ExamService } from '../../../providers/exam.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { EventPublishService } from '../../../providers/event-publish';
import { UserService } from '../../../providers/user.service';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.3s ease-out',
              style({ height: 300, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: "100%", opacity: 1 }),
            animate('0.3s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class SideNavComponent implements OnInit {
  hasLOggedIn:any;
  showNoti: boolean = false;
  profileData: any = []
  hasCounter:boolean=false;
  examName:any;
  url:any;
  constructor(private elRef: ElementRef, public common: CommonService,
    public profile: ProfileService, public zone: NgZone,public notification:NotificationComponent,public router:Router,
    public exam:ExamService,public route: ActivatedRoute,public events:EventPublishService,public user:UserService) {

      this.events.getObservable().subscribe((data) => {
        if(data=='exam_started'){
          this.checkForExamTimeCount();
        }
      })
    setTimeout(() => {
      $("li.active").prev("li").addClass("active_top");
      $("li.active").next("li").addClass("active_bottom");
    }, 100);
    this.getProfile()
  }

  ngOnInit(): void {
    this.hasLOggedIn=localStorage.getItem('aceHasLoggedIn')
    if (this.user.hasLoggedIn) {
      // this.getProfile()
      if (localStorage.getItem(this.common.SELECTED_COURSE)) {
        this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
        
      }
     
    }
    this.checkForExamTimeCount();
    
    this.url=this.router.url.substring(0,this.router.url.lastIndexOf('/')+1)
    
  }

  changeClass() {
    this.zone.run(() => {
      $("li").removeClass("active_top");
      $("li").removeClass("active_bottom");
      setTimeout(() => {
        // $('li.active').prevAll('li').first().addClass("active_top");
        // $('li.active').nextAll('li').first().addClass("active_top");
        $("li.active").prev("li").addClass("active_top");
        $("li.active").next("li").addClass("active_bottom");
        // alert('llllllllllllllll')
      }, 10);
    })


  }

  getProfile() {
    this.profile.getUserDetails()
      .subscribe(res => {
        if (res.success == true) {
          this.profileData = res.data;
        }
      }, err => {
        this.common.processError(err);
        return false;
      });
  }

  // toggleRightSidenav() {
  //   this.notification = !this.rightSidenav;
  // }


  checkForExamTimeCount() {
    if (localStorage.getItem(this.common.START_TIME)) {
      let _exam_time = localStorage.getItem(this.common.EXAM_TIME);
      let _exam_start_time = new Date(localStorage.getItem(this.common.START_TIME));

      this.hasCounter = true
      this.exam.startTime = _exam_start_time;
      this.exam.examTime = _exam_time;
      this.exam.isExamComplete = true;
      this.exam.calculateLeftTime()
    }
    this.examName = localStorage.getItem(this.common.EXAM_NAME)
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => {
      
      if (data && data.hasNoCounter) {
        this.hasCounter = false
      } else {
        this.hasCounter = true
      }
    })
  }

  goToExam() {
    localStorage.setItem(this.common.QUESTION_FLAG, 'question')
    if (this.exam.leftTimeSeconds > 0) {
      this.router.navigate(['/question-pool', localStorage.getItem(this.common.EXAM_ID)])
      localStorage.setItem('timer','true')
      // this.navCtrl.navigateForward('mock-test', {
      //   queryParams: {
      //     examId: localStorage.getItem(this.common.EXAM_ID),
      //   }
      // })
    }
  }
}
