import { Injectable } from '@angular/core';
// import { Storage } from '@ionic/storage';
// import {  ModalController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import { EventPublishService } from './event-publish';
import { LoginActivate } from './loginActivate';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public profile: any = {}
  public mentorProfile: any = {}
  public apiKey: string = ''
  public mentorApiKey: string = ''
  public hasLoggedIn: boolean = false;
  public mentorHasLoggedIn: boolean = false;
  public defaultAddress: any;

  PROFILE = 'aceUserProfile';
  MENTOR_PROFILE = 'aceMentorProfile';
  APIKEY = 'aceApiKey';
  MENTOR_APIKEY = 'aceMentorApiKey';
  HAS_LOGGED_IN = 'aceHasLoggedIn';
  MENTOR_HAS_LOGGED_IN = 'aceMentorHasLoggedIn';

  FREE_VIDEO_COUNT = 'acefreevideocount';
  FREE_MATERIAL_COUNT = 'acefreematerialcount';
  FREE_TRENDING_COUNT = 'acefreetrendingcount';
  FREE_EXAM_COUNT = 'acefreeexamcount';

  isFirstTime: boolean = false;
  // SETTINGS
  public free_video_count: any = 0;
  public free_trendings_count: any = 0;
  public free_material_count: any = 0;
  public free_exam_count: any = 0;
  // SETTINGS END
  public forgot_password_email_mobile: any = '';
  // OTP RESEND TIMER
  otp_resend_timer_verify_mobile: any = null;
  otp_resend_timer_forgot_password: any = null;
  // login
  public login_mobile: any = '';
  login_otp_resend_timer: any = null;;
  constructor(
    // public storage: Storage,
    private events: EventPublishService,
    public common: CommonService,
    public router: Router,
  ) { }

  updateLoginData(res) {
    this.profile = res.data;
    this.apiKey = res.api_token;
    this.hasLoggedIn = true;
    localStorage.setItem(this.PROFILE, JSON.stringify(this.profile));
    localStorage.setItem(this.APIKEY, this.apiKey);
    localStorage.setItem(this.HAS_LOGGED_IN, JSON.stringify(true));
  }
  
  updateUserData(res) {
    this.profile = res;
    localStorage.setItem(this.PROFILE, JSON.stringify(this.profile));
  }
  logout() {
    // STORAGE 
    localStorage.removeItem(this.PROFILE);
    localStorage.removeItem(this.APIKEY);
    localStorage.removeItem(this.HAS_LOGGED_IN);
    localStorage.removeItem(this.common.LOGIN_TYPE);
    localStorage.removeItem(this.common.SELECTED_COURSE);
    this.profile = null;
    this.apiKey = null;
    this.hasLoggedIn = false;
    // localStorage.clear()
    // this.common.presentToast("You have been successfully logged out!");
  }

  
  async loadUser() {
    if (localStorage.getItem(this.APIKEY)) {
      this.apiKey = localStorage.getItem(this.APIKEY);
      this.hasLoggedIn = true;
    }
      if (localStorage.getItem(this.PROFILE)) {
        this.profile = JSON.parse(localStorage.getItem(this.PROFILE));
    }

    // FREE COUNT 
  }


  // FREE COUNT 
  updateFreeCount(_type) {
    if (_type == 'video') {
      this.free_video_count++;
      localStorage.setItem(this.FREE_VIDEO_COUNT, this.free_video_count);
    } else if (_type == 'material') {
      this.free_material_count++;
      localStorage.setItem(this.FREE_MATERIAL_COUNT, this.free_material_count);
    } else if (_type == 'trending') {
      this.free_trendings_count++;
      localStorage.setItem(this.FREE_TRENDING_COUNT, this.free_trendings_count);
    } else if (_type == 'exam') {
      this.free_exam_count++;
      localStorage.setItem(this.FREE_EXAM_COUNT, this.free_exam_count);
    }

  }


  // CHECK FOR LANDING PAGE===============================
  checkForLandingPage() {
    // localStorage.getItem(this.common.IS_FIRST_TIME).then((val) => {
    //   if (val) this.isFirstTime = true;
    // })
    if (localStorage.getItem(this.common.IS_FIRST_TIME)) this.isFirstTime = true;
  }




}
