import { DatePipe } from '@angular/common';
import { Component, OnInit, NgZone } from '@angular/core';
import { CommonService } from '../../providers/common.service';
import { ItemService } from '../../providers/item.service';
import { MatDialog } from '@angular/material/dialog';
import { VideoDetailComponent } from '../video-detail/video-detail.component';
import { PackageListComponent } from '../package-list/package-list.component';
import { NavigationEnd, Route, Router, ActivatedRoute } from '@angular/router';
import { EventPublishService } from '../../providers/event-publish';
import { ExamService } from '../../providers/exam.service';
import $ from "jquery";
import { CourseService } from '../../providers/course.service';
import { UserService } from 'src/app/providers/user.service';
import { LoginActivate } from 'src/app/providers/loginActivate';


@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss']
})
export class VideoListComponent implements OnInit {

  loadingVideos: boolean = false
  scrollDistance: any = 100
  length: any
  per_page: number = 12;
  itemsList: any = []
  page: any = 1
  perpage: any = 20
  sub_id: any = null
  week_id: any = null
  day_id: any = null
  loadingExams: boolean = false;
  type: any = null
  day:any;
  week:any;
  check_week:any;
  subjectData:any;
  subject_id:any;
  day_idd:any;
  videos:any=[];
  description:any=[];
  constructor(public common: CommonService, public itemService: ItemService,
    public dialog: MatDialog, public datePipe: DatePipe, public router: Router,
    public zone: NgZone, public events: EventPublishService, public route: ActivatedRoute, public exam: ExamService,public user:UserService
    ,public CourseService:CourseService , public loginActivate:LoginActivate) {
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

  }

  ngOnInit(): void {

    this.page = 1
    this.itemsList = [];
    if (localStorage.getItem(this.common.SELECTED_COURSE)) {
      this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
    }
    if (localStorage.getItem(this.common.IS_TAB_METHOD)) {
      this.common.isTabActive = true
      if (localStorage.getItem(this.common.SELECTED_COURSE)) {
        this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
      }
    } else {
      this.common.isTabActive = false
    }
    this.route.params.subscribe(params => {
      
      if (params && params.sub_id) var subId = params.sub_id
      if(subId){
        this.subject_id=subId
        this.getSubjectView();
      }
      if (params && params.week_id) var weekId = params.week_id
      if (params && params.day_id) var dayId = params.day_id
      if(dayId){
        this.day_idd=dayId;
        console.log( this.day_idd)
      }
      if (params && params.day) this.day= params.day
      if (params && params.week) this.week= params.week
      this.check_week=params.week
      console.log(this.check_week)
      
    if(localStorage.getItem('description') ){
      this.description=localStorage.getItem('description')
    }
      console.log(this.day,this.week)
      if (params && params.id) {
        this.common.type = this.type = params.id
        this.common.sideActive = params.id
      } else {
        this.common.type = this.type = 'video'
        this.common.sideActive = 'video'
      }
      if(params && params.id == 'mock'){
        // this.common.type = this.type = 'exam'
        this.common.sideActive = 'exam'
      } 

      // if (params && params.title) this.title = params.title

      // if (params && params.weekTitle && params.dayTitle && params.day && params.week) {
      //   this.correspondingWeek = params.week
      //   this.selectedWeek = 'Week - ' + params.week + ' ' + params.weekTitle
      //   this.selectedDay = 'Day - ' + params.day + ' ' + params.dayTitle
      // }
      this.page = 1
      this.itemsList = []
      this.type = this.common.type;
      this.sub_id = subId
      this.week_id = weekId
      this.day_id = dayId
      
      if (this.type == null) {
        this.common.type = this.type = 'video'
      }
      console.log(subId)
      this.loadItems(this.type, subId, weekId, dayId);
    })


    // this.loadItems('video')


    this.events.getObservable().subscribe((data) => {
      if (data == 'aceUser:tabChanges') {
        this.page = 1
        this.itemsList = []
        this.loadItems()
      }
      if (data == 'aceTabClick') {
        this.page = 1
        if (this.common.type == null) this.common.type = 'video'
        this.itemsList = [];
        this.loadItems()
        if (localStorage.getItem(this.common.SELECTED_COURSE)) {
          this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
        }
        if (localStorage.getItem(this.common.IS_TAB_METHOD)) {
          this.common.isTabActive = true
          if (localStorage.getItem(this.common.SELECTED_COURSE)) {
            this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
          }
        } else {
          this.common.isTabActive = false
        }
      }
      if (data == 'aceUser:courseChanges') {
        this.zone.run(() => {
          this.common.selectedCourse = JSON.parse(localStorage.getItem(this.common.SELECTED_COURSE))
          this.loadItems('video')
        })
      }
    })


  }

  onScroll(e) {
    if (this.length <= this.itemsList.length) {
      return;
    }
    this.page = this.page + 1
    this.loadItems(this.type,this.subject_id)
  }

  onClickItem(value) {
    this.itemsList = []
    this.page = 1
    this.type = value
    if(value =='video'){
      this.common.sideActive='video'
    }
    if(value =='pdf'){
      this.common.sideActive='pdf'
    }
    if(value =='mock'){
      this.common.sideActive='exam'
    }
    this.loadItems(value,this.sub_id,this.week_id,this.day_id)
  }

  loadItems(type: any = null, sub_id: any = null, week_id: any = null, day_id: any = null) {
    console.log(sub_id)
    this.scrollDistance = 1.0
    this.loadingVideos = true;
  
    this.type = type
    console.log(this.type)
    if (type != 'mock') {
      let course_id = null
      // if (this.common.type == null) {
      //   type = ''
      // } else {
      //   type = this.common.type
      // }
      console.log(this.common.selectedCourse)
      if (this.common.selectedCourse && this.common.selectedCourse != null) {
   
        course_id = this.common.selectedCourse._id
      }
      this
        .itemService
        .getItems(this.perpage, this.page, course_id, sub_id,
          week_id, day_id, type,this.sub_id)
        .subscribe(res => {
          if (res.success == true) {
            this.length = res.data.total
            res.data.data.forEach(element => {
              if (!this.itemsList.find(x => x._id == element._id)) {
                this.itemsList.push(element);
                
              }
            });
          }
          this.loadingVideos = false;
        }, err => {
          this.loadingVideos = false;
          
          this.common.processError(err);
          return false;
        });
    }

    if (type == 'mock') {
      let course_id = this.common.selectedCourse._id
      this.loadingExams = true;
      this.exam.getExam(this.page, this.per_page, sub_id, course_id, week_id, day_id)
        .subscribe(res => {
          if (res.success == true) {
            this.length = res.data.total
            res.data.data.forEach(element => {
              if (!this.itemsList.find(x => x._id == element._id)) {
                this.itemsList.push(element);
                
              }
            });
          }
          this.loadingVideos = false;
        }, err => {
          this.loadingVideos = false;
          
          this.common.processError(err);
          return false;
        });


    }
  }

  onClickMaterialView(type, value) {

    var is_view :boolean=false

    if(!this.user.hasLoggedIn){
      if(this.common.played_video.video_count >= 3){
        console.log(this.common.played_video.ids.find(x=>x._id == value._id ) )
        if(this.common.played_video.ids.find(x=>x == value._id )){
          is_view=true
        }else{
          is_view=false
          this.loginActivate.onLogin()
        }
      }else{
        is_view=true
      }
    }else{
      is_view=true
    }

    if(is_view){
    this.common.type = type
    if ((!value.need_to_subscribe || value.need_to_subscribe) && value.type =='free') {
      this.common.type = type
      this.materialView(type, value)
    }else if(value.need_to_subscribe && value.type =='premium'){
      this.common.showToast("Please Subscribe to Continue")
     }else if(!value.need_to_subscribe && value.type =='premium'){
      this.common.type = type
      this.materialView(type, value)
     }
    // else {
    //   if (value.need_to_subscribe) {
    //     this.common.showToast("Please Subscribe to Continue")
    //     this.subscribe(value.course_id)
    //   } else {
    //     if(value.published_on  !=null){
    //       this.common.showToast("Content not Available Now, Please Check On " + this.datePipe.transform(value.published_on, 'dd-MM-yyyy'))
    //     }
    //     if(!value.published_on  ==null){
    //       this.common.showToast("Content not Available Now, Please Check On ")
    //     }
    //   }
    // }
    }
  }

  materialView(type, value) {
    let tempType = type
    const dialogRef = this.dialog.open(VideoDetailComponent, {
      panelClass: "full_modal",
      data: {
        id: value._id,
        sub_id: value.subject_id ? value.subject_id : null,
        week_id: value.week_id ? value.week_id : null,
        day_id: value.day_id ? value.day_id : null,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      
      // this.itemsList = []
      
      // this.loadItems(tempType)
    });
  }

  subscribe(course_id) {
    const dialogRef = this.dialog.open(PackageListComponent, {
      data: {
        flag: true,
        course_id: course_id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }


  typeChange(data) {
    this.type = data;
  }

  onClickView(value) {
    
    if (value.completed_on && value.completed_on != null) {
      this.router.navigate(['/exam-result', value.result_id])

    } else {
      this.router.navigate(['/exam-view', value._id])

    }
  }

  ngAfterViewInit() {
    $(".getHeightCol").click(function () {
      if ($(window).width() > 767) {
        setTimeout(function () {
          
          var t = 0;
          var t_elem;
          $(".cauto").each(function () {
            var tr = $(this);
            if (tr.outerHeight() > t) {
              t_elem = tr.outerHeight();
              t = tr.outerHeight();
              $(".cauto").height(t_elem);
            }
          })
        }, 100);
      }


    })
  }

  getSubjectView(){
    this.CourseService.getSubjectView(this.common.selectedCourse._id,this.subject_id).subscribe(resp=>{
      if(resp.success){
        this.subjectData=resp.data;
      }
      })
  }


  nextVideo(data){
    this.videos=[];
     var timelines=JSON.parse(localStorage.getItem(this.common.TIME_LINE))
    timelines.forEach(element => {
    element.days.forEach(element1 => {
      element1.week_id=element._id
      element1.week=element.week
      this.videos.push(element1)
      console.log(this.videos)
    });
    });

    var index: number = this.videos.findIndex(x => x._id == this.day_idd);
    console.log(index)
    console.log(data)
    if(data == 'next'){
      console.log("kkkkkkkkkk")
      index=index+1
      console.log(index)
      this.week_id=this.videos[index].week_id
      this.day_idd=this.videos[index]._id
      this.day_id=this.day_idd
      this.day=this.videos[index].days
      this.week=this.videos[index].week
      if(this.week !=this.check_week){
        this.description=[]
      }
    }
    if(data == 'previous'){
      console.log("kkkkkkkkkk")
      index=index-1
      console.log(index)
      this.week_id=this.videos[index].week_id
      this.day_idd=this.videos[index]._id
      this.day_id=this.day_idd
      this.day=this.videos[index].days
      this.week=this.videos[index].week
      if(this.week ==this.check_week){
        this.description=localStorage.getItem('description')
      }
    }
     this.itemsList=[];
    this.loadItems(this.type, this.sub_id, this.week_id, this.day_idd);
  }

}
