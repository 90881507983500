import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  public timeLineData: any;
  constructor(public api: Api) { }
  getAllCourses() {
    let endpoint = 'courses';
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }
  getCourseDataById(_course_id) {
    let endpoint = 'courses/view/' + _course_id;
    return this
      .api
      .get(endpoint)
      .pipe(map(resp => resp.json()));
  }

  getSubjectdetails(id){
    let endpoint='courses/'+id+'/subjects'
    return this
    .api
    .get(endpoint)
    .pipe(map(resp=>resp.json()))
  }

  getSubjectView(course_id,id){
   let endpoint='courses/'+course_id+'/subjects/'+id
   return this
   .api
   .get(endpoint)
   .pipe(map(resp=>resp.json()))
  }
}
