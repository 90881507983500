import { Component, OnInit, ElementRef, NgZone } from '@angular/core';
import * as $ from 'jquery';
import { CommonService } from '../../../providers/common.service';
import { ProfileService } from '../../../providers/profile.service';


@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent implements OnInit {
  showNoti: boolean = false;
  profileData: any = []
  hasLOggedIn:any;
  constructor(private elRef: ElementRef, public common: CommonService,
    public profile: ProfileService, public zone: NgZone) {
    setTimeout(() => {
      $("li.active").prev("li").addClass("active_top");
      $("li.active").next("li").addClass("active_bottom");
    }, 100);
    this.getProfile()
  }

  ngOnInit(): void {
  this.hasLOggedIn=localStorage.getItem('aceHasLoggedIn')
  }

  changeClass() {
    this.zone.run(() => {
      $("li").removeClass("active_top");
      $("li").removeClass("active_bottom");
      setTimeout(() => {
        // $('li.active').prevAll('li').first().addClass("active_top");
        // $('li.active').nextAll('li').first().addClass("active_top");
        $("li.active").prev("li").addClass("active_top");
        $("li.active").next("li").addClass("active_bottom");
        // alert('llllllllllllllll')
      }, 10);
    })


  }

  getProfile() {
    this.profile.getUserDetails()
      .subscribe(res => {
        if (res.success == true) {
          this.profileData = res.data;
        }
      }, err => {
        // this.common.processError(err);
        return false;
      });
  }

}
