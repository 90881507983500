<section class="auth_section">
  <div class="container-model">
    <div class="row">
      <div class="col-md-12">
        <button type="button" aria-label="Close" class="close_model_div" (click)="close()">
          <img src="assets/img/close.svg" class="close_model">
        </button>
        <form [formGroup]="loginForm">
          <div class="auth_left">
            <div class="auth_left_child">
              <div class="auth_heading">
                <!-- <h3>Hello</h3> -->
                <h1 class="bold h1">Sign <span class="primary h1">In!</span></h1>
              </div>
              
              <div class="auth_form">
                <!-- <div class="select_mthd mt30 mb5">
                  <div class="child_mthd" >
                    <div class="child_mt_table">
                      <input checked type="radio" name="login_method">
                      <span>Mobile</span>
                    </div>
                  </div>
                  <div class="child_mthd" > 
                    <div class="child_mt_table">
                      <input type="radio" name="login_method">
                      <span>Email</span>
                    </div>
                  </div>
                </div> -->
                <div class="auth_form_text mb5">
                  <span class="f14" *ngIf='type=="mobForm"'>Please enter your mobile number </span>
                  <span class="f14" *ngIf='type=="otpForm"'>Please enter your OTP </span>
                </div>

                <div class="otp_edit_nmb mb10 mt5" *ngIf='resentOtpAfter && type=="otpForm"'>
                  <span class="f14" (click)="changeMobile()"  *ngIf="!display_email">{{user.login_mobile}} <span class="pointer primary">Edit</span> </span>
                  <span class="f14" (click)="changeMobile()"  *ngIf="display_email">{{user_email}} <span class="pointer primary">Edit</span> </span>
                </div>

                <!-- <div *ngIf='resentOtpAfter && type=="otpForm"'>
                  <span  (click)="onSubmitMobile()" _ngcontent-rcw-c5="" *ngIf="!display_email"
                  class="medium pointer primary">{{user.login_mobile}}</span>

                  <span  (click)="onSubmitMobile()" _ngcontent-rcw-c5="" *ngIf="display_email"
                  class="medium pointer primary">{{user_email}}</span>
                  <span  (click)="changeMobile()" _ngcontent-rcw-c5=""
                  class="medium pointer primary">Change</span>
              </div> -->

                <div class="auth_form_box" *ngIf='type=="mobForm"'>
                  <div class="afb_input_control">
                    <input  type="text" formControlName="mobile" class="input_control_1 lgin_phone"
                      placeholder="10 digit mobile number" ng2TelInput [ng2TelInputOptions]="{initialCountry: 'in'}" 
                     (countryChange)="onCountryChange($event)">
                  
                  </div>
                  <div *ngIf="submitted && fpf.mobile.errors" class="form-error-msg">
                    <div class="form-error-msg" *ngIf="fpf.mobile.errors.required">Mobile Number is
                      required</div>
                      <div class="form-error-msg" *ngIf="fpf.mobile.errors.pattern">Please enter a valid mobile number
                        </div>
                  </div>
                </div>
                 
                <br>
                <div class="afb_input_control" *ngIf="is_email">
                  <div class="auth_form_text">
                    <span class="f14">Enter your email ID </span>
                  </div>

                  <input type="text" formControlName="email" class="input_control_1 login_email" placeholder="Enter Your Email Id">
                  <div *ngIf="submitted && fpf.email.errors" class="form-error-msg">
                    <div class="form-error-msg" *ngIf="fpf.email.errors.required">Email is
                      required</div>
                      <div class="form-error-msg" *ngIf="fpf.email.errors.pattern">Please enter a valid email</div>
                  </div>
                </div>

                
                
                <div class="auth_form_box"  *ngIf='type=="otpForm"'>
                  <div class="afb_input_control">
                    <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:4, allowNumbersOnly:true}">
                    </ng-otp-input>
                  </div>
                </div>
              
               
                <div class="pt10 pb10 pointer" *ngIf='isResendOtp && type=="otpForm"'>
                  <span class="prime  " *ngIf="resentOtpAfter"></span>
                  <span [ngClass]="{'btn-loading':!showResendOtp}" (click)="onSubmitMobile()" _ngcontent-rcw-c5=""
                    class="medium pointer primary">Resend OTP</span>
                </div>
                <div class="pt10 pb10 pointer" *ngIf='!isResendOtp && type=="otpForm"'>
                  <span class="prime  " *ngIf="resentOtpAfter">{{(resentOtpAfter) | myDuration}} | </span>
                  <span [ngClass]="{'btn-loading':!showResendOtp}" class="medium  opacity3">Resend
                    OTP</span>
                </div>
                <div class="auth_actions_box">
                  <div class="row">
                    <!-- <div class="col-md-6 col-xs-7 tl">
                    <span>Forgot Mobile Number ?</span>
                  </div>
                  <div class="col-md-6 col-xs-5 tr">
                    <span>New User ? <span class="primary pointer" routerLink="/signup" >Sign Up</span> </span>
                  </div> -->
                    <div class="col-md-12 col-sm-12 col-xs-12 tl" *ngIf='type=="mobForm"'>
                      <div class="action_auth_btn mt10">
                        <button class="button_s1 f12" (click)="onSubmitMobile()">Get OTP
                          <svg *ngIf="isLoading" class="spinner" viewBox="0 0 50 50">
                            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12 tl" *ngIf='type=="otpForm"'>
                      <div class="action_auth_btn mt10">
                        <button type="submit" class="button_s1 f12"  (click)="submit()">Sign In
                          <svg *ngIf="isLoading" class="spinner" viewBox="0 0 50 50">
                            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>